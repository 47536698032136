/*******************************************************
 * Custom theme code styles
 * Written by Themelize.me (http://themelize.me)
 *
 * Includes the base variables & mixins needed for all
 * scss files
 *******************************************************/

.owl-equalheight,
.owl-equalheight .owl-stage,
.owl-equalheight .owl-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.owl-carousel::after {
  display: block;
  clear: both;
  content: "";
}

.owl-nav {
  float: right;
}

.owl-dots {
  float: left;
}

/* Styling Next and Prev buttons */

.owl-nav div {
  color: #FFF;
  display: inline-block;
  zoom: 1;
  *display: inline;
  /*IE7 life-saver */
  margin: 0 1px;
  padding: 0;
  height: 30px;
  width: 30px;
  line-height: 30px;
  font-size: 12px;
  background: #1b1b1b;
  opacity: 0.8;
  border-radius: 1px;
  font-size: 0;
}

.owl-nav div:before {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  font-style: normal;
  speak: none;
  display: inline-block;
  content: "\f105";
  font-size: 22px;
  font-weight: bold;
  color: white;
  text-align: center;
  display: block;
  padding: 0;
  margin: 0;
}

.owl-nav div.owl-prev {
  left: 0;
}

.owl-nav div.owl-prev:before {
  content: '\f104';
}

.owl-nav div.owl-next {
  right: 0;
}

.owl-nav div.disabled {
  opacity: 0.4;
}

/* Clickable class fix problem with hover on touch devices */

/* Use it for non-touch hover action */

.owl-nav div:hover {
  opacity: 100;
  text-decoration: none;
  background: #55A79A;
}

/* Styling Pagination*/

.owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
  *display: inline;
  /*IE7 life-saver */
}

.owl-dots .owl-dot span {
  display: block;
  width: 12px;
  height: 12px;
  margin: 0 3px;
  background: #1b1b1b;
  border-radius: 1px;
}

.owl-dots .owl-dot.active span,
.owl-dots .owl-dot:hover span {
  opacity: 100;
  background: #55A79A;
}

.owl-nav-over {
  position: relative;
}

.owl-nav-over .owl-nav div {
  position: absolute;
  top: 50%;
  margin-top: -20px;
}

.owl-nav-over .owl-nav div.owl-prev {
  left: -1px;
}

.owl-nav-over .owl-nav div.owl-next {
  right: -1px;
}

.owl-nav-over.owl-nav-over-lg .owl-nav div {
  margin-top: -40px;
  height: 60px;
  width: 60px;
  line-height: 60px;
}

.owl-nav-over.owl-nav-over-lg .owl-nav div:before {
  font-size: 30px;
}

.owl-nav-over.owl-nav-over-lg .owl-nav div.owl-prev {
  left: -1px;
}

.owl-nav-over.owl-nav-over-lg .owl-nav div.owl-next {
  right: -1px;
}

.owl-nav-over.owl-nav-over-hover .owl-nav div {
  opacity: 0;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

.owl-nav-over.owl-nav-over-hover:hover .owl-nav div {
  opacity: 1;
}

.owl-nav-over.owl-nav-over-hover:hover .owl-nav div.disabled {
  opacity: 0.4;
}

.owl-nav-center .owl-nav {
  float: none;
  text-align: center;
}

.owl-dots-center .owl-dots {
  float: none;
  text-align: center;
}

.owl-dots-over {
  position: relative;
}

.owl-dots-over .owl-dots {
  bottom: 10px;
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
}

.owl-dots-over.owl-dots-over-hover .owl-dots {
  opacity: 0;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

.owl-dots-over.owl-dots-over-hover:hover .owl-dots {
  opacity: 1;
}

.owl-thumbs .owl-thumb {
  display: inline-block;
  max-width: 120px;
  cursor: pointer;
  opacity: 0.4;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

.owl-thumbs .owl-thumb:hover,
.owl-thumbs .owl-thumb.active {
  opacity: 1;
}

.owl-thumbs[data-toggle="owl-carousel"] .owl-thumb {
  max-width: none;
}

.owl-nav-plain .owl-nav div {
  background: transparent !important;
}

.owl-nav-text .owl-nav div {
  color: #adb5bd;
  display: inline-block;
  height: auto;
  width: auto;
  line-height: 1.2;
  background: transparent !important;
  opacity: 0.8;
  border-radius: 0;
  font-size: 16px;
  margin-left: 5px;
  margin-right: 5px;
}

.owl-nav-text .owl-nav div:before {
  content: '';
}

.owl-nav-text .owl-nav div:hover {
  opacity: 1;
  background: transparent !important;
  color: #495057;
}

.owl-overflow .owl-stage-outer {
  overflow: visible !important;
}

.owl-highlight-active .owl-item {
  opacity: 0.35;
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

.owl-highlight-active .owl-item:hover {
  opacity: 0.8;
  -webkit-transform: scale(0.91);
  transform: scale(0.91);
}

.owl-highlight-active .owl-item.active {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.owl-equalheight .owl-stage-outer {
  padding-left: 3px;
}