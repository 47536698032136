/*******************************************************
 * Custom theme code styles
 * Written by Themelize.me (http://themelize.me)
 *
 * Includes the base variables & mixins needed for all
 * scss files
 *******************************************************/

body .is-sticky .sticky-header-light.header .navbar-brand,
body .is-sticky .sticky-header-primary.header .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

body .is-sticky .sticky-header-light.header .navbar-brand:hover,
body .is-sticky .sticky-header-primary.header .navbar-brand:hover,
body .is-sticky .sticky-header-light.header .navbar-brand:focus,
body .is-sticky .sticky-header-primary.header .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

body .is-sticky .sticky-header-light.header .navbar-nav>.nav-item>.nav-link,
body .is-sticky .sticky-header-primary.header .navbar-nav>.nav-item>.nav-link,
body .is-sticky .sticky-header-light.header .navbar-nav>.nav-link,
body .is-sticky .sticky-header-primary.header .navbar-nav>.nav-link {
  color: rgba(0, 0, 0, 0.7);
}

body .is-sticky .sticky-header-light.header .navbar-nav>.nav-item>.nav-link:hover,
body .is-sticky .sticky-header-primary.header .navbar-nav>.nav-item>.nav-link:hover,
body .is-sticky .sticky-header-light.header .navbar-nav>.nav-item>.nav-link:focus,
body .is-sticky .sticky-header-primary.header .navbar-nav>.nav-item>.nav-link:focus,
body .is-sticky .sticky-header-light.header .navbar-nav>.nav-link:hover,
body .is-sticky .sticky-header-primary.header .navbar-nav>.nav-link:hover,
body .is-sticky .sticky-header-light.header .navbar-nav>.nav-link:focus,
body .is-sticky .sticky-header-primary.header .navbar-nav>.nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

body .is-sticky .sticky-header-light.header .navbar-nav>.nav-item>.nav-link.disabled,
body .is-sticky .sticky-header-primary.header .navbar-nav>.nav-item>.nav-link.disabled,
body .is-sticky .sticky-header-light.header .navbar-nav>.nav-link.disabled,
body .is-sticky .sticky-header-primary.header .navbar-nav>.nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

body .is-sticky .sticky-header-light.header .navbar-nav .active>.nav-link,
body .is-sticky .sticky-header-primary.header .navbar-nav .active>.nav-link,
body .is-sticky .sticky-header-light.header .navbar-nav .nav-link.show,
body .is-sticky .sticky-header-primary.header .navbar-nav .nav-link.show,
body .is-sticky .sticky-header-light.header .navbar-nav .nav-link.active,
body .is-sticky .sticky-header-primary.header .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

body .is-sticky .sticky-header-light.header .navbar-nav .open>.nav-link,
body .is-sticky .sticky-header-primary.header .navbar-nav .open>.nav-link,
body .is-sticky .sticky-header-light.header .navbar-nav .show>.nav-link,
body .is-sticky .sticky-header-primary.header .navbar-nav .show>.nav-link,
body .is-sticky .sticky-header-light.header .navbar-nav .open>.nav-link:hover,
body .is-sticky .sticky-header-primary.header .navbar-nav .open>.nav-link:hover,
body .is-sticky .sticky-header-light.header .navbar-nav .show>.nav-link:hover,
body .is-sticky .sticky-header-primary.header .navbar-nav .show>.nav-link:hover {
  color: #212529;
}

body .is-sticky .sticky-header-light.header .navbar-toggler,
body .is-sticky .sticky-header-primary.header .navbar-toggler {
  color: rgba(0, 0, 0, 0.7);
  border-color: rgba(0, 0, 0, 0.1);
}

body .is-sticky .sticky-header-light.header .navbar-toggler-icon,
body .is-sticky .sticky-header-primary.header .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.7)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

body .is-sticky .sticky-header-light.header .navbar-text,
body .is-sticky .sticky-header-primary.header .navbar-text {
  color: rgba(0, 0, 0, 0.7);
}

body .is-sticky .sticky-header-light.header .navbar-text a,
body .is-sticky .sticky-header-primary.header .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

body .is-sticky .sticky-header-light.header .navbar-text a:hover,
body .is-sticky .sticky-header-primary.header .navbar-text a:hover,
body .is-sticky .sticky-header-light.header .navbar-text a:focus,
body .is-sticky .sticky-header-primary.header .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

body .is-sticky .sticky-header-dark.header .navbar-brand {
  color: #fff;
}

body .is-sticky .sticky-header-dark.header .navbar-brand:hover,
body .is-sticky .sticky-header-dark.header .navbar-brand:focus {
  color: #fff;
}

body .is-sticky .sticky-header-dark.header .navbar-nav>.nav-item>.nav-link,
body .is-sticky .sticky-header-dark.header .navbar-nav>.nav-link {
  color: rgba(255, 255, 255, 0.75);
}

body .is-sticky .sticky-header-dark.header .navbar-nav>.nav-item>.nav-link:hover,
body .is-sticky .sticky-header-dark.header .navbar-nav>.nav-item>.nav-link:focus,
body .is-sticky .sticky-header-dark.header .navbar-nav>.nav-link:hover,
body .is-sticky .sticky-header-dark.header .navbar-nav>.nav-link:focus {
  color: rgba(255, 255, 255, 0.9);
}

body .is-sticky .sticky-header-dark.header .navbar-nav>.nav-item>.nav-link.disabled,
body .is-sticky .sticky-header-dark.header .navbar-nav>.nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

body .is-sticky .sticky-header-dark.header .navbar-nav .active>.nav-link,
body .is-sticky .sticky-header-dark.header .navbar-nav .nav-link.show,
body .is-sticky .sticky-header-dark.header .navbar-nav .nav-link.active {
  color: #fff;
}

body .is-sticky .sticky-header-dark.header .navbar-nav .open>.nav-link,
body .is-sticky .sticky-header-dark.header .navbar-nav .show>.nav-link,
body .is-sticky .sticky-header-dark.header .navbar-nav .open>.nav-link:hover,
body .is-sticky .sticky-header-dark.header .navbar-nav .show>.nav-link:hover {
  color: #212529;
}

body .is-sticky .sticky-header-dark.header .navbar-toggler {
  color: rgba(255, 255, 255, 0.75);
  border-color: rgba(255, 255, 255, 0.1);
}

body .is-sticky .sticky-header-dark.header .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.75)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

body .is-sticky .sticky-header-dark.header .navbar-text {
  color: rgba(255, 255, 255, 0.75);
}

body .is-sticky .sticky-header-dark.header .navbar-text a {
  color: #fff;
}

body .is-sticky .sticky-header-dark.header .navbar-text a:hover,
body .is-sticky .sticky-header-dark.header .navbar-text a:focus {
  color: #fff;
}

body .is-sticky .sticky-header-light.header {
  background-color: #fff;
  border-bottom: 1px solid rgba(85, 167, 154, 0.6);
  color: #1b1b1b;
}

body .is-sticky .sticky-header-light.header .header-inner .header-divider:after {
  background-color: #ced4da;
}

body .is-sticky .sticky-header-light.header .header-brand-text,
body .is-sticky .sticky-header-light.header .header-brand-text h1 {
  color: #55A79A;
}

body .is-sticky .sticky-header-light.header .header-brand-text-alt {
  color: #1b1b1b;
}

body .is-sticky .sticky-header-light.header .header-slogan {
  color: #888;
}

body .is-sticky .sticky-header-dark.header {
  background-color: #292b2c;
  border-bottom: 1px solid #5a5f61;
  color: #f0f0f0;
}

body .is-sticky .sticky-header-dark.header .header-inner .header-divider:after {
  background-color: #cdcdcd;
}

body .is-sticky .sticky-header-dark.header .header-brand-text,
body .is-sticky .sticky-header-dark.header .header-brand-text h1 {
  color: #fafafa;
}

body .is-sticky .sticky-header-dark.header .header-brand-text-alt {
  color: #fff;
}

body .is-sticky .sticky-header-dark.header .header-slogan {
  color: #f0f0f0;
}

body .is-sticky .sticky-header-primary.header {
  background-color: #55A79A;
  border-bottom: 1px solid #87c2b8;
  color: #d7d7d7;
}

body .is-sticky .sticky-header-primary.header .header-inner .header-divider:after {
  background-color: #87c2b8;
}

body .is-sticky .sticky-header-primary.header .header-brand-text,
body .is-sticky .sticky-header-primary.header .header-brand-text h1 {
  color: #d7d7d7;
}

body .is-sticky .sticky-header-primary.header .header-brand-text-alt {
  color: #d7d7d7;
}

body .is-sticky .sticky-header-primary.header .header-slogan {
  color: #dbedea;
}

.is-sticky .sticky-d-none {
  display: none !important;
}

.is-sticky .sticky-d-inline {
  display: inline !important;
}

.is-sticky .sticky-d-inline-block {
  display: inline-block !important;
}

.is-sticky .sticky-d-block {
  display: block !important;
}

.is-sticky .sticky-d-table {
  display: table !important;
}

.is-sticky .sticky-d-table-row {
  display: table-row !important;
}

.is-sticky .sticky-d-table-cell {
  display: table-cell !important;
}

.is-sticky .sticky-d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.is-sticky .sticky-d-inline-flex {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .is-sticky .sticky-d-sm-none {
    display: none !important;
  }
  .is-sticky .sticky-d-sm-inline {
    display: inline !important;
  }
  .is-sticky .sticky-d-sm-inline-block {
    display: inline-block !important;
  }
  .is-sticky .sticky-d-sm-block {
    display: block !important;
  }
  .is-sticky .sticky-d-sm-table {
    display: table !important;
  }
  .is-sticky .sticky-d-sm-table-row {
    display: table-row !important;
  }
  .is-sticky .sticky-d-sm-table-cell {
    display: table-cell !important;
  }
  .is-sticky .sticky-d-sm-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .is-sticky .sticky-d-sm-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .is-sticky .sticky-d-md-none {
    display: none !important;
  }
  .is-sticky .sticky-d-md-inline {
    display: inline !important;
  }
  .is-sticky .sticky-d-md-inline-block {
    display: inline-block !important;
  }
  .is-sticky .sticky-d-md-block {
    display: block !important;
  }
  .is-sticky .sticky-d-md-table {
    display: table !important;
  }
  .is-sticky .sticky-d-md-table-row {
    display: table-row !important;
  }
  .is-sticky .sticky-d-md-table-cell {
    display: table-cell !important;
  }
  .is-sticky .sticky-d-md-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .is-sticky .sticky-d-md-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .is-sticky .sticky-d-lg-none {
    display: none !important;
  }
  .is-sticky .sticky-d-lg-inline {
    display: inline !important;
  }
  .is-sticky .sticky-d-lg-inline-block {
    display: inline-block !important;
  }
  .is-sticky .sticky-d-lg-block {
    display: block !important;
  }
  .is-sticky .sticky-d-lg-table {
    display: table !important;
  }
  .is-sticky .sticky-d-lg-table-row {
    display: table-row !important;
  }
  .is-sticky .sticky-d-lg-table-cell {
    display: table-cell !important;
  }
  .is-sticky .sticky-d-lg-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .is-sticky .sticky-d-lg-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .is-sticky .sticky-d-xl-none {
    display: none !important;
  }
  .is-sticky .sticky-d-xl-inline {
    display: inline !important;
  }
  .is-sticky .sticky-d-xl-inline-block {
    display: inline-block !important;
  }
  .is-sticky .sticky-d-xl-block {
    display: block !important;
  }
  .is-sticky .sticky-d-xl-table {
    display: table !important;
  }
  .is-sticky .sticky-d-xl-table-row {
    display: table-row !important;
  }
  .is-sticky .sticky-d-xl-table-cell {
    display: table-cell !important;
  }
  .is-sticky .sticky-d-xl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .is-sticky .sticky-d-xl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

[class^="sticky-effect-"],
.sticky-nav-contract .navbar-nav>.nav-item>.nav-link,
.sticky-nav-contract .navbar-nav>.nav-link {
  -webkit-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}

.sticky-effect-slide-down {
  -webkit-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}

.sticky-effect-fade-in {
  opacity: 0;
  -webkit-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}

.sticky-effect-fade-out {
  opacity: 1;
}

.sticky-effect-expand-w {
  max-width: 0;
  padding-left: 0;
  padding-right: 0;
  overflow: hidden;
}

.sticky-effect-expand-h {
  height: 0 !important;
  overflow: hidden;
}

.sticky-effect-contract-w {
  max-width: 3000px;
  padding-left: 0;
  padding-right: 0;
  overflow: hidden;
}

.sticky-effect-contract-h {
  max-height: 3000px;
  overflow: hidden;
}

.is-sticky .sticky-effect-slide-down {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.is-sticky .sticky-effect-fade-in {
  opacity: 1;
}

.is-sticky .sticky-effect-fade-out {
  opacity: 0;
}

.is-sticky .sticky-effect-expand-w {
  max-width: 3000px;
  padding-left: inherit;
  padding-right: inherit;
}

.is-sticky .sticky-effect-expand-h {
  height: 100% !important;
}

.is-sticky .sticky-effect-contract-w {
  max-width: 0;
  padding-left: 0;
  padding-right: 0;
}

.is-sticky .sticky-effect-contract-h {
  max-height: 0;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

body .is-sticky .sticky-nav-contract .navbar-nav>.nav-item>.nav-link,
body .is-sticky .sticky-nav-contract .navbar-nav>.nav-link {
  padding-bottom: 24px !important;
  padding-top: 24px !important;
}

body .is-sticky .sticky-bg-light {
  background-color: #f8f9fa !important;
}

body .is-sticky .sticky-bg-light.sticky-bg-op-0,
body .is-sticky .bg-light.sticky-bg-op-0 {
  background-color: rgba(248, 249, 250, 0) !important;
}

body .is-sticky .sticky-bg-light.sticky-bg-op-1,
body .is-sticky .bg-light.sticky-bg-op-1 {
  background-color: rgba(248, 249, 250, 0.1) !important;
}

body .is-sticky .sticky-bg-light.sticky-bg-op-2,
body .is-sticky .bg-light.sticky-bg-op-2 {
  background-color: rgba(248, 249, 250, 0.2) !important;
}

body .is-sticky .sticky-bg-light.sticky-bg-op-3,
body .is-sticky .bg-light.sticky-bg-op-3 {
  background-color: rgba(248, 249, 250, 0.3) !important;
}

body .is-sticky .sticky-bg-light.sticky-bg-op-4,
body .is-sticky .bg-light.sticky-bg-op-4 {
  background-color: rgba(248, 249, 250, 0.4) !important;
}

body .is-sticky .sticky-bg-light.sticky-bg-op-5,
body .is-sticky .bg-light.sticky-bg-op-5 {
  background-color: rgba(248, 249, 250, 0.5) !important;
}

body .is-sticky .sticky-bg-light.sticky-bg-op-6,
body .is-sticky .bg-light.sticky-bg-op-6 {
  background-color: rgba(248, 249, 250, 0.6) !important;
}

body .is-sticky .sticky-bg-light.sticky-bg-op-7,
body .is-sticky .bg-light.sticky-bg-op-7 {
  background-color: rgba(248, 249, 250, 0.7) !important;
}

body .is-sticky .sticky-bg-light.sticky-bg-op-8,
body .is-sticky .bg-light.sticky-bg-op-8 {
  background-color: rgba(248, 249, 250, 0.8) !important;
}

body .is-sticky .sticky-bg-light.sticky-bg-op-9,
body .is-sticky .bg-light.sticky-bg-op-9 {
  background-color: rgba(248, 249, 250, 0.9) !important;
}

body .is-sticky .sticky-bg-light.sticky-bg-op-10,
body .is-sticky .bg-light.sticky-bg-op-10 {
  background-color: #f8f9fa !important;
}

body .is-sticky .sticky-text-light {
  color: #f8f9fa !important;
}

body .is-sticky .sticky-border-light {
  border: 1px solid transparent;
  border-color: #f8f9fa !important;
}

body .is-sticky .sticky-border-light.sticky-border-op-0,
body .is-sticky .border-light.sticky-border-op-0 {
  border-color: rgba(248, 249, 250, 0) !important;
}

body .is-sticky .sticky-border-light.sticky-border-op-1,
body .is-sticky .border-light.sticky-border-op-1 {
  border-color: rgba(248, 249, 250, 0.1) !important;
}

body .is-sticky .sticky-border-light.sticky-border-op-2,
body .is-sticky .border-light.sticky-border-op-2 {
  border-color: rgba(248, 249, 250, 0.2) !important;
}

body .is-sticky .sticky-border-light.sticky-border-op-3,
body .is-sticky .border-light.sticky-border-op-3 {
  border-color: rgba(248, 249, 250, 0.3) !important;
}

body .is-sticky .sticky-border-light.sticky-border-op-4,
body .is-sticky .border-light.sticky-border-op-4 {
  border-color: rgba(248, 249, 250, 0.4) !important;
}

body .is-sticky .sticky-border-light.sticky-border-op-5,
body .is-sticky .border-light.sticky-border-op-5 {
  border-color: rgba(248, 249, 250, 0.5) !important;
}

body .is-sticky .sticky-border-light.sticky-border-op-6,
body .is-sticky .border-light.sticky-border-op-6 {
  border-color: rgba(248, 249, 250, 0.6) !important;
}

body .is-sticky .sticky-border-light.sticky-border-op-7,
body .is-sticky .border-light.sticky-border-op-7 {
  border-color: rgba(248, 249, 250, 0.7) !important;
}

body .is-sticky .sticky-border-light.sticky-border-op-8,
body .is-sticky .border-light.sticky-border-op-8 {
  border-color: rgba(248, 249, 250, 0.8) !important;
}

body .is-sticky .sticky-border-light.sticky-border-op-9,
body .is-sticky .border-light.sticky-border-op-9 {
  border-color: rgba(248, 249, 250, 0.9) !important;
}

body .is-sticky .sticky-border-light.sticky-border-op-10,
body .is-sticky .border-light.sticky-border-op-10 {
  border-color: #f8f9fa !important;
}

body .is-sticky .sticky-bg-grey {
  background-color: #e9ecef !important;
}

body .is-sticky .sticky-bg-grey.sticky-bg-op-0,
body .is-sticky .bg-grey.sticky-bg-op-0 {
  background-color: rgba(233, 236, 239, 0) !important;
}

body .is-sticky .sticky-bg-grey.sticky-bg-op-1,
body .is-sticky .bg-grey.sticky-bg-op-1 {
  background-color: rgba(233, 236, 239, 0.1) !important;
}

body .is-sticky .sticky-bg-grey.sticky-bg-op-2,
body .is-sticky .bg-grey.sticky-bg-op-2 {
  background-color: rgba(233, 236, 239, 0.2) !important;
}

body .is-sticky .sticky-bg-grey.sticky-bg-op-3,
body .is-sticky .bg-grey.sticky-bg-op-3 {
  background-color: rgba(233, 236, 239, 0.3) !important;
}

body .is-sticky .sticky-bg-grey.sticky-bg-op-4,
body .is-sticky .bg-grey.sticky-bg-op-4 {
  background-color: rgba(233, 236, 239, 0.4) !important;
}

body .is-sticky .sticky-bg-grey.sticky-bg-op-5,
body .is-sticky .bg-grey.sticky-bg-op-5 {
  background-color: rgba(233, 236, 239, 0.5) !important;
}

body .is-sticky .sticky-bg-grey.sticky-bg-op-6,
body .is-sticky .bg-grey.sticky-bg-op-6 {
  background-color: rgba(233, 236, 239, 0.6) !important;
}

body .is-sticky .sticky-bg-grey.sticky-bg-op-7,
body .is-sticky .bg-grey.sticky-bg-op-7 {
  background-color: rgba(233, 236, 239, 0.7) !important;
}

body .is-sticky .sticky-bg-grey.sticky-bg-op-8,
body .is-sticky .bg-grey.sticky-bg-op-8 {
  background-color: rgba(233, 236, 239, 0.8) !important;
}

body .is-sticky .sticky-bg-grey.sticky-bg-op-9,
body .is-sticky .bg-grey.sticky-bg-op-9 {
  background-color: rgba(233, 236, 239, 0.9) !important;
}

body .is-sticky .sticky-bg-grey.sticky-bg-op-10,
body .is-sticky .bg-grey.sticky-bg-op-10 {
  background-color: #e9ecef !important;
}

body .is-sticky .sticky-text-grey {
  color: #e9ecef !important;
}

body .is-sticky .sticky-border-grey {
  border: 1px solid transparent;
  border-color: #e9ecef !important;
}

body .is-sticky .sticky-border-grey.sticky-border-op-0,
body .is-sticky .border-grey.sticky-border-op-0 {
  border-color: rgba(233, 236, 239, 0) !important;
}

body .is-sticky .sticky-border-grey.sticky-border-op-1,
body .is-sticky .border-grey.sticky-border-op-1 {
  border-color: rgba(233, 236, 239, 0.1) !important;
}

body .is-sticky .sticky-border-grey.sticky-border-op-2,
body .is-sticky .border-grey.sticky-border-op-2 {
  border-color: rgba(233, 236, 239, 0.2) !important;
}

body .is-sticky .sticky-border-grey.sticky-border-op-3,
body .is-sticky .border-grey.sticky-border-op-3 {
  border-color: rgba(233, 236, 239, 0.3) !important;
}

body .is-sticky .sticky-border-grey.sticky-border-op-4,
body .is-sticky .border-grey.sticky-border-op-4 {
  border-color: rgba(233, 236, 239, 0.4) !important;
}

body .is-sticky .sticky-border-grey.sticky-border-op-5,
body .is-sticky .border-grey.sticky-border-op-5 {
  border-color: rgba(233, 236, 239, 0.5) !important;
}

body .is-sticky .sticky-border-grey.sticky-border-op-6,
body .is-sticky .border-grey.sticky-border-op-6 {
  border-color: rgba(233, 236, 239, 0.6) !important;
}

body .is-sticky .sticky-border-grey.sticky-border-op-7,
body .is-sticky .border-grey.sticky-border-op-7 {
  border-color: rgba(233, 236, 239, 0.7) !important;
}

body .is-sticky .sticky-border-grey.sticky-border-op-8,
body .is-sticky .border-grey.sticky-border-op-8 {
  border-color: rgba(233, 236, 239, 0.8) !important;
}

body .is-sticky .sticky-border-grey.sticky-border-op-9,
body .is-sticky .border-grey.sticky-border-op-9 {
  border-color: rgba(233, 236, 239, 0.9) !important;
}

body .is-sticky .sticky-border-grey.sticky-border-op-10,
body .is-sticky .border-grey.sticky-border-op-10 {
  border-color: #e9ecef !important;
}

body .is-sticky .sticky-bg-grey-dark {
  background-color: #495057 !important;
}

body .is-sticky .sticky-bg-grey-dark.sticky-bg-op-0,
body .is-sticky .bg-grey-dark.sticky-bg-op-0 {
  background-color: rgba(73, 80, 87, 0) !important;
}

body .is-sticky .sticky-bg-grey-dark.sticky-bg-op-1,
body .is-sticky .bg-grey-dark.sticky-bg-op-1 {
  background-color: rgba(73, 80, 87, 0.1) !important;
}

body .is-sticky .sticky-bg-grey-dark.sticky-bg-op-2,
body .is-sticky .bg-grey-dark.sticky-bg-op-2 {
  background-color: rgba(73, 80, 87, 0.2) !important;
}

body .is-sticky .sticky-bg-grey-dark.sticky-bg-op-3,
body .is-sticky .bg-grey-dark.sticky-bg-op-3 {
  background-color: rgba(73, 80, 87, 0.3) !important;
}

body .is-sticky .sticky-bg-grey-dark.sticky-bg-op-4,
body .is-sticky .bg-grey-dark.sticky-bg-op-4 {
  background-color: rgba(73, 80, 87, 0.4) !important;
}

body .is-sticky .sticky-bg-grey-dark.sticky-bg-op-5,
body .is-sticky .bg-grey-dark.sticky-bg-op-5 {
  background-color: rgba(73, 80, 87, 0.5) !important;
}

body .is-sticky .sticky-bg-grey-dark.sticky-bg-op-6,
body .is-sticky .bg-grey-dark.sticky-bg-op-6 {
  background-color: rgba(73, 80, 87, 0.6) !important;
}

body .is-sticky .sticky-bg-grey-dark.sticky-bg-op-7,
body .is-sticky .bg-grey-dark.sticky-bg-op-7 {
  background-color: rgba(73, 80, 87, 0.7) !important;
}

body .is-sticky .sticky-bg-grey-dark.sticky-bg-op-8,
body .is-sticky .bg-grey-dark.sticky-bg-op-8 {
  background-color: rgba(73, 80, 87, 0.8) !important;
}

body .is-sticky .sticky-bg-grey-dark.sticky-bg-op-9,
body .is-sticky .bg-grey-dark.sticky-bg-op-9 {
  background-color: rgba(73, 80, 87, 0.9) !important;
}

body .is-sticky .sticky-bg-grey-dark.sticky-bg-op-10,
body .is-sticky .bg-grey-dark.sticky-bg-op-10 {
  background-color: #495057 !important;
}

body .is-sticky .sticky-text-grey-dark {
  color: #495057 !important;
}

body .is-sticky .sticky-border-grey-dark {
  border: 1px solid transparent;
  border-color: #495057 !important;
}

body .is-sticky .sticky-border-grey-dark.sticky-border-op-0,
body .is-sticky .border-grey-dark.sticky-border-op-0 {
  border-color: rgba(73, 80, 87, 0) !important;
}

body .is-sticky .sticky-border-grey-dark.sticky-border-op-1,
body .is-sticky .border-grey-dark.sticky-border-op-1 {
  border-color: rgba(73, 80, 87, 0.1) !important;
}

body .is-sticky .sticky-border-grey-dark.sticky-border-op-2,
body .is-sticky .border-grey-dark.sticky-border-op-2 {
  border-color: rgba(73, 80, 87, 0.2) !important;
}

body .is-sticky .sticky-border-grey-dark.sticky-border-op-3,
body .is-sticky .border-grey-dark.sticky-border-op-3 {
  border-color: rgba(73, 80, 87, 0.3) !important;
}

body .is-sticky .sticky-border-grey-dark.sticky-border-op-4,
body .is-sticky .border-grey-dark.sticky-border-op-4 {
  border-color: rgba(73, 80, 87, 0.4) !important;
}

body .is-sticky .sticky-border-grey-dark.sticky-border-op-5,
body .is-sticky .border-grey-dark.sticky-border-op-5 {
  border-color: rgba(73, 80, 87, 0.5) !important;
}

body .is-sticky .sticky-border-grey-dark.sticky-border-op-6,
body .is-sticky .border-grey-dark.sticky-border-op-6 {
  border-color: rgba(73, 80, 87, 0.6) !important;
}

body .is-sticky .sticky-border-grey-dark.sticky-border-op-7,
body .is-sticky .border-grey-dark.sticky-border-op-7 {
  border-color: rgba(73, 80, 87, 0.7) !important;
}

body .is-sticky .sticky-border-grey-dark.sticky-border-op-8,
body .is-sticky .border-grey-dark.sticky-border-op-8 {
  border-color: rgba(73, 80, 87, 0.8) !important;
}

body .is-sticky .sticky-border-grey-dark.sticky-border-op-9,
body .is-sticky .border-grey-dark.sticky-border-op-9 {
  border-color: rgba(73, 80, 87, 0.9) !important;
}

body .is-sticky .sticky-border-grey-dark.sticky-border-op-10,
body .is-sticky .border-grey-dark.sticky-border-op-10 {
  border-color: #495057 !important;
}

body .is-sticky .sticky-bg-primary {
  background-color: #55A79A !important;
}

body .is-sticky .sticky-bg-primary.sticky-bg-op-0,
body .is-sticky .bg-primary.sticky-bg-op-0 {
  background-color: rgba(85, 167, 154, 0) !important;
}

body .is-sticky .sticky-bg-primary.sticky-bg-op-1,
body .is-sticky .bg-primary.sticky-bg-op-1 {
  background-color: rgba(85, 167, 154, 0.1) !important;
}

body .is-sticky .sticky-bg-primary.sticky-bg-op-2,
body .is-sticky .bg-primary.sticky-bg-op-2 {
  background-color: rgba(85, 167, 154, 0.2) !important;
}

body .is-sticky .sticky-bg-primary.sticky-bg-op-3,
body .is-sticky .bg-primary.sticky-bg-op-3 {
  background-color: rgba(85, 167, 154, 0.3) !important;
}

body .is-sticky .sticky-bg-primary.sticky-bg-op-4,
body .is-sticky .bg-primary.sticky-bg-op-4 {
  background-color: rgba(85, 167, 154, 0.4) !important;
}

body .is-sticky .sticky-bg-primary.sticky-bg-op-5,
body .is-sticky .bg-primary.sticky-bg-op-5 {
  background-color: rgba(85, 167, 154, 0.5) !important;
}

body .is-sticky .sticky-bg-primary.sticky-bg-op-6,
body .is-sticky .bg-primary.sticky-bg-op-6 {
  background-color: rgba(85, 167, 154, 0.6) !important;
}

body .is-sticky .sticky-bg-primary.sticky-bg-op-7,
body .is-sticky .bg-primary.sticky-bg-op-7 {
  background-color: rgba(85, 167, 154, 0.7) !important;
}

body .is-sticky .sticky-bg-primary.sticky-bg-op-8,
body .is-sticky .bg-primary.sticky-bg-op-8 {
  background-color: rgba(85, 167, 154, 0.8) !important;
}

body .is-sticky .sticky-bg-primary.sticky-bg-op-9,
body .is-sticky .bg-primary.sticky-bg-op-9 {
  background-color: rgba(85, 167, 154, 0.9) !important;
}

body .is-sticky .sticky-bg-primary.sticky-bg-op-10,
body .is-sticky .bg-primary.sticky-bg-op-10 {
  background-color: #55a79a !important;
}

body .is-sticky .sticky-text-primary {
  color: #55A79A !important;
}

body .is-sticky .sticky-border-primary {
  border: 1px solid transparent;
  border-color: #55A79A !important;
}

body .is-sticky .sticky-border-primary.sticky-border-op-0,
body .is-sticky .border-primary.sticky-border-op-0 {
  border-color: rgba(85, 167, 154, 0) !important;
}

body .is-sticky .sticky-border-primary.sticky-border-op-1,
body .is-sticky .border-primary.sticky-border-op-1 {
  border-color: rgba(85, 167, 154, 0.1) !important;
}

body .is-sticky .sticky-border-primary.sticky-border-op-2,
body .is-sticky .border-primary.sticky-border-op-2 {
  border-color: rgba(85, 167, 154, 0.2) !important;
}

body .is-sticky .sticky-border-primary.sticky-border-op-3,
body .is-sticky .border-primary.sticky-border-op-3 {
  border-color: rgba(85, 167, 154, 0.3) !important;
}

body .is-sticky .sticky-border-primary.sticky-border-op-4,
body .is-sticky .border-primary.sticky-border-op-4 {
  border-color: rgba(85, 167, 154, 0.4) !important;
}

body .is-sticky .sticky-border-primary.sticky-border-op-5,
body .is-sticky .border-primary.sticky-border-op-5 {
  border-color: rgba(85, 167, 154, 0.5) !important;
}

body .is-sticky .sticky-border-primary.sticky-border-op-6,
body .is-sticky .border-primary.sticky-border-op-6 {
  border-color: rgba(85, 167, 154, 0.6) !important;
}

body .is-sticky .sticky-border-primary.sticky-border-op-7,
body .is-sticky .border-primary.sticky-border-op-7 {
  border-color: rgba(85, 167, 154, 0.7) !important;
}

body .is-sticky .sticky-border-primary.sticky-border-op-8,
body .is-sticky .border-primary.sticky-border-op-8 {
  border-color: rgba(85, 167, 154, 0.8) !important;
}

body .is-sticky .sticky-border-primary.sticky-border-op-9,
body .is-sticky .border-primary.sticky-border-op-9 {
  border-color: rgba(85, 167, 154, 0.9) !important;
}

body .is-sticky .sticky-border-primary.sticky-border-op-10,
body .is-sticky .border-primary.sticky-border-op-10 {
  border-color: #55a79a !important;
}

body .is-sticky .sticky-bg-primary-dark {
  background-color: #33635c !important;
}

body .is-sticky .sticky-bg-primary-dark.sticky-bg-op-0,
body .is-sticky .bg-primary-dark.sticky-bg-op-0 {
  background-color: rgba(51, 99, 92, 0) !important;
}

body .is-sticky .sticky-bg-primary-dark.sticky-bg-op-1,
body .is-sticky .bg-primary-dark.sticky-bg-op-1 {
  background-color: rgba(51, 99, 92, 0.1) !important;
}

body .is-sticky .sticky-bg-primary-dark.sticky-bg-op-2,
body .is-sticky .bg-primary-dark.sticky-bg-op-2 {
  background-color: rgba(51, 99, 92, 0.2) !important;
}

body .is-sticky .sticky-bg-primary-dark.sticky-bg-op-3,
body .is-sticky .bg-primary-dark.sticky-bg-op-3 {
  background-color: rgba(51, 99, 92, 0.3) !important;
}

body .is-sticky .sticky-bg-primary-dark.sticky-bg-op-4,
body .is-sticky .bg-primary-dark.sticky-bg-op-4 {
  background-color: rgba(51, 99, 92, 0.4) !important;
}

body .is-sticky .sticky-bg-primary-dark.sticky-bg-op-5,
body .is-sticky .bg-primary-dark.sticky-bg-op-5 {
  background-color: rgba(51, 99, 92, 0.5) !important;
}

body .is-sticky .sticky-bg-primary-dark.sticky-bg-op-6,
body .is-sticky .bg-primary-dark.sticky-bg-op-6 {
  background-color: rgba(51, 99, 92, 0.6) !important;
}

body .is-sticky .sticky-bg-primary-dark.sticky-bg-op-7,
body .is-sticky .bg-primary-dark.sticky-bg-op-7 {
  background-color: rgba(51, 99, 92, 0.7) !important;
}

body .is-sticky .sticky-bg-primary-dark.sticky-bg-op-8,
body .is-sticky .bg-primary-dark.sticky-bg-op-8 {
  background-color: rgba(51, 99, 92, 0.8) !important;
}

body .is-sticky .sticky-bg-primary-dark.sticky-bg-op-9,
body .is-sticky .bg-primary-dark.sticky-bg-op-9 {
  background-color: rgba(51, 99, 92, 0.9) !important;
}

body .is-sticky .sticky-bg-primary-dark.sticky-bg-op-10,
body .is-sticky .bg-primary-dark.sticky-bg-op-10 {
  background-color: #33635c !important;
}

body .is-sticky .sticky-text-primary-dark {
  color: #33635c !important;
}

body .is-sticky .sticky-border-primary-dark {
  border: 1px solid transparent;
  border-color: #33635c !important;
}

body .is-sticky .sticky-border-primary-dark.sticky-border-op-0,
body .is-sticky .border-primary-dark.sticky-border-op-0 {
  border-color: rgba(51, 99, 92, 0) !important;
}

body .is-sticky .sticky-border-primary-dark.sticky-border-op-1,
body .is-sticky .border-primary-dark.sticky-border-op-1 {
  border-color: rgba(51, 99, 92, 0.1) !important;
}

body .is-sticky .sticky-border-primary-dark.sticky-border-op-2,
body .is-sticky .border-primary-dark.sticky-border-op-2 {
  border-color: rgba(51, 99, 92, 0.2) !important;
}

body .is-sticky .sticky-border-primary-dark.sticky-border-op-3,
body .is-sticky .border-primary-dark.sticky-border-op-3 {
  border-color: rgba(51, 99, 92, 0.3) !important;
}

body .is-sticky .sticky-border-primary-dark.sticky-border-op-4,
body .is-sticky .border-primary-dark.sticky-border-op-4 {
  border-color: rgba(51, 99, 92, 0.4) !important;
}

body .is-sticky .sticky-border-primary-dark.sticky-border-op-5,
body .is-sticky .border-primary-dark.sticky-border-op-5 {
  border-color: rgba(51, 99, 92, 0.5) !important;
}

body .is-sticky .sticky-border-primary-dark.sticky-border-op-6,
body .is-sticky .border-primary-dark.sticky-border-op-6 {
  border-color: rgba(51, 99, 92, 0.6) !important;
}

body .is-sticky .sticky-border-primary-dark.sticky-border-op-7,
body .is-sticky .border-primary-dark.sticky-border-op-7 {
  border-color: rgba(51, 99, 92, 0.7) !important;
}

body .is-sticky .sticky-border-primary-dark.sticky-border-op-8,
body .is-sticky .border-primary-dark.sticky-border-op-8 {
  border-color: rgba(51, 99, 92, 0.8) !important;
}

body .is-sticky .sticky-border-primary-dark.sticky-border-op-9,
body .is-sticky .border-primary-dark.sticky-border-op-9 {
  border-color: rgba(51, 99, 92, 0.9) !important;
}

body .is-sticky .sticky-border-primary-dark.sticky-border-op-10,
body .is-sticky .border-primary-dark.sticky-border-op-10 {
  border-color: #33635c !important;
}

body .is-sticky .sticky-bg-primary-faded {
  background-color: rgba(85, 167, 154, 0.3) !important;
}

body .is-sticky .sticky-bg-primary-faded.sticky-bg-op-0,
body .is-sticky .bg-primary-faded.sticky-bg-op-0 {
  background-color: rgba(85, 167, 154, 0) !important;
}

body .is-sticky .sticky-bg-primary-faded.sticky-bg-op-1,
body .is-sticky .bg-primary-faded.sticky-bg-op-1 {
  background-color: rgba(85, 167, 154, 0.1) !important;
}

body .is-sticky .sticky-bg-primary-faded.sticky-bg-op-2,
body .is-sticky .bg-primary-faded.sticky-bg-op-2 {
  background-color: rgba(85, 167, 154, 0.2) !important;
}

body .is-sticky .sticky-bg-primary-faded.sticky-bg-op-3,
body .is-sticky .bg-primary-faded.sticky-bg-op-3 {
  background-color: rgba(85, 167, 154, 0.3) !important;
}

body .is-sticky .sticky-bg-primary-faded.sticky-bg-op-4,
body .is-sticky .bg-primary-faded.sticky-bg-op-4 {
  background-color: rgba(85, 167, 154, 0.4) !important;
}

body .is-sticky .sticky-bg-primary-faded.sticky-bg-op-5,
body .is-sticky .bg-primary-faded.sticky-bg-op-5 {
  background-color: rgba(85, 167, 154, 0.5) !important;
}

body .is-sticky .sticky-bg-primary-faded.sticky-bg-op-6,
body .is-sticky .bg-primary-faded.sticky-bg-op-6 {
  background-color: rgba(85, 167, 154, 0.6) !important;
}

body .is-sticky .sticky-bg-primary-faded.sticky-bg-op-7,
body .is-sticky .bg-primary-faded.sticky-bg-op-7 {
  background-color: rgba(85, 167, 154, 0.7) !important;
}

body .is-sticky .sticky-bg-primary-faded.sticky-bg-op-8,
body .is-sticky .bg-primary-faded.sticky-bg-op-8 {
  background-color: rgba(85, 167, 154, 0.8) !important;
}

body .is-sticky .sticky-bg-primary-faded.sticky-bg-op-9,
body .is-sticky .bg-primary-faded.sticky-bg-op-9 {
  background-color: rgba(85, 167, 154, 0.9) !important;
}

body .is-sticky .sticky-bg-primary-faded.sticky-bg-op-10,
body .is-sticky .bg-primary-faded.sticky-bg-op-10 {
  background-color: #55a79a !important;
}

body .is-sticky .sticky-text-primary-faded {
  color: rgba(85, 167, 154, 0.3) !important;
}

body .is-sticky .sticky-border-primary-faded {
  border: 1px solid transparent;
  border-color: rgba(85, 167, 154, 0.3) !important;
}

body .is-sticky .sticky-border-primary-faded.sticky-border-op-0,
body .is-sticky .border-primary-faded.sticky-border-op-0 {
  border-color: rgba(85, 167, 154, 0) !important;
}

body .is-sticky .sticky-border-primary-faded.sticky-border-op-1,
body .is-sticky .border-primary-faded.sticky-border-op-1 {
  border-color: rgba(85, 167, 154, 0.1) !important;
}

body .is-sticky .sticky-border-primary-faded.sticky-border-op-2,
body .is-sticky .border-primary-faded.sticky-border-op-2 {
  border-color: rgba(85, 167, 154, 0.2) !important;
}

body .is-sticky .sticky-border-primary-faded.sticky-border-op-3,
body .is-sticky .border-primary-faded.sticky-border-op-3 {
  border-color: rgba(85, 167, 154, 0.3) !important;
}

body .is-sticky .sticky-border-primary-faded.sticky-border-op-4,
body .is-sticky .border-primary-faded.sticky-border-op-4 {
  border-color: rgba(85, 167, 154, 0.4) !important;
}

body .is-sticky .sticky-border-primary-faded.sticky-border-op-5,
body .is-sticky .border-primary-faded.sticky-border-op-5 {
  border-color: rgba(85, 167, 154, 0.5) !important;
}

body .is-sticky .sticky-border-primary-faded.sticky-border-op-6,
body .is-sticky .border-primary-faded.sticky-border-op-6 {
  border-color: rgba(85, 167, 154, 0.6) !important;
}

body .is-sticky .sticky-border-primary-faded.sticky-border-op-7,
body .is-sticky .border-primary-faded.sticky-border-op-7 {
  border-color: rgba(85, 167, 154, 0.7) !important;
}

body .is-sticky .sticky-border-primary-faded.sticky-border-op-8,
body .is-sticky .border-primary-faded.sticky-border-op-8 {
  border-color: rgba(85, 167, 154, 0.8) !important;
}

body .is-sticky .sticky-border-primary-faded.sticky-border-op-9,
body .is-sticky .border-primary-faded.sticky-border-op-9 {
  border-color: rgba(85, 167, 154, 0.9) !important;
}

body .is-sticky .sticky-border-primary-faded.sticky-border-op-10,
body .is-sticky .border-primary-faded.sticky-border-op-10 {
  border-color: #55a79a !important;
}

body .is-sticky .sticky-bg-info {
  background-color: #17a2b8 !important;
}

body .is-sticky .sticky-bg-info.sticky-bg-op-0,
body .is-sticky .bg-info.sticky-bg-op-0 {
  background-color: rgba(23, 162, 184, 0) !important;
}

body .is-sticky .sticky-bg-info.sticky-bg-op-1,
body .is-sticky .bg-info.sticky-bg-op-1 {
  background-color: rgba(23, 162, 184, 0.1) !important;
}

body .is-sticky .sticky-bg-info.sticky-bg-op-2,
body .is-sticky .bg-info.sticky-bg-op-2 {
  background-color: rgba(23, 162, 184, 0.2) !important;
}

body .is-sticky .sticky-bg-info.sticky-bg-op-3,
body .is-sticky .bg-info.sticky-bg-op-3 {
  background-color: rgba(23, 162, 184, 0.3) !important;
}

body .is-sticky .sticky-bg-info.sticky-bg-op-4,
body .is-sticky .bg-info.sticky-bg-op-4 {
  background-color: rgba(23, 162, 184, 0.4) !important;
}

body .is-sticky .sticky-bg-info.sticky-bg-op-5,
body .is-sticky .bg-info.sticky-bg-op-5 {
  background-color: rgba(23, 162, 184, 0.5) !important;
}

body .is-sticky .sticky-bg-info.sticky-bg-op-6,
body .is-sticky .bg-info.sticky-bg-op-6 {
  background-color: rgba(23, 162, 184, 0.6) !important;
}

body .is-sticky .sticky-bg-info.sticky-bg-op-7,
body .is-sticky .bg-info.sticky-bg-op-7 {
  background-color: rgba(23, 162, 184, 0.7) !important;
}

body .is-sticky .sticky-bg-info.sticky-bg-op-8,
body .is-sticky .bg-info.sticky-bg-op-8 {
  background-color: rgba(23, 162, 184, 0.8) !important;
}

body .is-sticky .sticky-bg-info.sticky-bg-op-9,
body .is-sticky .bg-info.sticky-bg-op-9 {
  background-color: rgba(23, 162, 184, 0.9) !important;
}

body .is-sticky .sticky-bg-info.sticky-bg-op-10,
body .is-sticky .bg-info.sticky-bg-op-10 {
  background-color: #17a2b8 !important;
}

body .is-sticky .sticky-text-info {
  color: #17a2b8 !important;
}

body .is-sticky .sticky-border-info {
  border: 1px solid transparent;
  border-color: #17a2b8 !important;
}

body .is-sticky .sticky-border-info.sticky-border-op-0,
body .is-sticky .border-info.sticky-border-op-0 {
  border-color: rgba(23, 162, 184, 0) !important;
}

body .is-sticky .sticky-border-info.sticky-border-op-1,
body .is-sticky .border-info.sticky-border-op-1 {
  border-color: rgba(23, 162, 184, 0.1) !important;
}

body .is-sticky .sticky-border-info.sticky-border-op-2,
body .is-sticky .border-info.sticky-border-op-2 {
  border-color: rgba(23, 162, 184, 0.2) !important;
}

body .is-sticky .sticky-border-info.sticky-border-op-3,
body .is-sticky .border-info.sticky-border-op-3 {
  border-color: rgba(23, 162, 184, 0.3) !important;
}

body .is-sticky .sticky-border-info.sticky-border-op-4,
body .is-sticky .border-info.sticky-border-op-4 {
  border-color: rgba(23, 162, 184, 0.4) !important;
}

body .is-sticky .sticky-border-info.sticky-border-op-5,
body .is-sticky .border-info.sticky-border-op-5 {
  border-color: rgba(23, 162, 184, 0.5) !important;
}

body .is-sticky .sticky-border-info.sticky-border-op-6,
body .is-sticky .border-info.sticky-border-op-6 {
  border-color: rgba(23, 162, 184, 0.6) !important;
}

body .is-sticky .sticky-border-info.sticky-border-op-7,
body .is-sticky .border-info.sticky-border-op-7 {
  border-color: rgba(23, 162, 184, 0.7) !important;
}

body .is-sticky .sticky-border-info.sticky-border-op-8,
body .is-sticky .border-info.sticky-border-op-8 {
  border-color: rgba(23, 162, 184, 0.8) !important;
}

body .is-sticky .sticky-border-info.sticky-border-op-9,
body .is-sticky .border-info.sticky-border-op-9 {
  border-color: rgba(23, 162, 184, 0.9) !important;
}

body .is-sticky .sticky-border-info.sticky-border-op-10,
body .is-sticky .border-info.sticky-border-op-10 {
  border-color: #17a2b8 !important;
}

body .is-sticky .sticky-bg-success {
  background-color: #28a745 !important;
}

body .is-sticky .sticky-bg-success.sticky-bg-op-0,
body .is-sticky .bg-success.sticky-bg-op-0 {
  background-color: rgba(40, 167, 69, 0) !important;
}

body .is-sticky .sticky-bg-success.sticky-bg-op-1,
body .is-sticky .bg-success.sticky-bg-op-1 {
  background-color: rgba(40, 167, 69, 0.1) !important;
}

body .is-sticky .sticky-bg-success.sticky-bg-op-2,
body .is-sticky .bg-success.sticky-bg-op-2 {
  background-color: rgba(40, 167, 69, 0.2) !important;
}

body .is-sticky .sticky-bg-success.sticky-bg-op-3,
body .is-sticky .bg-success.sticky-bg-op-3 {
  background-color: rgba(40, 167, 69, 0.3) !important;
}

body .is-sticky .sticky-bg-success.sticky-bg-op-4,
body .is-sticky .bg-success.sticky-bg-op-4 {
  background-color: rgba(40, 167, 69, 0.4) !important;
}

body .is-sticky .sticky-bg-success.sticky-bg-op-5,
body .is-sticky .bg-success.sticky-bg-op-5 {
  background-color: rgba(40, 167, 69, 0.5) !important;
}

body .is-sticky .sticky-bg-success.sticky-bg-op-6,
body .is-sticky .bg-success.sticky-bg-op-6 {
  background-color: rgba(40, 167, 69, 0.6) !important;
}

body .is-sticky .sticky-bg-success.sticky-bg-op-7,
body .is-sticky .bg-success.sticky-bg-op-7 {
  background-color: rgba(40, 167, 69, 0.7) !important;
}

body .is-sticky .sticky-bg-success.sticky-bg-op-8,
body .is-sticky .bg-success.sticky-bg-op-8 {
  background-color: rgba(40, 167, 69, 0.8) !important;
}

body .is-sticky .sticky-bg-success.sticky-bg-op-9,
body .is-sticky .bg-success.sticky-bg-op-9 {
  background-color: rgba(40, 167, 69, 0.9) !important;
}

body .is-sticky .sticky-bg-success.sticky-bg-op-10,
body .is-sticky .bg-success.sticky-bg-op-10 {
  background-color: #28a745 !important;
}

body .is-sticky .sticky-text-success {
  color: #28a745 !important;
}

body .is-sticky .sticky-border-success {
  border: 1px solid transparent;
  border-color: #28a745 !important;
}

body .is-sticky .sticky-border-success.sticky-border-op-0,
body .is-sticky .border-success.sticky-border-op-0 {
  border-color: rgba(40, 167, 69, 0) !important;
}

body .is-sticky .sticky-border-success.sticky-border-op-1,
body .is-sticky .border-success.sticky-border-op-1 {
  border-color: rgba(40, 167, 69, 0.1) !important;
}

body .is-sticky .sticky-border-success.sticky-border-op-2,
body .is-sticky .border-success.sticky-border-op-2 {
  border-color: rgba(40, 167, 69, 0.2) !important;
}

body .is-sticky .sticky-border-success.sticky-border-op-3,
body .is-sticky .border-success.sticky-border-op-3 {
  border-color: rgba(40, 167, 69, 0.3) !important;
}

body .is-sticky .sticky-border-success.sticky-border-op-4,
body .is-sticky .border-success.sticky-border-op-4 {
  border-color: rgba(40, 167, 69, 0.4) !important;
}

body .is-sticky .sticky-border-success.sticky-border-op-5,
body .is-sticky .border-success.sticky-border-op-5 {
  border-color: rgba(40, 167, 69, 0.5) !important;
}

body .is-sticky .sticky-border-success.sticky-border-op-6,
body .is-sticky .border-success.sticky-border-op-6 {
  border-color: rgba(40, 167, 69, 0.6) !important;
}

body .is-sticky .sticky-border-success.sticky-border-op-7,
body .is-sticky .border-success.sticky-border-op-7 {
  border-color: rgba(40, 167, 69, 0.7) !important;
}

body .is-sticky .sticky-border-success.sticky-border-op-8,
body .is-sticky .border-success.sticky-border-op-8 {
  border-color: rgba(40, 167, 69, 0.8) !important;
}

body .is-sticky .sticky-border-success.sticky-border-op-9,
body .is-sticky .border-success.sticky-border-op-9 {
  border-color: rgba(40, 167, 69, 0.9) !important;
}

body .is-sticky .sticky-border-success.sticky-border-op-10,
body .is-sticky .border-success.sticky-border-op-10 {
  border-color: #28a745 !important;
}

body .is-sticky .sticky-bg-warning {
  background-color: #ffc107 !important;
}

body .is-sticky .sticky-bg-warning.sticky-bg-op-0,
body .is-sticky .bg-warning.sticky-bg-op-0 {
  background-color: rgba(255, 193, 7, 0) !important;
}

body .is-sticky .sticky-bg-warning.sticky-bg-op-1,
body .is-sticky .bg-warning.sticky-bg-op-1 {
  background-color: rgba(255, 193, 7, 0.1) !important;
}

body .is-sticky .sticky-bg-warning.sticky-bg-op-2,
body .is-sticky .bg-warning.sticky-bg-op-2 {
  background-color: rgba(255, 193, 7, 0.2) !important;
}

body .is-sticky .sticky-bg-warning.sticky-bg-op-3,
body .is-sticky .bg-warning.sticky-bg-op-3 {
  background-color: rgba(255, 193, 7, 0.3) !important;
}

body .is-sticky .sticky-bg-warning.sticky-bg-op-4,
body .is-sticky .bg-warning.sticky-bg-op-4 {
  background-color: rgba(255, 193, 7, 0.4) !important;
}

body .is-sticky .sticky-bg-warning.sticky-bg-op-5,
body .is-sticky .bg-warning.sticky-bg-op-5 {
  background-color: rgba(255, 193, 7, 0.5) !important;
}

body .is-sticky .sticky-bg-warning.sticky-bg-op-6,
body .is-sticky .bg-warning.sticky-bg-op-6 {
  background-color: rgba(255, 193, 7, 0.6) !important;
}

body .is-sticky .sticky-bg-warning.sticky-bg-op-7,
body .is-sticky .bg-warning.sticky-bg-op-7 {
  background-color: rgba(255, 193, 7, 0.7) !important;
}

body .is-sticky .sticky-bg-warning.sticky-bg-op-8,
body .is-sticky .bg-warning.sticky-bg-op-8 {
  background-color: rgba(255, 193, 7, 0.8) !important;
}

body .is-sticky .sticky-bg-warning.sticky-bg-op-9,
body .is-sticky .bg-warning.sticky-bg-op-9 {
  background-color: rgba(255, 193, 7, 0.9) !important;
}

body .is-sticky .sticky-bg-warning.sticky-bg-op-10,
body .is-sticky .bg-warning.sticky-bg-op-10 {
  background-color: #ffc107 !important;
}

body .is-sticky .sticky-text-warning {
  color: #ffc107 !important;
}

body .is-sticky .sticky-border-warning {
  border: 1px solid transparent;
  border-color: #ffc107 !important;
}

body .is-sticky .sticky-border-warning.sticky-border-op-0,
body .is-sticky .border-warning.sticky-border-op-0 {
  border-color: rgba(255, 193, 7, 0) !important;
}

body .is-sticky .sticky-border-warning.sticky-border-op-1,
body .is-sticky .border-warning.sticky-border-op-1 {
  border-color: rgba(255, 193, 7, 0.1) !important;
}

body .is-sticky .sticky-border-warning.sticky-border-op-2,
body .is-sticky .border-warning.sticky-border-op-2 {
  border-color: rgba(255, 193, 7, 0.2) !important;
}

body .is-sticky .sticky-border-warning.sticky-border-op-3,
body .is-sticky .border-warning.sticky-border-op-3 {
  border-color: rgba(255, 193, 7, 0.3) !important;
}

body .is-sticky .sticky-border-warning.sticky-border-op-4,
body .is-sticky .border-warning.sticky-border-op-4 {
  border-color: rgba(255, 193, 7, 0.4) !important;
}

body .is-sticky .sticky-border-warning.sticky-border-op-5,
body .is-sticky .border-warning.sticky-border-op-5 {
  border-color: rgba(255, 193, 7, 0.5) !important;
}

body .is-sticky .sticky-border-warning.sticky-border-op-6,
body .is-sticky .border-warning.sticky-border-op-6 {
  border-color: rgba(255, 193, 7, 0.6) !important;
}

body .is-sticky .sticky-border-warning.sticky-border-op-7,
body .is-sticky .border-warning.sticky-border-op-7 {
  border-color: rgba(255, 193, 7, 0.7) !important;
}

body .is-sticky .sticky-border-warning.sticky-border-op-8,
body .is-sticky .border-warning.sticky-border-op-8 {
  border-color: rgba(255, 193, 7, 0.8) !important;
}

body .is-sticky .sticky-border-warning.sticky-border-op-9,
body .is-sticky .border-warning.sticky-border-op-9 {
  border-color: rgba(255, 193, 7, 0.9) !important;
}

body .is-sticky .sticky-border-warning.sticky-border-op-10,
body .is-sticky .border-warning.sticky-border-op-10 {
  border-color: #ffc107 !important;
}

body .is-sticky .sticky-bg-danger {
  background-color: #dc3545 !important;
}

body .is-sticky .sticky-bg-danger.sticky-bg-op-0,
body .is-sticky .bg-danger.sticky-bg-op-0 {
  background-color: rgba(220, 53, 69, 0) !important;
}

body .is-sticky .sticky-bg-danger.sticky-bg-op-1,
body .is-sticky .bg-danger.sticky-bg-op-1 {
  background-color: rgba(220, 53, 69, 0.1) !important;
}

body .is-sticky .sticky-bg-danger.sticky-bg-op-2,
body .is-sticky .bg-danger.sticky-bg-op-2 {
  background-color: rgba(220, 53, 69, 0.2) !important;
}

body .is-sticky .sticky-bg-danger.sticky-bg-op-3,
body .is-sticky .bg-danger.sticky-bg-op-3 {
  background-color: rgba(220, 53, 69, 0.3) !important;
}

body .is-sticky .sticky-bg-danger.sticky-bg-op-4,
body .is-sticky .bg-danger.sticky-bg-op-4 {
  background-color: rgba(220, 53, 69, 0.4) !important;
}

body .is-sticky .sticky-bg-danger.sticky-bg-op-5,
body .is-sticky .bg-danger.sticky-bg-op-5 {
  background-color: rgba(220, 53, 69, 0.5) !important;
}

body .is-sticky .sticky-bg-danger.sticky-bg-op-6,
body .is-sticky .bg-danger.sticky-bg-op-6 {
  background-color: rgba(220, 53, 69, 0.6) !important;
}

body .is-sticky .sticky-bg-danger.sticky-bg-op-7,
body .is-sticky .bg-danger.sticky-bg-op-7 {
  background-color: rgba(220, 53, 69, 0.7) !important;
}

body .is-sticky .sticky-bg-danger.sticky-bg-op-8,
body .is-sticky .bg-danger.sticky-bg-op-8 {
  background-color: rgba(220, 53, 69, 0.8) !important;
}

body .is-sticky .sticky-bg-danger.sticky-bg-op-9,
body .is-sticky .bg-danger.sticky-bg-op-9 {
  background-color: rgba(220, 53, 69, 0.9) !important;
}

body .is-sticky .sticky-bg-danger.sticky-bg-op-10,
body .is-sticky .bg-danger.sticky-bg-op-10 {
  background-color: #dc3545 !important;
}

body .is-sticky .sticky-text-danger {
  color: #dc3545 !important;
}

body .is-sticky .sticky-border-danger {
  border: 1px solid transparent;
  border-color: #dc3545 !important;
}

body .is-sticky .sticky-border-danger.sticky-border-op-0,
body .is-sticky .border-danger.sticky-border-op-0 {
  border-color: rgba(220, 53, 69, 0) !important;
}

body .is-sticky .sticky-border-danger.sticky-border-op-1,
body .is-sticky .border-danger.sticky-border-op-1 {
  border-color: rgba(220, 53, 69, 0.1) !important;
}

body .is-sticky .sticky-border-danger.sticky-border-op-2,
body .is-sticky .border-danger.sticky-border-op-2 {
  border-color: rgba(220, 53, 69, 0.2) !important;
}

body .is-sticky .sticky-border-danger.sticky-border-op-3,
body .is-sticky .border-danger.sticky-border-op-3 {
  border-color: rgba(220, 53, 69, 0.3) !important;
}

body .is-sticky .sticky-border-danger.sticky-border-op-4,
body .is-sticky .border-danger.sticky-border-op-4 {
  border-color: rgba(220, 53, 69, 0.4) !important;
}

body .is-sticky .sticky-border-danger.sticky-border-op-5,
body .is-sticky .border-danger.sticky-border-op-5 {
  border-color: rgba(220, 53, 69, 0.5) !important;
}

body .is-sticky .sticky-border-danger.sticky-border-op-6,
body .is-sticky .border-danger.sticky-border-op-6 {
  border-color: rgba(220, 53, 69, 0.6) !important;
}

body .is-sticky .sticky-border-danger.sticky-border-op-7,
body .is-sticky .border-danger.sticky-border-op-7 {
  border-color: rgba(220, 53, 69, 0.7) !important;
}

body .is-sticky .sticky-border-danger.sticky-border-op-8,
body .is-sticky .border-danger.sticky-border-op-8 {
  border-color: rgba(220, 53, 69, 0.8) !important;
}

body .is-sticky .sticky-border-danger.sticky-border-op-9,
body .is-sticky .border-danger.sticky-border-op-9 {
  border-color: rgba(220, 53, 69, 0.9) !important;
}

body .is-sticky .sticky-border-danger.sticky-border-op-10,
body .is-sticky .border-danger.sticky-border-op-10 {
  border-color: #dc3545 !important;
}

body .is-sticky .sticky-bg-dark {
  background-color: #343a40 !important;
}

body .is-sticky .sticky-bg-dark.sticky-bg-op-0,
body .is-sticky .bg-dark.sticky-bg-op-0 {
  background-color: rgba(52, 58, 64, 0) !important;
}

body .is-sticky .sticky-bg-dark.sticky-bg-op-1,
body .is-sticky .bg-dark.sticky-bg-op-1 {
  background-color: rgba(52, 58, 64, 0.1) !important;
}

body .is-sticky .sticky-bg-dark.sticky-bg-op-2,
body .is-sticky .bg-dark.sticky-bg-op-2 {
  background-color: rgba(52, 58, 64, 0.2) !important;
}

body .is-sticky .sticky-bg-dark.sticky-bg-op-3,
body .is-sticky .bg-dark.sticky-bg-op-3 {
  background-color: rgba(52, 58, 64, 0.3) !important;
}

body .is-sticky .sticky-bg-dark.sticky-bg-op-4,
body .is-sticky .bg-dark.sticky-bg-op-4 {
  background-color: rgba(52, 58, 64, 0.4) !important;
}

body .is-sticky .sticky-bg-dark.sticky-bg-op-5,
body .is-sticky .bg-dark.sticky-bg-op-5 {
  background-color: rgba(52, 58, 64, 0.5) !important;
}

body .is-sticky .sticky-bg-dark.sticky-bg-op-6,
body .is-sticky .bg-dark.sticky-bg-op-6 {
  background-color: rgba(52, 58, 64, 0.6) !important;
}

body .is-sticky .sticky-bg-dark.sticky-bg-op-7,
body .is-sticky .bg-dark.sticky-bg-op-7 {
  background-color: rgba(52, 58, 64, 0.7) !important;
}

body .is-sticky .sticky-bg-dark.sticky-bg-op-8,
body .is-sticky .bg-dark.sticky-bg-op-8 {
  background-color: rgba(52, 58, 64, 0.8) !important;
}

body .is-sticky .sticky-bg-dark.sticky-bg-op-9,
body .is-sticky .bg-dark.sticky-bg-op-9 {
  background-color: rgba(52, 58, 64, 0.9) !important;
}

body .is-sticky .sticky-bg-dark.sticky-bg-op-10,
body .is-sticky .bg-dark.sticky-bg-op-10 {
  background-color: #343a40 !important;
}

body .is-sticky .sticky-text-dark {
  color: #343a40 !important;
}

body .is-sticky .sticky-border-dark {
  border: 1px solid transparent;
  border-color: #343a40 !important;
}

body .is-sticky .sticky-border-dark.sticky-border-op-0,
body .is-sticky .border-dark.sticky-border-op-0 {
  border-color: rgba(52, 58, 64, 0) !important;
}

body .is-sticky .sticky-border-dark.sticky-border-op-1,
body .is-sticky .border-dark.sticky-border-op-1 {
  border-color: rgba(52, 58, 64, 0.1) !important;
}

body .is-sticky .sticky-border-dark.sticky-border-op-2,
body .is-sticky .border-dark.sticky-border-op-2 {
  border-color: rgba(52, 58, 64, 0.2) !important;
}

body .is-sticky .sticky-border-dark.sticky-border-op-3,
body .is-sticky .border-dark.sticky-border-op-3 {
  border-color: rgba(52, 58, 64, 0.3) !important;
}

body .is-sticky .sticky-border-dark.sticky-border-op-4,
body .is-sticky .border-dark.sticky-border-op-4 {
  border-color: rgba(52, 58, 64, 0.4) !important;
}

body .is-sticky .sticky-border-dark.sticky-border-op-5,
body .is-sticky .border-dark.sticky-border-op-5 {
  border-color: rgba(52, 58, 64, 0.5) !important;
}

body .is-sticky .sticky-border-dark.sticky-border-op-6,
body .is-sticky .border-dark.sticky-border-op-6 {
  border-color: rgba(52, 58, 64, 0.6) !important;
}

body .is-sticky .sticky-border-dark.sticky-border-op-7,
body .is-sticky .border-dark.sticky-border-op-7 {
  border-color: rgba(52, 58, 64, 0.7) !important;
}

body .is-sticky .sticky-border-dark.sticky-border-op-8,
body .is-sticky .border-dark.sticky-border-op-8 {
  border-color: rgba(52, 58, 64, 0.8) !important;
}

body .is-sticky .sticky-border-dark.sticky-border-op-9,
body .is-sticky .border-dark.sticky-border-op-9 {
  border-color: rgba(52, 58, 64, 0.9) !important;
}

body .is-sticky .sticky-border-dark.sticky-border-op-10,
body .is-sticky .border-dark.sticky-border-op-10 {
  border-color: #343a40 !important;
}

body .is-sticky .sticky-bg-secondary {
  background-color: #6c757d !important;
}

body .is-sticky .sticky-bg-secondary.sticky-bg-op-0,
body .is-sticky .bg-secondary.sticky-bg-op-0 {
  background-color: rgba(108, 117, 125, 0) !important;
}

body .is-sticky .sticky-bg-secondary.sticky-bg-op-1,
body .is-sticky .bg-secondary.sticky-bg-op-1 {
  background-color: rgba(108, 117, 125, 0.1) !important;
}

body .is-sticky .sticky-bg-secondary.sticky-bg-op-2,
body .is-sticky .bg-secondary.sticky-bg-op-2 {
  background-color: rgba(108, 117, 125, 0.2) !important;
}

body .is-sticky .sticky-bg-secondary.sticky-bg-op-3,
body .is-sticky .bg-secondary.sticky-bg-op-3 {
  background-color: rgba(108, 117, 125, 0.3) !important;
}

body .is-sticky .sticky-bg-secondary.sticky-bg-op-4,
body .is-sticky .bg-secondary.sticky-bg-op-4 {
  background-color: rgba(108, 117, 125, 0.4) !important;
}

body .is-sticky .sticky-bg-secondary.sticky-bg-op-5,
body .is-sticky .bg-secondary.sticky-bg-op-5 {
  background-color: rgba(108, 117, 125, 0.5) !important;
}

body .is-sticky .sticky-bg-secondary.sticky-bg-op-6,
body .is-sticky .bg-secondary.sticky-bg-op-6 {
  background-color: rgba(108, 117, 125, 0.6) !important;
}

body .is-sticky .sticky-bg-secondary.sticky-bg-op-7,
body .is-sticky .bg-secondary.sticky-bg-op-7 {
  background-color: rgba(108, 117, 125, 0.7) !important;
}

body .is-sticky .sticky-bg-secondary.sticky-bg-op-8,
body .is-sticky .bg-secondary.sticky-bg-op-8 {
  background-color: rgba(108, 117, 125, 0.8) !important;
}

body .is-sticky .sticky-bg-secondary.sticky-bg-op-9,
body .is-sticky .bg-secondary.sticky-bg-op-9 {
  background-color: rgba(108, 117, 125, 0.9) !important;
}

body .is-sticky .sticky-bg-secondary.sticky-bg-op-10,
body .is-sticky .bg-secondary.sticky-bg-op-10 {
  background-color: #6c757d !important;
}

body .is-sticky .sticky-text-secondary {
  color: #6c757d !important;
}

body .is-sticky .sticky-border-secondary {
  border: 1px solid transparent;
  border-color: #6c757d !important;
}

body .is-sticky .sticky-border-secondary.sticky-border-op-0,
body .is-sticky .border-secondary.sticky-border-op-0 {
  border-color: rgba(108, 117, 125, 0) !important;
}

body .is-sticky .sticky-border-secondary.sticky-border-op-1,
body .is-sticky .border-secondary.sticky-border-op-1 {
  border-color: rgba(108, 117, 125, 0.1) !important;
}

body .is-sticky .sticky-border-secondary.sticky-border-op-2,
body .is-sticky .border-secondary.sticky-border-op-2 {
  border-color: rgba(108, 117, 125, 0.2) !important;
}

body .is-sticky .sticky-border-secondary.sticky-border-op-3,
body .is-sticky .border-secondary.sticky-border-op-3 {
  border-color: rgba(108, 117, 125, 0.3) !important;
}

body .is-sticky .sticky-border-secondary.sticky-border-op-4,
body .is-sticky .border-secondary.sticky-border-op-4 {
  border-color: rgba(108, 117, 125, 0.4) !important;
}

body .is-sticky .sticky-border-secondary.sticky-border-op-5,
body .is-sticky .border-secondary.sticky-border-op-5 {
  border-color: rgba(108, 117, 125, 0.5) !important;
}

body .is-sticky .sticky-border-secondary.sticky-border-op-6,
body .is-sticky .border-secondary.sticky-border-op-6 {
  border-color: rgba(108, 117, 125, 0.6) !important;
}

body .is-sticky .sticky-border-secondary.sticky-border-op-7,
body .is-sticky .border-secondary.sticky-border-op-7 {
  border-color: rgba(108, 117, 125, 0.7) !important;
}

body .is-sticky .sticky-border-secondary.sticky-border-op-8,
body .is-sticky .border-secondary.sticky-border-op-8 {
  border-color: rgba(108, 117, 125, 0.8) !important;
}

body .is-sticky .sticky-border-secondary.sticky-border-op-9,
body .is-sticky .border-secondary.sticky-border-op-9 {
  border-color: rgba(108, 117, 125, 0.9) !important;
}

body .is-sticky .sticky-border-secondary.sticky-border-op-10,
body .is-sticky .border-secondary.sticky-border-op-10 {
  border-color: #6c757d !important;
}

body .is-sticky .sticky-bg-black {
  background-color: #000 !important;
}

body .is-sticky .sticky-bg-black.sticky-bg-op-0,
body .is-sticky .bg-black.sticky-bg-op-0 {
  background-color: transparent !important;
}

body .is-sticky .sticky-bg-black.sticky-bg-op-1,
body .is-sticky .bg-black.sticky-bg-op-1 {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

body .is-sticky .sticky-bg-black.sticky-bg-op-2,
body .is-sticky .bg-black.sticky-bg-op-2 {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

body .is-sticky .sticky-bg-black.sticky-bg-op-3,
body .is-sticky .bg-black.sticky-bg-op-3 {
  background-color: rgba(0, 0, 0, 0.3) !important;
}

body .is-sticky .sticky-bg-black.sticky-bg-op-4,
body .is-sticky .bg-black.sticky-bg-op-4 {
  background-color: rgba(0, 0, 0, 0.4) !important;
}

body .is-sticky .sticky-bg-black.sticky-bg-op-5,
body .is-sticky .bg-black.sticky-bg-op-5 {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

body .is-sticky .sticky-bg-black.sticky-bg-op-6,
body .is-sticky .bg-black.sticky-bg-op-6 {
  background-color: rgba(0, 0, 0, 0.6) !important;
}

body .is-sticky .sticky-bg-black.sticky-bg-op-7,
body .is-sticky .bg-black.sticky-bg-op-7 {
  background-color: rgba(0, 0, 0, 0.7) !important;
}

body .is-sticky .sticky-bg-black.sticky-bg-op-8,
body .is-sticky .bg-black.sticky-bg-op-8 {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

body .is-sticky .sticky-bg-black.sticky-bg-op-9,
body .is-sticky .bg-black.sticky-bg-op-9 {
  background-color: rgba(0, 0, 0, 0.9) !important;
}

body .is-sticky .sticky-bg-black.sticky-bg-op-10,
body .is-sticky .bg-black.sticky-bg-op-10 {
  background-color: black !important;
}

body .is-sticky .sticky-text-black {
  color: #000 !important;
}

body .is-sticky .sticky-border-black {
  border: 1px solid transparent;
  border-color: #000 !important;
}

body .is-sticky .sticky-border-black.sticky-border-op-0,
body .is-sticky .border-black.sticky-border-op-0 {
  border-color: transparent !important;
}

body .is-sticky .sticky-border-black.sticky-border-op-1,
body .is-sticky .border-black.sticky-border-op-1 {
  border-color: rgba(0, 0, 0, 0.1) !important;
}

body .is-sticky .sticky-border-black.sticky-border-op-2,
body .is-sticky .border-black.sticky-border-op-2 {
  border-color: rgba(0, 0, 0, 0.2) !important;
}

body .is-sticky .sticky-border-black.sticky-border-op-3,
body .is-sticky .border-black.sticky-border-op-3 {
  border-color: rgba(0, 0, 0, 0.3) !important;
}

body .is-sticky .sticky-border-black.sticky-border-op-4,
body .is-sticky .border-black.sticky-border-op-4 {
  border-color: rgba(0, 0, 0, 0.4) !important;
}

body .is-sticky .sticky-border-black.sticky-border-op-5,
body .is-sticky .border-black.sticky-border-op-5 {
  border-color: rgba(0, 0, 0, 0.5) !important;
}

body .is-sticky .sticky-border-black.sticky-border-op-6,
body .is-sticky .border-black.sticky-border-op-6 {
  border-color: rgba(0, 0, 0, 0.6) !important;
}

body .is-sticky .sticky-border-black.sticky-border-op-7,
body .is-sticky .border-black.sticky-border-op-7 {
  border-color: rgba(0, 0, 0, 0.7) !important;
}

body .is-sticky .sticky-border-black.sticky-border-op-8,
body .is-sticky .border-black.sticky-border-op-8 {
  border-color: rgba(0, 0, 0, 0.8) !important;
}

body .is-sticky .sticky-border-black.sticky-border-op-9,
body .is-sticky .border-black.sticky-border-op-9 {
  border-color: rgba(0, 0, 0, 0.9) !important;
}

body .is-sticky .sticky-border-black.sticky-border-op-10,
body .is-sticky .border-black.sticky-border-op-10 {
  border-color: black !important;
}

body .is-sticky .sticky-bg-white {
  background-color: #fff !important;
}

body .is-sticky .sticky-bg-white.sticky-bg-op-0,
body .is-sticky .bg-white.sticky-bg-op-0 {
  background-color: rgba(255, 255, 255, 0) !important;
}

body .is-sticky .sticky-bg-white.sticky-bg-op-1,
body .is-sticky .bg-white.sticky-bg-op-1 {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

body .is-sticky .sticky-bg-white.sticky-bg-op-2,
body .is-sticky .bg-white.sticky-bg-op-2 {
  background-color: rgba(255, 255, 255, 0.2) !important;
}

body .is-sticky .sticky-bg-white.sticky-bg-op-3,
body .is-sticky .bg-white.sticky-bg-op-3 {
  background-color: rgba(255, 255, 255, 0.3) !important;
}

body .is-sticky .sticky-bg-white.sticky-bg-op-4,
body .is-sticky .bg-white.sticky-bg-op-4 {
  background-color: rgba(255, 255, 255, 0.4) !important;
}

body .is-sticky .sticky-bg-white.sticky-bg-op-5,
body .is-sticky .bg-white.sticky-bg-op-5 {
  background-color: rgba(255, 255, 255, 0.5) !important;
}

body .is-sticky .sticky-bg-white.sticky-bg-op-6,
body .is-sticky .bg-white.sticky-bg-op-6 {
  background-color: rgba(255, 255, 255, 0.6) !important;
}

body .is-sticky .sticky-bg-white.sticky-bg-op-7,
body .is-sticky .bg-white.sticky-bg-op-7 {
  background-color: rgba(255, 255, 255, 0.7) !important;
}

body .is-sticky .sticky-bg-white.sticky-bg-op-8,
body .is-sticky .bg-white.sticky-bg-op-8 {
  background-color: rgba(255, 255, 255, 0.8) !important;
}

body .is-sticky .sticky-bg-white.sticky-bg-op-9,
body .is-sticky .bg-white.sticky-bg-op-9 {
  background-color: rgba(255, 255, 255, 0.9) !important;
}

body .is-sticky .sticky-bg-white.sticky-bg-op-10,
body .is-sticky .bg-white.sticky-bg-op-10 {
  background-color: white !important;
}

body .is-sticky .sticky-text-white {
  color: #fff !important;
}

body .is-sticky .sticky-border-white {
  border: 1px solid transparent;
  border-color: #fff !important;
}

body .is-sticky .sticky-border-white.sticky-border-op-0,
body .is-sticky .border-white.sticky-border-op-0 {
  border-color: rgba(255, 255, 255, 0) !important;
}

body .is-sticky .sticky-border-white.sticky-border-op-1,
body .is-sticky .border-white.sticky-border-op-1 {
  border-color: rgba(255, 255, 255, 0.1) !important;
}

body .is-sticky .sticky-border-white.sticky-border-op-2,
body .is-sticky .border-white.sticky-border-op-2 {
  border-color: rgba(255, 255, 255, 0.2) !important;
}

body .is-sticky .sticky-border-white.sticky-border-op-3,
body .is-sticky .border-white.sticky-border-op-3 {
  border-color: rgba(255, 255, 255, 0.3) !important;
}

body .is-sticky .sticky-border-white.sticky-border-op-4,
body .is-sticky .border-white.sticky-border-op-4 {
  border-color: rgba(255, 255, 255, 0.4) !important;
}

body .is-sticky .sticky-border-white.sticky-border-op-5,
body .is-sticky .border-white.sticky-border-op-5 {
  border-color: rgba(255, 255, 255, 0.5) !important;
}

body .is-sticky .sticky-border-white.sticky-border-op-6,
body .is-sticky .border-white.sticky-border-op-6 {
  border-color: rgba(255, 255, 255, 0.6) !important;
}

body .is-sticky .sticky-border-white.sticky-border-op-7,
body .is-sticky .border-white.sticky-border-op-7 {
  border-color: rgba(255, 255, 255, 0.7) !important;
}

body .is-sticky .sticky-border-white.sticky-border-op-8,
body .is-sticky .border-white.sticky-border-op-8 {
  border-color: rgba(255, 255, 255, 0.8) !important;
}

body .is-sticky .sticky-border-white.sticky-border-op-9,
body .is-sticky .border-white.sticky-border-op-9 {
  border-color: rgba(255, 255, 255, 0.9) !important;
}

body .is-sticky .sticky-border-white.sticky-border-op-10,
body .is-sticky .border-white.sticky-border-op-10 {
  border-color: white !important;
}

body .is-sticky .sticky-bg-green {
  background-color: #55A79A !important;
}

body .is-sticky .sticky-bg-green.sticky-bg-op-0,
body .is-sticky .bg-green.sticky-bg-op-0 {
  background-color: rgba(85, 167, 154, 0) !important;
}

body .is-sticky .sticky-bg-green.sticky-bg-op-1,
body .is-sticky .bg-green.sticky-bg-op-1 {
  background-color: rgba(85, 167, 154, 0.1) !important;
}

body .is-sticky .sticky-bg-green.sticky-bg-op-2,
body .is-sticky .bg-green.sticky-bg-op-2 {
  background-color: rgba(85, 167, 154, 0.2) !important;
}

body .is-sticky .sticky-bg-green.sticky-bg-op-3,
body .is-sticky .bg-green.sticky-bg-op-3 {
  background-color: rgba(85, 167, 154, 0.3) !important;
}

body .is-sticky .sticky-bg-green.sticky-bg-op-4,
body .is-sticky .bg-green.sticky-bg-op-4 {
  background-color: rgba(85, 167, 154, 0.4) !important;
}

body .is-sticky .sticky-bg-green.sticky-bg-op-5,
body .is-sticky .bg-green.sticky-bg-op-5 {
  background-color: rgba(85, 167, 154, 0.5) !important;
}

body .is-sticky .sticky-bg-green.sticky-bg-op-6,
body .is-sticky .bg-green.sticky-bg-op-6 {
  background-color: rgba(85, 167, 154, 0.6) !important;
}

body .is-sticky .sticky-bg-green.sticky-bg-op-7,
body .is-sticky .bg-green.sticky-bg-op-7 {
  background-color: rgba(85, 167, 154, 0.7) !important;
}

body .is-sticky .sticky-bg-green.sticky-bg-op-8,
body .is-sticky .bg-green.sticky-bg-op-8 {
  background-color: rgba(85, 167, 154, 0.8) !important;
}

body .is-sticky .sticky-bg-green.sticky-bg-op-9,
body .is-sticky .bg-green.sticky-bg-op-9 {
  background-color: rgba(85, 167, 154, 0.9) !important;
}

body .is-sticky .sticky-bg-green.sticky-bg-op-10,
body .is-sticky .bg-green.sticky-bg-op-10 {
  background-color: #55a79a !important;
}

body .is-sticky .sticky-text-green {
  color: #55A79A !important;
}

body .is-sticky .sticky-border-green {
  border: 1px solid transparent;
  border-color: #55A79A !important;
}

body .is-sticky .sticky-border-green.sticky-border-op-0,
body .is-sticky .border-green.sticky-border-op-0 {
  border-color: rgba(85, 167, 154, 0) !important;
}

body .is-sticky .sticky-border-green.sticky-border-op-1,
body .is-sticky .border-green.sticky-border-op-1 {
  border-color: rgba(85, 167, 154, 0.1) !important;
}

body .is-sticky .sticky-border-green.sticky-border-op-2,
body .is-sticky .border-green.sticky-border-op-2 {
  border-color: rgba(85, 167, 154, 0.2) !important;
}

body .is-sticky .sticky-border-green.sticky-border-op-3,
body .is-sticky .border-green.sticky-border-op-3 {
  border-color: rgba(85, 167, 154, 0.3) !important;
}

body .is-sticky .sticky-border-green.sticky-border-op-4,
body .is-sticky .border-green.sticky-border-op-4 {
  border-color: rgba(85, 167, 154, 0.4) !important;
}

body .is-sticky .sticky-border-green.sticky-border-op-5,
body .is-sticky .border-green.sticky-border-op-5 {
  border-color: rgba(85, 167, 154, 0.5) !important;
}

body .is-sticky .sticky-border-green.sticky-border-op-6,
body .is-sticky .border-green.sticky-border-op-6 {
  border-color: rgba(85, 167, 154, 0.6) !important;
}

body .is-sticky .sticky-border-green.sticky-border-op-7,
body .is-sticky .border-green.sticky-border-op-7 {
  border-color: rgba(85, 167, 154, 0.7) !important;
}

body .is-sticky .sticky-border-green.sticky-border-op-8,
body .is-sticky .border-green.sticky-border-op-8 {
  border-color: rgba(85, 167, 154, 0.8) !important;
}

body .is-sticky .sticky-border-green.sticky-border-op-9,
body .is-sticky .border-green.sticky-border-op-9 {
  border-color: rgba(85, 167, 154, 0.9) !important;
}

body .is-sticky .sticky-border-green.sticky-border-op-10,
body .is-sticky .border-green.sticky-border-op-10 {
  border-color: #55a79a !important;
}

body .is-sticky .sticky-bg-red {
  background-color: #BE3E1D !important;
}

body .is-sticky .sticky-bg-red.sticky-bg-op-0,
body .is-sticky .bg-red.sticky-bg-op-0 {
  background-color: rgba(190, 62, 29, 0) !important;
}

body .is-sticky .sticky-bg-red.sticky-bg-op-1,
body .is-sticky .bg-red.sticky-bg-op-1 {
  background-color: rgba(190, 62, 29, 0.1) !important;
}

body .is-sticky .sticky-bg-red.sticky-bg-op-2,
body .is-sticky .bg-red.sticky-bg-op-2 {
  background-color: rgba(190, 62, 29, 0.2) !important;
}

body .is-sticky .sticky-bg-red.sticky-bg-op-3,
body .is-sticky .bg-red.sticky-bg-op-3 {
  background-color: rgba(190, 62, 29, 0.3) !important;
}

body .is-sticky .sticky-bg-red.sticky-bg-op-4,
body .is-sticky .bg-red.sticky-bg-op-4 {
  background-color: rgba(190, 62, 29, 0.4) !important;
}

body .is-sticky .sticky-bg-red.sticky-bg-op-5,
body .is-sticky .bg-red.sticky-bg-op-5 {
  background-color: rgba(190, 62, 29, 0.5) !important;
}

body .is-sticky .sticky-bg-red.sticky-bg-op-6,
body .is-sticky .bg-red.sticky-bg-op-6 {
  background-color: rgba(190, 62, 29, 0.6) !important;
}

body .is-sticky .sticky-bg-red.sticky-bg-op-7,
body .is-sticky .bg-red.sticky-bg-op-7 {
  background-color: rgba(190, 62, 29, 0.7) !important;
}

body .is-sticky .sticky-bg-red.sticky-bg-op-8,
body .is-sticky .bg-red.sticky-bg-op-8 {
  background-color: rgba(190, 62, 29, 0.8) !important;
}

body .is-sticky .sticky-bg-red.sticky-bg-op-9,
body .is-sticky .bg-red.sticky-bg-op-9 {
  background-color: rgba(190, 62, 29, 0.9) !important;
}

body .is-sticky .sticky-bg-red.sticky-bg-op-10,
body .is-sticky .bg-red.sticky-bg-op-10 {
  background-color: #be3e1d !important;
}

body .is-sticky .sticky-text-red {
  color: #BE3E1D !important;
}

body .is-sticky .sticky-border-red {
  border: 1px solid transparent;
  border-color: #BE3E1D !important;
}

body .is-sticky .sticky-border-red.sticky-border-op-0,
body .is-sticky .border-red.sticky-border-op-0 {
  border-color: rgba(190, 62, 29, 0) !important;
}

body .is-sticky .sticky-border-red.sticky-border-op-1,
body .is-sticky .border-red.sticky-border-op-1 {
  border-color: rgba(190, 62, 29, 0.1) !important;
}

body .is-sticky .sticky-border-red.sticky-border-op-2,
body .is-sticky .border-red.sticky-border-op-2 {
  border-color: rgba(190, 62, 29, 0.2) !important;
}

body .is-sticky .sticky-border-red.sticky-border-op-3,
body .is-sticky .border-red.sticky-border-op-3 {
  border-color: rgba(190, 62, 29, 0.3) !important;
}

body .is-sticky .sticky-border-red.sticky-border-op-4,
body .is-sticky .border-red.sticky-border-op-4 {
  border-color: rgba(190, 62, 29, 0.4) !important;
}

body .is-sticky .sticky-border-red.sticky-border-op-5,
body .is-sticky .border-red.sticky-border-op-5 {
  border-color: rgba(190, 62, 29, 0.5) !important;
}

body .is-sticky .sticky-border-red.sticky-border-op-6,
body .is-sticky .border-red.sticky-border-op-6 {
  border-color: rgba(190, 62, 29, 0.6) !important;
}

body .is-sticky .sticky-border-red.sticky-border-op-7,
body .is-sticky .border-red.sticky-border-op-7 {
  border-color: rgba(190, 62, 29, 0.7) !important;
}

body .is-sticky .sticky-border-red.sticky-border-op-8,
body .is-sticky .border-red.sticky-border-op-8 {
  border-color: rgba(190, 62, 29, 0.8) !important;
}

body .is-sticky .sticky-border-red.sticky-border-op-9,
body .is-sticky .border-red.sticky-border-op-9 {
  border-color: rgba(190, 62, 29, 0.9) !important;
}

body .is-sticky .sticky-border-red.sticky-border-op-10,
body .is-sticky .border-red.sticky-border-op-10 {
  border-color: #be3e1d !important;
}

body .is-sticky .sticky-bg-blue {
  background-color: #00ADBB !important;
}

body .is-sticky .sticky-bg-blue.sticky-bg-op-0,
body .is-sticky .bg-blue.sticky-bg-op-0 {
  background-color: rgba(0, 173, 187, 0) !important;
}

body .is-sticky .sticky-bg-blue.sticky-bg-op-1,
body .is-sticky .bg-blue.sticky-bg-op-1 {
  background-color: rgba(0, 173, 187, 0.1) !important;
}

body .is-sticky .sticky-bg-blue.sticky-bg-op-2,
body .is-sticky .bg-blue.sticky-bg-op-2 {
  background-color: rgba(0, 173, 187, 0.2) !important;
}

body .is-sticky .sticky-bg-blue.sticky-bg-op-3,
body .is-sticky .bg-blue.sticky-bg-op-3 {
  background-color: rgba(0, 173, 187, 0.3) !important;
}

body .is-sticky .sticky-bg-blue.sticky-bg-op-4,
body .is-sticky .bg-blue.sticky-bg-op-4 {
  background-color: rgba(0, 173, 187, 0.4) !important;
}

body .is-sticky .sticky-bg-blue.sticky-bg-op-5,
body .is-sticky .bg-blue.sticky-bg-op-5 {
  background-color: rgba(0, 173, 187, 0.5) !important;
}

body .is-sticky .sticky-bg-blue.sticky-bg-op-6,
body .is-sticky .bg-blue.sticky-bg-op-6 {
  background-color: rgba(0, 173, 187, 0.6) !important;
}

body .is-sticky .sticky-bg-blue.sticky-bg-op-7,
body .is-sticky .bg-blue.sticky-bg-op-7 {
  background-color: rgba(0, 173, 187, 0.7) !important;
}

body .is-sticky .sticky-bg-blue.sticky-bg-op-8,
body .is-sticky .bg-blue.sticky-bg-op-8 {
  background-color: rgba(0, 173, 187, 0.8) !important;
}

body .is-sticky .sticky-bg-blue.sticky-bg-op-9,
body .is-sticky .bg-blue.sticky-bg-op-9 {
  background-color: rgba(0, 173, 187, 0.9) !important;
}

body .is-sticky .sticky-bg-blue.sticky-bg-op-10,
body .is-sticky .bg-blue.sticky-bg-op-10 {
  background-color: #00adbb !important;
}

body .is-sticky .sticky-text-blue {
  color: #00ADBB !important;
}

body .is-sticky .sticky-border-blue {
  border: 1px solid transparent;
  border-color: #00ADBB !important;
}

body .is-sticky .sticky-border-blue.sticky-border-op-0,
body .is-sticky .border-blue.sticky-border-op-0 {
  border-color: rgba(0, 173, 187, 0) !important;
}

body .is-sticky .sticky-border-blue.sticky-border-op-1,
body .is-sticky .border-blue.sticky-border-op-1 {
  border-color: rgba(0, 173, 187, 0.1) !important;
}

body .is-sticky .sticky-border-blue.sticky-border-op-2,
body .is-sticky .border-blue.sticky-border-op-2 {
  border-color: rgba(0, 173, 187, 0.2) !important;
}

body .is-sticky .sticky-border-blue.sticky-border-op-3,
body .is-sticky .border-blue.sticky-border-op-3 {
  border-color: rgba(0, 173, 187, 0.3) !important;
}

body .is-sticky .sticky-border-blue.sticky-border-op-4,
body .is-sticky .border-blue.sticky-border-op-4 {
  border-color: rgba(0, 173, 187, 0.4) !important;
}

body .is-sticky .sticky-border-blue.sticky-border-op-5,
body .is-sticky .border-blue.sticky-border-op-5 {
  border-color: rgba(0, 173, 187, 0.5) !important;
}

body .is-sticky .sticky-border-blue.sticky-border-op-6,
body .is-sticky .border-blue.sticky-border-op-6 {
  border-color: rgba(0, 173, 187, 0.6) !important;
}

body .is-sticky .sticky-border-blue.sticky-border-op-7,
body .is-sticky .border-blue.sticky-border-op-7 {
  border-color: rgba(0, 173, 187, 0.7) !important;
}

body .is-sticky .sticky-border-blue.sticky-border-op-8,
body .is-sticky .border-blue.sticky-border-op-8 {
  border-color: rgba(0, 173, 187, 0.8) !important;
}

body .is-sticky .sticky-border-blue.sticky-border-op-9,
body .is-sticky .border-blue.sticky-border-op-9 {
  border-color: rgba(0, 173, 187, 0.9) !important;
}

body .is-sticky .sticky-border-blue.sticky-border-op-10,
body .is-sticky .border-blue.sticky-border-op-10 {
  border-color: #00adbb !important;
}

body .is-sticky .sticky-bg-purple {
  background-color: #b771b0 !important;
}

body .is-sticky .sticky-bg-purple.sticky-bg-op-0,
body .is-sticky .bg-purple.sticky-bg-op-0 {
  background-color: rgba(183, 113, 176, 0) !important;
}

body .is-sticky .sticky-bg-purple.sticky-bg-op-1,
body .is-sticky .bg-purple.sticky-bg-op-1 {
  background-color: rgba(183, 113, 176, 0.1) !important;
}

body .is-sticky .sticky-bg-purple.sticky-bg-op-2,
body .is-sticky .bg-purple.sticky-bg-op-2 {
  background-color: rgba(183, 113, 176, 0.2) !important;
}

body .is-sticky .sticky-bg-purple.sticky-bg-op-3,
body .is-sticky .bg-purple.sticky-bg-op-3 {
  background-color: rgba(183, 113, 176, 0.3) !important;
}

body .is-sticky .sticky-bg-purple.sticky-bg-op-4,
body .is-sticky .bg-purple.sticky-bg-op-4 {
  background-color: rgba(183, 113, 176, 0.4) !important;
}

body .is-sticky .sticky-bg-purple.sticky-bg-op-5,
body .is-sticky .bg-purple.sticky-bg-op-5 {
  background-color: rgba(183, 113, 176, 0.5) !important;
}

body .is-sticky .sticky-bg-purple.sticky-bg-op-6,
body .is-sticky .bg-purple.sticky-bg-op-6 {
  background-color: rgba(183, 113, 176, 0.6) !important;
}

body .is-sticky .sticky-bg-purple.sticky-bg-op-7,
body .is-sticky .bg-purple.sticky-bg-op-7 {
  background-color: rgba(183, 113, 176, 0.7) !important;
}

body .is-sticky .sticky-bg-purple.sticky-bg-op-8,
body .is-sticky .bg-purple.sticky-bg-op-8 {
  background-color: rgba(183, 113, 176, 0.8) !important;
}

body .is-sticky .sticky-bg-purple.sticky-bg-op-9,
body .is-sticky .bg-purple.sticky-bg-op-9 {
  background-color: rgba(183, 113, 176, 0.9) !important;
}

body .is-sticky .sticky-bg-purple.sticky-bg-op-10,
body .is-sticky .bg-purple.sticky-bg-op-10 {
  background-color: #b771b0 !important;
}

body .is-sticky .sticky-text-purple {
  color: #b771b0 !important;
}

body .is-sticky .sticky-border-purple {
  border: 1px solid transparent;
  border-color: #b771b0 !important;
}

body .is-sticky .sticky-border-purple.sticky-border-op-0,
body .is-sticky .border-purple.sticky-border-op-0 {
  border-color: rgba(183, 113, 176, 0) !important;
}

body .is-sticky .sticky-border-purple.sticky-border-op-1,
body .is-sticky .border-purple.sticky-border-op-1 {
  border-color: rgba(183, 113, 176, 0.1) !important;
}

body .is-sticky .sticky-border-purple.sticky-border-op-2,
body .is-sticky .border-purple.sticky-border-op-2 {
  border-color: rgba(183, 113, 176, 0.2) !important;
}

body .is-sticky .sticky-border-purple.sticky-border-op-3,
body .is-sticky .border-purple.sticky-border-op-3 {
  border-color: rgba(183, 113, 176, 0.3) !important;
}

body .is-sticky .sticky-border-purple.sticky-border-op-4,
body .is-sticky .border-purple.sticky-border-op-4 {
  border-color: rgba(183, 113, 176, 0.4) !important;
}

body .is-sticky .sticky-border-purple.sticky-border-op-5,
body .is-sticky .border-purple.sticky-border-op-5 {
  border-color: rgba(183, 113, 176, 0.5) !important;
}

body .is-sticky .sticky-border-purple.sticky-border-op-6,
body .is-sticky .border-purple.sticky-border-op-6 {
  border-color: rgba(183, 113, 176, 0.6) !important;
}

body .is-sticky .sticky-border-purple.sticky-border-op-7,
body .is-sticky .border-purple.sticky-border-op-7 {
  border-color: rgba(183, 113, 176, 0.7) !important;
}

body .is-sticky .sticky-border-purple.sticky-border-op-8,
body .is-sticky .border-purple.sticky-border-op-8 {
  border-color: rgba(183, 113, 176, 0.8) !important;
}

body .is-sticky .sticky-border-purple.sticky-border-op-9,
body .is-sticky .border-purple.sticky-border-op-9 {
  border-color: rgba(183, 113, 176, 0.9) !important;
}

body .is-sticky .sticky-border-purple.sticky-border-op-10,
body .is-sticky .border-purple.sticky-border-op-10 {
  border-color: #b771b0 !important;
}

body .is-sticky .sticky-bg-pink {
  background-color: #CC164D !important;
}

body .is-sticky .sticky-bg-pink.sticky-bg-op-0,
body .is-sticky .bg-pink.sticky-bg-op-0 {
  background-color: rgba(204, 22, 77, 0) !important;
}

body .is-sticky .sticky-bg-pink.sticky-bg-op-1,
body .is-sticky .bg-pink.sticky-bg-op-1 {
  background-color: rgba(204, 22, 77, 0.1) !important;
}

body .is-sticky .sticky-bg-pink.sticky-bg-op-2,
body .is-sticky .bg-pink.sticky-bg-op-2 {
  background-color: rgba(204, 22, 77, 0.2) !important;
}

body .is-sticky .sticky-bg-pink.sticky-bg-op-3,
body .is-sticky .bg-pink.sticky-bg-op-3 {
  background-color: rgba(204, 22, 77, 0.3) !important;
}

body .is-sticky .sticky-bg-pink.sticky-bg-op-4,
body .is-sticky .bg-pink.sticky-bg-op-4 {
  background-color: rgba(204, 22, 77, 0.4) !important;
}

body .is-sticky .sticky-bg-pink.sticky-bg-op-5,
body .is-sticky .bg-pink.sticky-bg-op-5 {
  background-color: rgba(204, 22, 77, 0.5) !important;
}

body .is-sticky .sticky-bg-pink.sticky-bg-op-6,
body .is-sticky .bg-pink.sticky-bg-op-6 {
  background-color: rgba(204, 22, 77, 0.6) !important;
}

body .is-sticky .sticky-bg-pink.sticky-bg-op-7,
body .is-sticky .bg-pink.sticky-bg-op-7 {
  background-color: rgba(204, 22, 77, 0.7) !important;
}

body .is-sticky .sticky-bg-pink.sticky-bg-op-8,
body .is-sticky .bg-pink.sticky-bg-op-8 {
  background-color: rgba(204, 22, 77, 0.8) !important;
}

body .is-sticky .sticky-bg-pink.sticky-bg-op-9,
body .is-sticky .bg-pink.sticky-bg-op-9 {
  background-color: rgba(204, 22, 77, 0.9) !important;
}

body .is-sticky .sticky-bg-pink.sticky-bg-op-10,
body .is-sticky .bg-pink.sticky-bg-op-10 {
  background-color: #cc164d !important;
}

body .is-sticky .sticky-text-pink {
  color: #CC164D !important;
}

body .is-sticky .sticky-border-pink {
  border: 1px solid transparent;
  border-color: #CC164D !important;
}

body .is-sticky .sticky-border-pink.sticky-border-op-0,
body .is-sticky .border-pink.sticky-border-op-0 {
  border-color: rgba(204, 22, 77, 0) !important;
}

body .is-sticky .sticky-border-pink.sticky-border-op-1,
body .is-sticky .border-pink.sticky-border-op-1 {
  border-color: rgba(204, 22, 77, 0.1) !important;
}

body .is-sticky .sticky-border-pink.sticky-border-op-2,
body .is-sticky .border-pink.sticky-border-op-2 {
  border-color: rgba(204, 22, 77, 0.2) !important;
}

body .is-sticky .sticky-border-pink.sticky-border-op-3,
body .is-sticky .border-pink.sticky-border-op-3 {
  border-color: rgba(204, 22, 77, 0.3) !important;
}

body .is-sticky .sticky-border-pink.sticky-border-op-4,
body .is-sticky .border-pink.sticky-border-op-4 {
  border-color: rgba(204, 22, 77, 0.4) !important;
}

body .is-sticky .sticky-border-pink.sticky-border-op-5,
body .is-sticky .border-pink.sticky-border-op-5 {
  border-color: rgba(204, 22, 77, 0.5) !important;
}

body .is-sticky .sticky-border-pink.sticky-border-op-6,
body .is-sticky .border-pink.sticky-border-op-6 {
  border-color: rgba(204, 22, 77, 0.6) !important;
}

body .is-sticky .sticky-border-pink.sticky-border-op-7,
body .is-sticky .border-pink.sticky-border-op-7 {
  border-color: rgba(204, 22, 77, 0.7) !important;
}

body .is-sticky .sticky-border-pink.sticky-border-op-8,
body .is-sticky .border-pink.sticky-border-op-8 {
  border-color: rgba(204, 22, 77, 0.8) !important;
}

body .is-sticky .sticky-border-pink.sticky-border-op-9,
body .is-sticky .border-pink.sticky-border-op-9 {
  border-color: rgba(204, 22, 77, 0.9) !important;
}

body .is-sticky .sticky-border-pink.sticky-border-op-10,
body .is-sticky .border-pink.sticky-border-op-10 {
  border-color: #cc164d !important;
}

body .is-sticky .sticky-bg-orange {
  background-color: #e67e22 !important;
}

body .is-sticky .sticky-bg-orange.sticky-bg-op-0,
body .is-sticky .bg-orange.sticky-bg-op-0 {
  background-color: rgba(230, 126, 34, 0) !important;
}

body .is-sticky .sticky-bg-orange.sticky-bg-op-1,
body .is-sticky .bg-orange.sticky-bg-op-1 {
  background-color: rgba(230, 126, 34, 0.1) !important;
}

body .is-sticky .sticky-bg-orange.sticky-bg-op-2,
body .is-sticky .bg-orange.sticky-bg-op-2 {
  background-color: rgba(230, 126, 34, 0.2) !important;
}

body .is-sticky .sticky-bg-orange.sticky-bg-op-3,
body .is-sticky .bg-orange.sticky-bg-op-3 {
  background-color: rgba(230, 126, 34, 0.3) !important;
}

body .is-sticky .sticky-bg-orange.sticky-bg-op-4,
body .is-sticky .bg-orange.sticky-bg-op-4 {
  background-color: rgba(230, 126, 34, 0.4) !important;
}

body .is-sticky .sticky-bg-orange.sticky-bg-op-5,
body .is-sticky .bg-orange.sticky-bg-op-5 {
  background-color: rgba(230, 126, 34, 0.5) !important;
}

body .is-sticky .sticky-bg-orange.sticky-bg-op-6,
body .is-sticky .bg-orange.sticky-bg-op-6 {
  background-color: rgba(230, 126, 34, 0.6) !important;
}

body .is-sticky .sticky-bg-orange.sticky-bg-op-7,
body .is-sticky .bg-orange.sticky-bg-op-7 {
  background-color: rgba(230, 126, 34, 0.7) !important;
}

body .is-sticky .sticky-bg-orange.sticky-bg-op-8,
body .is-sticky .bg-orange.sticky-bg-op-8 {
  background-color: rgba(230, 126, 34, 0.8) !important;
}

body .is-sticky .sticky-bg-orange.sticky-bg-op-9,
body .is-sticky .bg-orange.sticky-bg-op-9 {
  background-color: rgba(230, 126, 34, 0.9) !important;
}

body .is-sticky .sticky-bg-orange.sticky-bg-op-10,
body .is-sticky .bg-orange.sticky-bg-op-10 {
  background-color: #e67e22 !important;
}

body .is-sticky .sticky-text-orange {
  color: #e67e22 !important;
}

body .is-sticky .sticky-border-orange {
  border: 1px solid transparent;
  border-color: #e67e22 !important;
}

body .is-sticky .sticky-border-orange.sticky-border-op-0,
body .is-sticky .border-orange.sticky-border-op-0 {
  border-color: rgba(230, 126, 34, 0) !important;
}

body .is-sticky .sticky-border-orange.sticky-border-op-1,
body .is-sticky .border-orange.sticky-border-op-1 {
  border-color: rgba(230, 126, 34, 0.1) !important;
}

body .is-sticky .sticky-border-orange.sticky-border-op-2,
body .is-sticky .border-orange.sticky-border-op-2 {
  border-color: rgba(230, 126, 34, 0.2) !important;
}

body .is-sticky .sticky-border-orange.sticky-border-op-3,
body .is-sticky .border-orange.sticky-border-op-3 {
  border-color: rgba(230, 126, 34, 0.3) !important;
}

body .is-sticky .sticky-border-orange.sticky-border-op-4,
body .is-sticky .border-orange.sticky-border-op-4 {
  border-color: rgba(230, 126, 34, 0.4) !important;
}

body .is-sticky .sticky-border-orange.sticky-border-op-5,
body .is-sticky .border-orange.sticky-border-op-5 {
  border-color: rgba(230, 126, 34, 0.5) !important;
}

body .is-sticky .sticky-border-orange.sticky-border-op-6,
body .is-sticky .border-orange.sticky-border-op-6 {
  border-color: rgba(230, 126, 34, 0.6) !important;
}

body .is-sticky .sticky-border-orange.sticky-border-op-7,
body .is-sticky .border-orange.sticky-border-op-7 {
  border-color: rgba(230, 126, 34, 0.7) !important;
}

body .is-sticky .sticky-border-orange.sticky-border-op-8,
body .is-sticky .border-orange.sticky-border-op-8 {
  border-color: rgba(230, 126, 34, 0.8) !important;
}

body .is-sticky .sticky-border-orange.sticky-border-op-9,
body .is-sticky .border-orange.sticky-border-op-9 {
  border-color: rgba(230, 126, 34, 0.9) !important;
}

body .is-sticky .sticky-border-orange.sticky-border-op-10,
body .is-sticky .border-orange.sticky-border-op-10 {
  border-color: #e67e22 !important;
}

body .is-sticky .sticky-bg-lime {
  background-color: #b1dc44 !important;
}

body .is-sticky .sticky-bg-lime.sticky-bg-op-0,
body .is-sticky .bg-lime.sticky-bg-op-0 {
  background-color: rgba(177, 220, 68, 0) !important;
}

body .is-sticky .sticky-bg-lime.sticky-bg-op-1,
body .is-sticky .bg-lime.sticky-bg-op-1 {
  background-color: rgba(177, 220, 68, 0.1) !important;
}

body .is-sticky .sticky-bg-lime.sticky-bg-op-2,
body .is-sticky .bg-lime.sticky-bg-op-2 {
  background-color: rgba(177, 220, 68, 0.2) !important;
}

body .is-sticky .sticky-bg-lime.sticky-bg-op-3,
body .is-sticky .bg-lime.sticky-bg-op-3 {
  background-color: rgba(177, 220, 68, 0.3) !important;
}

body .is-sticky .sticky-bg-lime.sticky-bg-op-4,
body .is-sticky .bg-lime.sticky-bg-op-4 {
  background-color: rgba(177, 220, 68, 0.4) !important;
}

body .is-sticky .sticky-bg-lime.sticky-bg-op-5,
body .is-sticky .bg-lime.sticky-bg-op-5 {
  background-color: rgba(177, 220, 68, 0.5) !important;
}

body .is-sticky .sticky-bg-lime.sticky-bg-op-6,
body .is-sticky .bg-lime.sticky-bg-op-6 {
  background-color: rgba(177, 220, 68, 0.6) !important;
}

body .is-sticky .sticky-bg-lime.sticky-bg-op-7,
body .is-sticky .bg-lime.sticky-bg-op-7 {
  background-color: rgba(177, 220, 68, 0.7) !important;
}

body .is-sticky .sticky-bg-lime.sticky-bg-op-8,
body .is-sticky .bg-lime.sticky-bg-op-8 {
  background-color: rgba(177, 220, 68, 0.8) !important;
}

body .is-sticky .sticky-bg-lime.sticky-bg-op-9,
body .is-sticky .bg-lime.sticky-bg-op-9 {
  background-color: rgba(177, 220, 68, 0.9) !important;
}

body .is-sticky .sticky-bg-lime.sticky-bg-op-10,
body .is-sticky .bg-lime.sticky-bg-op-10 {
  background-color: #b1dc44 !important;
}

body .is-sticky .sticky-text-lime {
  color: #b1dc44 !important;
}

body .is-sticky .sticky-border-lime {
  border: 1px solid transparent;
  border-color: #b1dc44 !important;
}

body .is-sticky .sticky-border-lime.sticky-border-op-0,
body .is-sticky .border-lime.sticky-border-op-0 {
  border-color: rgba(177, 220, 68, 0) !important;
}

body .is-sticky .sticky-border-lime.sticky-border-op-1,
body .is-sticky .border-lime.sticky-border-op-1 {
  border-color: rgba(177, 220, 68, 0.1) !important;
}

body .is-sticky .sticky-border-lime.sticky-border-op-2,
body .is-sticky .border-lime.sticky-border-op-2 {
  border-color: rgba(177, 220, 68, 0.2) !important;
}

body .is-sticky .sticky-border-lime.sticky-border-op-3,
body .is-sticky .border-lime.sticky-border-op-3 {
  border-color: rgba(177, 220, 68, 0.3) !important;
}

body .is-sticky .sticky-border-lime.sticky-border-op-4,
body .is-sticky .border-lime.sticky-border-op-4 {
  border-color: rgba(177, 220, 68, 0.4) !important;
}

body .is-sticky .sticky-border-lime.sticky-border-op-5,
body .is-sticky .border-lime.sticky-border-op-5 {
  border-color: rgba(177, 220, 68, 0.5) !important;
}

body .is-sticky .sticky-border-lime.sticky-border-op-6,
body .is-sticky .border-lime.sticky-border-op-6 {
  border-color: rgba(177, 220, 68, 0.6) !important;
}

body .is-sticky .sticky-border-lime.sticky-border-op-7,
body .is-sticky .border-lime.sticky-border-op-7 {
  border-color: rgba(177, 220, 68, 0.7) !important;
}

body .is-sticky .sticky-border-lime.sticky-border-op-8,
body .is-sticky .border-lime.sticky-border-op-8 {
  border-color: rgba(177, 220, 68, 0.8) !important;
}

body .is-sticky .sticky-border-lime.sticky-border-op-9,
body .is-sticky .border-lime.sticky-border-op-9 {
  border-color: rgba(177, 220, 68, 0.9) !important;
}

body .is-sticky .sticky-border-lime.sticky-border-op-10,
body .is-sticky .border-lime.sticky-border-op-10 {
  border-color: #b1dc44 !important;
}

body .is-sticky .sticky-bg-blue-dark {
  background-color: #34495e !important;
}

body .is-sticky .sticky-bg-blue-dark.sticky-bg-op-0,
body .is-sticky .bg-blue-dark.sticky-bg-op-0 {
  background-color: rgba(52, 73, 94, 0) !important;
}

body .is-sticky .sticky-bg-blue-dark.sticky-bg-op-1,
body .is-sticky .bg-blue-dark.sticky-bg-op-1 {
  background-color: rgba(52, 73, 94, 0.1) !important;
}

body .is-sticky .sticky-bg-blue-dark.sticky-bg-op-2,
body .is-sticky .bg-blue-dark.sticky-bg-op-2 {
  background-color: rgba(52, 73, 94, 0.2) !important;
}

body .is-sticky .sticky-bg-blue-dark.sticky-bg-op-3,
body .is-sticky .bg-blue-dark.sticky-bg-op-3 {
  background-color: rgba(52, 73, 94, 0.3) !important;
}

body .is-sticky .sticky-bg-blue-dark.sticky-bg-op-4,
body .is-sticky .bg-blue-dark.sticky-bg-op-4 {
  background-color: rgba(52, 73, 94, 0.4) !important;
}

body .is-sticky .sticky-bg-blue-dark.sticky-bg-op-5,
body .is-sticky .bg-blue-dark.sticky-bg-op-5 {
  background-color: rgba(52, 73, 94, 0.5) !important;
}

body .is-sticky .sticky-bg-blue-dark.sticky-bg-op-6,
body .is-sticky .bg-blue-dark.sticky-bg-op-6 {
  background-color: rgba(52, 73, 94, 0.6) !important;
}

body .is-sticky .sticky-bg-blue-dark.sticky-bg-op-7,
body .is-sticky .bg-blue-dark.sticky-bg-op-7 {
  background-color: rgba(52, 73, 94, 0.7) !important;
}

body .is-sticky .sticky-bg-blue-dark.sticky-bg-op-8,
body .is-sticky .bg-blue-dark.sticky-bg-op-8 {
  background-color: rgba(52, 73, 94, 0.8) !important;
}

body .is-sticky .sticky-bg-blue-dark.sticky-bg-op-9,
body .is-sticky .bg-blue-dark.sticky-bg-op-9 {
  background-color: rgba(52, 73, 94, 0.9) !important;
}

body .is-sticky .sticky-bg-blue-dark.sticky-bg-op-10,
body .is-sticky .bg-blue-dark.sticky-bg-op-10 {
  background-color: #34495e !important;
}

body .is-sticky .sticky-text-blue-dark {
  color: #34495e !important;
}

body .is-sticky .sticky-border-blue-dark {
  border: 1px solid transparent;
  border-color: #34495e !important;
}

body .is-sticky .sticky-border-blue-dark.sticky-border-op-0,
body .is-sticky .border-blue-dark.sticky-border-op-0 {
  border-color: rgba(52, 73, 94, 0) !important;
}

body .is-sticky .sticky-border-blue-dark.sticky-border-op-1,
body .is-sticky .border-blue-dark.sticky-border-op-1 {
  border-color: rgba(52, 73, 94, 0.1) !important;
}

body .is-sticky .sticky-border-blue-dark.sticky-border-op-2,
body .is-sticky .border-blue-dark.sticky-border-op-2 {
  border-color: rgba(52, 73, 94, 0.2) !important;
}

body .is-sticky .sticky-border-blue-dark.sticky-border-op-3,
body .is-sticky .border-blue-dark.sticky-border-op-3 {
  border-color: rgba(52, 73, 94, 0.3) !important;
}

body .is-sticky .sticky-border-blue-dark.sticky-border-op-4,
body .is-sticky .border-blue-dark.sticky-border-op-4 {
  border-color: rgba(52, 73, 94, 0.4) !important;
}

body .is-sticky .sticky-border-blue-dark.sticky-border-op-5,
body .is-sticky .border-blue-dark.sticky-border-op-5 {
  border-color: rgba(52, 73, 94, 0.5) !important;
}

body .is-sticky .sticky-border-blue-dark.sticky-border-op-6,
body .is-sticky .border-blue-dark.sticky-border-op-6 {
  border-color: rgba(52, 73, 94, 0.6) !important;
}

body .is-sticky .sticky-border-blue-dark.sticky-border-op-7,
body .is-sticky .border-blue-dark.sticky-border-op-7 {
  border-color: rgba(52, 73, 94, 0.7) !important;
}

body .is-sticky .sticky-border-blue-dark.sticky-border-op-8,
body .is-sticky .border-blue-dark.sticky-border-op-8 {
  border-color: rgba(52, 73, 94, 0.8) !important;
}

body .is-sticky .sticky-border-blue-dark.sticky-border-op-9,
body .is-sticky .border-blue-dark.sticky-border-op-9 {
  border-color: rgba(52, 73, 94, 0.9) !important;
}

body .is-sticky .sticky-border-blue-dark.sticky-border-op-10,
body .is-sticky .border-blue-dark.sticky-border-op-10 {
  border-color: #34495e !important;
}

body .is-sticky .sticky-bg-red-dark {
  background-color: #a10f2b !important;
}

body .is-sticky .sticky-bg-red-dark.sticky-bg-op-0,
body .is-sticky .bg-red-dark.sticky-bg-op-0 {
  background-color: rgba(161, 15, 43, 0) !important;
}

body .is-sticky .sticky-bg-red-dark.sticky-bg-op-1,
body .is-sticky .bg-red-dark.sticky-bg-op-1 {
  background-color: rgba(161, 15, 43, 0.1) !important;
}

body .is-sticky .sticky-bg-red-dark.sticky-bg-op-2,
body .is-sticky .bg-red-dark.sticky-bg-op-2 {
  background-color: rgba(161, 15, 43, 0.2) !important;
}

body .is-sticky .sticky-bg-red-dark.sticky-bg-op-3,
body .is-sticky .bg-red-dark.sticky-bg-op-3 {
  background-color: rgba(161, 15, 43, 0.3) !important;
}

body .is-sticky .sticky-bg-red-dark.sticky-bg-op-4,
body .is-sticky .bg-red-dark.sticky-bg-op-4 {
  background-color: rgba(161, 15, 43, 0.4) !important;
}

body .is-sticky .sticky-bg-red-dark.sticky-bg-op-5,
body .is-sticky .bg-red-dark.sticky-bg-op-5 {
  background-color: rgba(161, 15, 43, 0.5) !important;
}

body .is-sticky .sticky-bg-red-dark.sticky-bg-op-6,
body .is-sticky .bg-red-dark.sticky-bg-op-6 {
  background-color: rgba(161, 15, 43, 0.6) !important;
}

body .is-sticky .sticky-bg-red-dark.sticky-bg-op-7,
body .is-sticky .bg-red-dark.sticky-bg-op-7 {
  background-color: rgba(161, 15, 43, 0.7) !important;
}

body .is-sticky .sticky-bg-red-dark.sticky-bg-op-8,
body .is-sticky .bg-red-dark.sticky-bg-op-8 {
  background-color: rgba(161, 15, 43, 0.8) !important;
}

body .is-sticky .sticky-bg-red-dark.sticky-bg-op-9,
body .is-sticky .bg-red-dark.sticky-bg-op-9 {
  background-color: rgba(161, 15, 43, 0.9) !important;
}

body .is-sticky .sticky-bg-red-dark.sticky-bg-op-10,
body .is-sticky .bg-red-dark.sticky-bg-op-10 {
  background-color: #a10f2b !important;
}

body .is-sticky .sticky-text-red-dark {
  color: #a10f2b !important;
}

body .is-sticky .sticky-border-red-dark {
  border: 1px solid transparent;
  border-color: #a10f2b !important;
}

body .is-sticky .sticky-border-red-dark.sticky-border-op-0,
body .is-sticky .border-red-dark.sticky-border-op-0 {
  border-color: rgba(161, 15, 43, 0) !important;
}

body .is-sticky .sticky-border-red-dark.sticky-border-op-1,
body .is-sticky .border-red-dark.sticky-border-op-1 {
  border-color: rgba(161, 15, 43, 0.1) !important;
}

body .is-sticky .sticky-border-red-dark.sticky-border-op-2,
body .is-sticky .border-red-dark.sticky-border-op-2 {
  border-color: rgba(161, 15, 43, 0.2) !important;
}

body .is-sticky .sticky-border-red-dark.sticky-border-op-3,
body .is-sticky .border-red-dark.sticky-border-op-3 {
  border-color: rgba(161, 15, 43, 0.3) !important;
}

body .is-sticky .sticky-border-red-dark.sticky-border-op-4,
body .is-sticky .border-red-dark.sticky-border-op-4 {
  border-color: rgba(161, 15, 43, 0.4) !important;
}

body .is-sticky .sticky-border-red-dark.sticky-border-op-5,
body .is-sticky .border-red-dark.sticky-border-op-5 {
  border-color: rgba(161, 15, 43, 0.5) !important;
}

body .is-sticky .sticky-border-red-dark.sticky-border-op-6,
body .is-sticky .border-red-dark.sticky-border-op-6 {
  border-color: rgba(161, 15, 43, 0.6) !important;
}

body .is-sticky .sticky-border-red-dark.sticky-border-op-7,
body .is-sticky .border-red-dark.sticky-border-op-7 {
  border-color: rgba(161, 15, 43, 0.7) !important;
}

body .is-sticky .sticky-border-red-dark.sticky-border-op-8,
body .is-sticky .border-red-dark.sticky-border-op-8 {
  border-color: rgba(161, 15, 43, 0.8) !important;
}

body .is-sticky .sticky-border-red-dark.sticky-border-op-9,
body .is-sticky .border-red-dark.sticky-border-op-9 {
  border-color: rgba(161, 15, 43, 0.9) !important;
}

body .is-sticky .sticky-border-red-dark.sticky-border-op-10,
body .is-sticky .border-red-dark.sticky-border-op-10 {
  border-color: #a10f2b !important;
}

body .is-sticky .sticky-bg-brown {
  background-color: #91633c !important;
}

body .is-sticky .sticky-bg-brown.sticky-bg-op-0,
body .is-sticky .bg-brown.sticky-bg-op-0 {
  background-color: rgba(145, 99, 60, 0) !important;
}

body .is-sticky .sticky-bg-brown.sticky-bg-op-1,
body .is-sticky .bg-brown.sticky-bg-op-1 {
  background-color: rgba(145, 99, 60, 0.1) !important;
}

body .is-sticky .sticky-bg-brown.sticky-bg-op-2,
body .is-sticky .bg-brown.sticky-bg-op-2 {
  background-color: rgba(145, 99, 60, 0.2) !important;
}

body .is-sticky .sticky-bg-brown.sticky-bg-op-3,
body .is-sticky .bg-brown.sticky-bg-op-3 {
  background-color: rgba(145, 99, 60, 0.3) !important;
}

body .is-sticky .sticky-bg-brown.sticky-bg-op-4,
body .is-sticky .bg-brown.sticky-bg-op-4 {
  background-color: rgba(145, 99, 60, 0.4) !important;
}

body .is-sticky .sticky-bg-brown.sticky-bg-op-5,
body .is-sticky .bg-brown.sticky-bg-op-5 {
  background-color: rgba(145, 99, 60, 0.5) !important;
}

body .is-sticky .sticky-bg-brown.sticky-bg-op-6,
body .is-sticky .bg-brown.sticky-bg-op-6 {
  background-color: rgba(145, 99, 60, 0.6) !important;
}

body .is-sticky .sticky-bg-brown.sticky-bg-op-7,
body .is-sticky .bg-brown.sticky-bg-op-7 {
  background-color: rgba(145, 99, 60, 0.7) !important;
}

body .is-sticky .sticky-bg-brown.sticky-bg-op-8,
body .is-sticky .bg-brown.sticky-bg-op-8 {
  background-color: rgba(145, 99, 60, 0.8) !important;
}

body .is-sticky .sticky-bg-brown.sticky-bg-op-9,
body .is-sticky .bg-brown.sticky-bg-op-9 {
  background-color: rgba(145, 99, 60, 0.9) !important;
}

body .is-sticky .sticky-bg-brown.sticky-bg-op-10,
body .is-sticky .bg-brown.sticky-bg-op-10 {
  background-color: #91633c !important;
}

body .is-sticky .sticky-text-brown {
  color: #91633c !important;
}

body .is-sticky .sticky-border-brown {
  border: 1px solid transparent;
  border-color: #91633c !important;
}

body .is-sticky .sticky-border-brown.sticky-border-op-0,
body .is-sticky .border-brown.sticky-border-op-0 {
  border-color: rgba(145, 99, 60, 0) !important;
}

body .is-sticky .sticky-border-brown.sticky-border-op-1,
body .is-sticky .border-brown.sticky-border-op-1 {
  border-color: rgba(145, 99, 60, 0.1) !important;
}

body .is-sticky .sticky-border-brown.sticky-border-op-2,
body .is-sticky .border-brown.sticky-border-op-2 {
  border-color: rgba(145, 99, 60, 0.2) !important;
}

body .is-sticky .sticky-border-brown.sticky-border-op-3,
body .is-sticky .border-brown.sticky-border-op-3 {
  border-color: rgba(145, 99, 60, 0.3) !important;
}

body .is-sticky .sticky-border-brown.sticky-border-op-4,
body .is-sticky .border-brown.sticky-border-op-4 {
  border-color: rgba(145, 99, 60, 0.4) !important;
}

body .is-sticky .sticky-border-brown.sticky-border-op-5,
body .is-sticky .border-brown.sticky-border-op-5 {
  border-color: rgba(145, 99, 60, 0.5) !important;
}

body .is-sticky .sticky-border-brown.sticky-border-op-6,
body .is-sticky .border-brown.sticky-border-op-6 {
  border-color: rgba(145, 99, 60, 0.6) !important;
}

body .is-sticky .sticky-border-brown.sticky-border-op-7,
body .is-sticky .border-brown.sticky-border-op-7 {
  border-color: rgba(145, 99, 60, 0.7) !important;
}

body .is-sticky .sticky-border-brown.sticky-border-op-8,
body .is-sticky .border-brown.sticky-border-op-8 {
  border-color: rgba(145, 99, 60, 0.8) !important;
}

body .is-sticky .sticky-border-brown.sticky-border-op-9,
body .is-sticky .border-brown.sticky-border-op-9 {
  border-color: rgba(145, 99, 60, 0.9) !important;
}

body .is-sticky .sticky-border-brown.sticky-border-op-10,
body .is-sticky .border-brown.sticky-border-op-10 {
  border-color: #91633c !important;
}

body .is-sticky .sticky-bg-cyan-dark {
  background-color: #008b8b !important;
}

body .is-sticky .sticky-bg-cyan-dark.sticky-bg-op-0,
body .is-sticky .bg-cyan-dark.sticky-bg-op-0 {
  background-color: rgba(0, 139, 139, 0) !important;
}

body .is-sticky .sticky-bg-cyan-dark.sticky-bg-op-1,
body .is-sticky .bg-cyan-dark.sticky-bg-op-1 {
  background-color: rgba(0, 139, 139, 0.1) !important;
}

body .is-sticky .sticky-bg-cyan-dark.sticky-bg-op-2,
body .is-sticky .bg-cyan-dark.sticky-bg-op-2 {
  background-color: rgba(0, 139, 139, 0.2) !important;
}

body .is-sticky .sticky-bg-cyan-dark.sticky-bg-op-3,
body .is-sticky .bg-cyan-dark.sticky-bg-op-3 {
  background-color: rgba(0, 139, 139, 0.3) !important;
}

body .is-sticky .sticky-bg-cyan-dark.sticky-bg-op-4,
body .is-sticky .bg-cyan-dark.sticky-bg-op-4 {
  background-color: rgba(0, 139, 139, 0.4) !important;
}

body .is-sticky .sticky-bg-cyan-dark.sticky-bg-op-5,
body .is-sticky .bg-cyan-dark.sticky-bg-op-5 {
  background-color: rgba(0, 139, 139, 0.5) !important;
}

body .is-sticky .sticky-bg-cyan-dark.sticky-bg-op-6,
body .is-sticky .bg-cyan-dark.sticky-bg-op-6 {
  background-color: rgba(0, 139, 139, 0.6) !important;
}

body .is-sticky .sticky-bg-cyan-dark.sticky-bg-op-7,
body .is-sticky .bg-cyan-dark.sticky-bg-op-7 {
  background-color: rgba(0, 139, 139, 0.7) !important;
}

body .is-sticky .sticky-bg-cyan-dark.sticky-bg-op-8,
body .is-sticky .bg-cyan-dark.sticky-bg-op-8 {
  background-color: rgba(0, 139, 139, 0.8) !important;
}

body .is-sticky .sticky-bg-cyan-dark.sticky-bg-op-9,
body .is-sticky .bg-cyan-dark.sticky-bg-op-9 {
  background-color: rgba(0, 139, 139, 0.9) !important;
}

body .is-sticky .sticky-bg-cyan-dark.sticky-bg-op-10,
body .is-sticky .bg-cyan-dark.sticky-bg-op-10 {
  background-color: darkcyan !important;
}

body .is-sticky .sticky-text-cyan-dark {
  color: #008b8b !important;
}

body .is-sticky .sticky-border-cyan-dark {
  border: 1px solid transparent;
  border-color: #008b8b !important;
}

body .is-sticky .sticky-border-cyan-dark.sticky-border-op-0,
body .is-sticky .border-cyan-dark.sticky-border-op-0 {
  border-color: rgba(0, 139, 139, 0) !important;
}

body .is-sticky .sticky-border-cyan-dark.sticky-border-op-1,
body .is-sticky .border-cyan-dark.sticky-border-op-1 {
  border-color: rgba(0, 139, 139, 0.1) !important;
}

body .is-sticky .sticky-border-cyan-dark.sticky-border-op-2,
body .is-sticky .border-cyan-dark.sticky-border-op-2 {
  border-color: rgba(0, 139, 139, 0.2) !important;
}

body .is-sticky .sticky-border-cyan-dark.sticky-border-op-3,
body .is-sticky .border-cyan-dark.sticky-border-op-3 {
  border-color: rgba(0, 139, 139, 0.3) !important;
}

body .is-sticky .sticky-border-cyan-dark.sticky-border-op-4,
body .is-sticky .border-cyan-dark.sticky-border-op-4 {
  border-color: rgba(0, 139, 139, 0.4) !important;
}

body .is-sticky .sticky-border-cyan-dark.sticky-border-op-5,
body .is-sticky .border-cyan-dark.sticky-border-op-5 {
  border-color: rgba(0, 139, 139, 0.5) !important;
}

body .is-sticky .sticky-border-cyan-dark.sticky-border-op-6,
body .is-sticky .border-cyan-dark.sticky-border-op-6 {
  border-color: rgba(0, 139, 139, 0.6) !important;
}

body .is-sticky .sticky-border-cyan-dark.sticky-border-op-7,
body .is-sticky .border-cyan-dark.sticky-border-op-7 {
  border-color: rgba(0, 139, 139, 0.7) !important;
}

body .is-sticky .sticky-border-cyan-dark.sticky-border-op-8,
body .is-sticky .border-cyan-dark.sticky-border-op-8 {
  border-color: rgba(0, 139, 139, 0.8) !important;
}

body .is-sticky .sticky-border-cyan-dark.sticky-border-op-9,
body .is-sticky .border-cyan-dark.sticky-border-op-9 {
  border-color: rgba(0, 139, 139, 0.9) !important;
}

body .is-sticky .sticky-border-cyan-dark.sticky-border-op-10,
body .is-sticky .border-cyan-dark.sticky-border-op-10 {
  border-color: darkcyan !important;
}

body .is-sticky .sticky-bg-yellow {
  background-color: #D4AC0D !important;
}

body .is-sticky .sticky-bg-yellow.sticky-bg-op-0,
body .is-sticky .bg-yellow.sticky-bg-op-0 {
  background-color: rgba(212, 172, 13, 0) !important;
}

body .is-sticky .sticky-bg-yellow.sticky-bg-op-1,
body .is-sticky .bg-yellow.sticky-bg-op-1 {
  background-color: rgba(212, 172, 13, 0.1) !important;
}

body .is-sticky .sticky-bg-yellow.sticky-bg-op-2,
body .is-sticky .bg-yellow.sticky-bg-op-2 {
  background-color: rgba(212, 172, 13, 0.2) !important;
}

body .is-sticky .sticky-bg-yellow.sticky-bg-op-3,
body .is-sticky .bg-yellow.sticky-bg-op-3 {
  background-color: rgba(212, 172, 13, 0.3) !important;
}

body .is-sticky .sticky-bg-yellow.sticky-bg-op-4,
body .is-sticky .bg-yellow.sticky-bg-op-4 {
  background-color: rgba(212, 172, 13, 0.4) !important;
}

body .is-sticky .sticky-bg-yellow.sticky-bg-op-5,
body .is-sticky .bg-yellow.sticky-bg-op-5 {
  background-color: rgba(212, 172, 13, 0.5) !important;
}

body .is-sticky .sticky-bg-yellow.sticky-bg-op-6,
body .is-sticky .bg-yellow.sticky-bg-op-6 {
  background-color: rgba(212, 172, 13, 0.6) !important;
}

body .is-sticky .sticky-bg-yellow.sticky-bg-op-7,
body .is-sticky .bg-yellow.sticky-bg-op-7 {
  background-color: rgba(212, 172, 13, 0.7) !important;
}

body .is-sticky .sticky-bg-yellow.sticky-bg-op-8,
body .is-sticky .bg-yellow.sticky-bg-op-8 {
  background-color: rgba(212, 172, 13, 0.8) !important;
}

body .is-sticky .sticky-bg-yellow.sticky-bg-op-9,
body .is-sticky .bg-yellow.sticky-bg-op-9 {
  background-color: rgba(212, 172, 13, 0.9) !important;
}

body .is-sticky .sticky-bg-yellow.sticky-bg-op-10,
body .is-sticky .bg-yellow.sticky-bg-op-10 {
  background-color: #d4ac0d !important;
}

body .is-sticky .sticky-text-yellow {
  color: #D4AC0D !important;
}

body .is-sticky .sticky-border-yellow {
  border: 1px solid transparent;
  border-color: #D4AC0D !important;
}

body .is-sticky .sticky-border-yellow.sticky-border-op-0,
body .is-sticky .border-yellow.sticky-border-op-0 {
  border-color: rgba(212, 172, 13, 0) !important;
}

body .is-sticky .sticky-border-yellow.sticky-border-op-1,
body .is-sticky .border-yellow.sticky-border-op-1 {
  border-color: rgba(212, 172, 13, 0.1) !important;
}

body .is-sticky .sticky-border-yellow.sticky-border-op-2,
body .is-sticky .border-yellow.sticky-border-op-2 {
  border-color: rgba(212, 172, 13, 0.2) !important;
}

body .is-sticky .sticky-border-yellow.sticky-border-op-3,
body .is-sticky .border-yellow.sticky-border-op-3 {
  border-color: rgba(212, 172, 13, 0.3) !important;
}

body .is-sticky .sticky-border-yellow.sticky-border-op-4,
body .is-sticky .border-yellow.sticky-border-op-4 {
  border-color: rgba(212, 172, 13, 0.4) !important;
}

body .is-sticky .sticky-border-yellow.sticky-border-op-5,
body .is-sticky .border-yellow.sticky-border-op-5 {
  border-color: rgba(212, 172, 13, 0.5) !important;
}

body .is-sticky .sticky-border-yellow.sticky-border-op-6,
body .is-sticky .border-yellow.sticky-border-op-6 {
  border-color: rgba(212, 172, 13, 0.6) !important;
}

body .is-sticky .sticky-border-yellow.sticky-border-op-7,
body .is-sticky .border-yellow.sticky-border-op-7 {
  border-color: rgba(212, 172, 13, 0.7) !important;
}

body .is-sticky .sticky-border-yellow.sticky-border-op-8,
body .is-sticky .border-yellow.sticky-border-op-8 {
  border-color: rgba(212, 172, 13, 0.8) !important;
}

body .is-sticky .sticky-border-yellow.sticky-border-op-9,
body .is-sticky .border-yellow.sticky-border-op-9 {
  border-color: rgba(212, 172, 13, 0.9) !important;
}

body .is-sticky .sticky-border-yellow.sticky-border-op-10,
body .is-sticky .border-yellow.sticky-border-op-10 {
  border-color: #d4ac0d !important;
}

body .is-sticky .sticky-bg-slate {
  background-color: #5D6D7E !important;
}

body .is-sticky .sticky-bg-slate.sticky-bg-op-0,
body .is-sticky .bg-slate.sticky-bg-op-0 {
  background-color: rgba(93, 109, 126, 0) !important;
}

body .is-sticky .sticky-bg-slate.sticky-bg-op-1,
body .is-sticky .bg-slate.sticky-bg-op-1 {
  background-color: rgba(93, 109, 126, 0.1) !important;
}

body .is-sticky .sticky-bg-slate.sticky-bg-op-2,
body .is-sticky .bg-slate.sticky-bg-op-2 {
  background-color: rgba(93, 109, 126, 0.2) !important;
}

body .is-sticky .sticky-bg-slate.sticky-bg-op-3,
body .is-sticky .bg-slate.sticky-bg-op-3 {
  background-color: rgba(93, 109, 126, 0.3) !important;
}

body .is-sticky .sticky-bg-slate.sticky-bg-op-4,
body .is-sticky .bg-slate.sticky-bg-op-4 {
  background-color: rgba(93, 109, 126, 0.4) !important;
}

body .is-sticky .sticky-bg-slate.sticky-bg-op-5,
body .is-sticky .bg-slate.sticky-bg-op-5 {
  background-color: rgba(93, 109, 126, 0.5) !important;
}

body .is-sticky .sticky-bg-slate.sticky-bg-op-6,
body .is-sticky .bg-slate.sticky-bg-op-6 {
  background-color: rgba(93, 109, 126, 0.6) !important;
}

body .is-sticky .sticky-bg-slate.sticky-bg-op-7,
body .is-sticky .bg-slate.sticky-bg-op-7 {
  background-color: rgba(93, 109, 126, 0.7) !important;
}

body .is-sticky .sticky-bg-slate.sticky-bg-op-8,
body .is-sticky .bg-slate.sticky-bg-op-8 {
  background-color: rgba(93, 109, 126, 0.8) !important;
}

body .is-sticky .sticky-bg-slate.sticky-bg-op-9,
body .is-sticky .bg-slate.sticky-bg-op-9 {
  background-color: rgba(93, 109, 126, 0.9) !important;
}

body .is-sticky .sticky-bg-slate.sticky-bg-op-10,
body .is-sticky .bg-slate.sticky-bg-op-10 {
  background-color: #5d6d7e !important;
}

body .is-sticky .sticky-text-slate {
  color: #5D6D7E !important;
}

body .is-sticky .sticky-border-slate {
  border: 1px solid transparent;
  border-color: #5D6D7E !important;
}

body .is-sticky .sticky-border-slate.sticky-border-op-0,
body .is-sticky .border-slate.sticky-border-op-0 {
  border-color: rgba(93, 109, 126, 0) !important;
}

body .is-sticky .sticky-border-slate.sticky-border-op-1,
body .is-sticky .border-slate.sticky-border-op-1 {
  border-color: rgba(93, 109, 126, 0.1) !important;
}

body .is-sticky .sticky-border-slate.sticky-border-op-2,
body .is-sticky .border-slate.sticky-border-op-2 {
  border-color: rgba(93, 109, 126, 0.2) !important;
}

body .is-sticky .sticky-border-slate.sticky-border-op-3,
body .is-sticky .border-slate.sticky-border-op-3 {
  border-color: rgba(93, 109, 126, 0.3) !important;
}

body .is-sticky .sticky-border-slate.sticky-border-op-4,
body .is-sticky .border-slate.sticky-border-op-4 {
  border-color: rgba(93, 109, 126, 0.4) !important;
}

body .is-sticky .sticky-border-slate.sticky-border-op-5,
body .is-sticky .border-slate.sticky-border-op-5 {
  border-color: rgba(93, 109, 126, 0.5) !important;
}

body .is-sticky .sticky-border-slate.sticky-border-op-6,
body .is-sticky .border-slate.sticky-border-op-6 {
  border-color: rgba(93, 109, 126, 0.6) !important;
}

body .is-sticky .sticky-border-slate.sticky-border-op-7,
body .is-sticky .border-slate.sticky-border-op-7 {
  border-color: rgba(93, 109, 126, 0.7) !important;
}

body .is-sticky .sticky-border-slate.sticky-border-op-8,
body .is-sticky .border-slate.sticky-border-op-8 {
  border-color: rgba(93, 109, 126, 0.8) !important;
}

body .is-sticky .sticky-border-slate.sticky-border-op-9,
body .is-sticky .border-slate.sticky-border-op-9 {
  border-color: rgba(93, 109, 126, 0.9) !important;
}

body .is-sticky .sticky-border-slate.sticky-border-op-10,
body .is-sticky .border-slate.sticky-border-op-10 {
  border-color: #5d6d7e !important;
}

body .is-sticky .sticky-bg-olive {
  background-color: #808000 !important;
}

body .is-sticky .sticky-bg-olive.sticky-bg-op-0,
body .is-sticky .bg-olive.sticky-bg-op-0 {
  background-color: rgba(128, 128, 0, 0) !important;
}

body .is-sticky .sticky-bg-olive.sticky-bg-op-1,
body .is-sticky .bg-olive.sticky-bg-op-1 {
  background-color: rgba(128, 128, 0, 0.1) !important;
}

body .is-sticky .sticky-bg-olive.sticky-bg-op-2,
body .is-sticky .bg-olive.sticky-bg-op-2 {
  background-color: rgba(128, 128, 0, 0.2) !important;
}

body .is-sticky .sticky-bg-olive.sticky-bg-op-3,
body .is-sticky .bg-olive.sticky-bg-op-3 {
  background-color: rgba(128, 128, 0, 0.3) !important;
}

body .is-sticky .sticky-bg-olive.sticky-bg-op-4,
body .is-sticky .bg-olive.sticky-bg-op-4 {
  background-color: rgba(128, 128, 0, 0.4) !important;
}

body .is-sticky .sticky-bg-olive.sticky-bg-op-5,
body .is-sticky .bg-olive.sticky-bg-op-5 {
  background-color: rgba(128, 128, 0, 0.5) !important;
}

body .is-sticky .sticky-bg-olive.sticky-bg-op-6,
body .is-sticky .bg-olive.sticky-bg-op-6 {
  background-color: rgba(128, 128, 0, 0.6) !important;
}

body .is-sticky .sticky-bg-olive.sticky-bg-op-7,
body .is-sticky .bg-olive.sticky-bg-op-7 {
  background-color: rgba(128, 128, 0, 0.7) !important;
}

body .is-sticky .sticky-bg-olive.sticky-bg-op-8,
body .is-sticky .bg-olive.sticky-bg-op-8 {
  background-color: rgba(128, 128, 0, 0.8) !important;
}

body .is-sticky .sticky-bg-olive.sticky-bg-op-9,
body .is-sticky .bg-olive.sticky-bg-op-9 {
  background-color: rgba(128, 128, 0, 0.9) !important;
}

body .is-sticky .sticky-bg-olive.sticky-bg-op-10,
body .is-sticky .bg-olive.sticky-bg-op-10 {
  background-color: olive !important;
}

body .is-sticky .sticky-text-olive {
  color: #808000 !important;
}

body .is-sticky .sticky-border-olive {
  border: 1px solid transparent;
  border-color: #808000 !important;
}

body .is-sticky .sticky-border-olive.sticky-border-op-0,
body .is-sticky .border-olive.sticky-border-op-0 {
  border-color: rgba(128, 128, 0, 0) !important;
}

body .is-sticky .sticky-border-olive.sticky-border-op-1,
body .is-sticky .border-olive.sticky-border-op-1 {
  border-color: rgba(128, 128, 0, 0.1) !important;
}

body .is-sticky .sticky-border-olive.sticky-border-op-2,
body .is-sticky .border-olive.sticky-border-op-2 {
  border-color: rgba(128, 128, 0, 0.2) !important;
}

body .is-sticky .sticky-border-olive.sticky-border-op-3,
body .is-sticky .border-olive.sticky-border-op-3 {
  border-color: rgba(128, 128, 0, 0.3) !important;
}

body .is-sticky .sticky-border-olive.sticky-border-op-4,
body .is-sticky .border-olive.sticky-border-op-4 {
  border-color: rgba(128, 128, 0, 0.4) !important;
}

body .is-sticky .sticky-border-olive.sticky-border-op-5,
body .is-sticky .border-olive.sticky-border-op-5 {
  border-color: rgba(128, 128, 0, 0.5) !important;
}

body .is-sticky .sticky-border-olive.sticky-border-op-6,
body .is-sticky .border-olive.sticky-border-op-6 {
  border-color: rgba(128, 128, 0, 0.6) !important;
}

body .is-sticky .sticky-border-olive.sticky-border-op-7,
body .is-sticky .border-olive.sticky-border-op-7 {
  border-color: rgba(128, 128, 0, 0.7) !important;
}

body .is-sticky .sticky-border-olive.sticky-border-op-8,
body .is-sticky .border-olive.sticky-border-op-8 {
  border-color: rgba(128, 128, 0, 0.8) !important;
}

body .is-sticky .sticky-border-olive.sticky-border-op-9,
body .is-sticky .border-olive.sticky-border-op-9 {
  border-color: rgba(128, 128, 0, 0.9) !important;
}

body .is-sticky .sticky-border-olive.sticky-border-op-10,
body .is-sticky .border-olive.sticky-border-op-10 {
  border-color: olive !important;
}

body .is-sticky .sticky-bg-teal {
  background-color: #008080 !important;
}

body .is-sticky .sticky-bg-teal.sticky-bg-op-0,
body .is-sticky .bg-teal.sticky-bg-op-0 {
  background-color: rgba(0, 128, 128, 0) !important;
}

body .is-sticky .sticky-bg-teal.sticky-bg-op-1,
body .is-sticky .bg-teal.sticky-bg-op-1 {
  background-color: rgba(0, 128, 128, 0.1) !important;
}

body .is-sticky .sticky-bg-teal.sticky-bg-op-2,
body .is-sticky .bg-teal.sticky-bg-op-2 {
  background-color: rgba(0, 128, 128, 0.2) !important;
}

body .is-sticky .sticky-bg-teal.sticky-bg-op-3,
body .is-sticky .bg-teal.sticky-bg-op-3 {
  background-color: rgba(0, 128, 128, 0.3) !important;
}

body .is-sticky .sticky-bg-teal.sticky-bg-op-4,
body .is-sticky .bg-teal.sticky-bg-op-4 {
  background-color: rgba(0, 128, 128, 0.4) !important;
}

body .is-sticky .sticky-bg-teal.sticky-bg-op-5,
body .is-sticky .bg-teal.sticky-bg-op-5 {
  background-color: rgba(0, 128, 128, 0.5) !important;
}

body .is-sticky .sticky-bg-teal.sticky-bg-op-6,
body .is-sticky .bg-teal.sticky-bg-op-6 {
  background-color: rgba(0, 128, 128, 0.6) !important;
}

body .is-sticky .sticky-bg-teal.sticky-bg-op-7,
body .is-sticky .bg-teal.sticky-bg-op-7 {
  background-color: rgba(0, 128, 128, 0.7) !important;
}

body .is-sticky .sticky-bg-teal.sticky-bg-op-8,
body .is-sticky .bg-teal.sticky-bg-op-8 {
  background-color: rgba(0, 128, 128, 0.8) !important;
}

body .is-sticky .sticky-bg-teal.sticky-bg-op-9,
body .is-sticky .bg-teal.sticky-bg-op-9 {
  background-color: rgba(0, 128, 128, 0.9) !important;
}

body .is-sticky .sticky-bg-teal.sticky-bg-op-10,
body .is-sticky .bg-teal.sticky-bg-op-10 {
  background-color: teal !important;
}

body .is-sticky .sticky-text-teal {
  color: #008080 !important;
}

body .is-sticky .sticky-border-teal {
  border: 1px solid transparent;
  border-color: #008080 !important;
}

body .is-sticky .sticky-border-teal.sticky-border-op-0,
body .is-sticky .border-teal.sticky-border-op-0 {
  border-color: rgba(0, 128, 128, 0) !important;
}

body .is-sticky .sticky-border-teal.sticky-border-op-1,
body .is-sticky .border-teal.sticky-border-op-1 {
  border-color: rgba(0, 128, 128, 0.1) !important;
}

body .is-sticky .sticky-border-teal.sticky-border-op-2,
body .is-sticky .border-teal.sticky-border-op-2 {
  border-color: rgba(0, 128, 128, 0.2) !important;
}

body .is-sticky .sticky-border-teal.sticky-border-op-3,
body .is-sticky .border-teal.sticky-border-op-3 {
  border-color: rgba(0, 128, 128, 0.3) !important;
}

body .is-sticky .sticky-border-teal.sticky-border-op-4,
body .is-sticky .border-teal.sticky-border-op-4 {
  border-color: rgba(0, 128, 128, 0.4) !important;
}

body .is-sticky .sticky-border-teal.sticky-border-op-5,
body .is-sticky .border-teal.sticky-border-op-5 {
  border-color: rgba(0, 128, 128, 0.5) !important;
}

body .is-sticky .sticky-border-teal.sticky-border-op-6,
body .is-sticky .border-teal.sticky-border-op-6 {
  border-color: rgba(0, 128, 128, 0.6) !important;
}

body .is-sticky .sticky-border-teal.sticky-border-op-7,
body .is-sticky .border-teal.sticky-border-op-7 {
  border-color: rgba(0, 128, 128, 0.7) !important;
}

body .is-sticky .sticky-border-teal.sticky-border-op-8,
body .is-sticky .border-teal.sticky-border-op-8 {
  border-color: rgba(0, 128, 128, 0.8) !important;
}

body .is-sticky .sticky-border-teal.sticky-border-op-9,
body .is-sticky .border-teal.sticky-border-op-9 {
  border-color: rgba(0, 128, 128, 0.9) !important;
}

body .is-sticky .sticky-border-teal.sticky-border-op-10,
body .is-sticky .border-teal.sticky-border-op-10 {
  border-color: teal !important;
}

body .is-sticky .sticky-bg-green-bright {
  background-color: #2ECC71 !important;
}

body .is-sticky .sticky-bg-green-bright.sticky-bg-op-0,
body .is-sticky .bg-green-bright.sticky-bg-op-0 {
  background-color: rgba(46, 204, 113, 0) !important;
}

body .is-sticky .sticky-bg-green-bright.sticky-bg-op-1,
body .is-sticky .bg-green-bright.sticky-bg-op-1 {
  background-color: rgba(46, 204, 113, 0.1) !important;
}

body .is-sticky .sticky-bg-green-bright.sticky-bg-op-2,
body .is-sticky .bg-green-bright.sticky-bg-op-2 {
  background-color: rgba(46, 204, 113, 0.2) !important;
}

body .is-sticky .sticky-bg-green-bright.sticky-bg-op-3,
body .is-sticky .bg-green-bright.sticky-bg-op-3 {
  background-color: rgba(46, 204, 113, 0.3) !important;
}

body .is-sticky .sticky-bg-green-bright.sticky-bg-op-4,
body .is-sticky .bg-green-bright.sticky-bg-op-4 {
  background-color: rgba(46, 204, 113, 0.4) !important;
}

body .is-sticky .sticky-bg-green-bright.sticky-bg-op-5,
body .is-sticky .bg-green-bright.sticky-bg-op-5 {
  background-color: rgba(46, 204, 113, 0.5) !important;
}

body .is-sticky .sticky-bg-green-bright.sticky-bg-op-6,
body .is-sticky .bg-green-bright.sticky-bg-op-6 {
  background-color: rgba(46, 204, 113, 0.6) !important;
}

body .is-sticky .sticky-bg-green-bright.sticky-bg-op-7,
body .is-sticky .bg-green-bright.sticky-bg-op-7 {
  background-color: rgba(46, 204, 113, 0.7) !important;
}

body .is-sticky .sticky-bg-green-bright.sticky-bg-op-8,
body .is-sticky .bg-green-bright.sticky-bg-op-8 {
  background-color: rgba(46, 204, 113, 0.8) !important;
}

body .is-sticky .sticky-bg-green-bright.sticky-bg-op-9,
body .is-sticky .bg-green-bright.sticky-bg-op-9 {
  background-color: rgba(46, 204, 113, 0.9) !important;
}

body .is-sticky .sticky-bg-green-bright.sticky-bg-op-10,
body .is-sticky .bg-green-bright.sticky-bg-op-10 {
  background-color: #2ecc71 !important;
}

body .is-sticky .sticky-text-green-bright {
  color: #2ECC71 !important;
}

body .is-sticky .sticky-border-green-bright {
  border: 1px solid transparent;
  border-color: #2ECC71 !important;
}

body .is-sticky .sticky-border-green-bright.sticky-border-op-0,
body .is-sticky .border-green-bright.sticky-border-op-0 {
  border-color: rgba(46, 204, 113, 0) !important;
}

body .is-sticky .sticky-border-green-bright.sticky-border-op-1,
body .is-sticky .border-green-bright.sticky-border-op-1 {
  border-color: rgba(46, 204, 113, 0.1) !important;
}

body .is-sticky .sticky-border-green-bright.sticky-border-op-2,
body .is-sticky .border-green-bright.sticky-border-op-2 {
  border-color: rgba(46, 204, 113, 0.2) !important;
}

body .is-sticky .sticky-border-green-bright.sticky-border-op-3,
body .is-sticky .border-green-bright.sticky-border-op-3 {
  border-color: rgba(46, 204, 113, 0.3) !important;
}

body .is-sticky .sticky-border-green-bright.sticky-border-op-4,
body .is-sticky .border-green-bright.sticky-border-op-4 {
  border-color: rgba(46, 204, 113, 0.4) !important;
}

body .is-sticky .sticky-border-green-bright.sticky-border-op-5,
body .is-sticky .border-green-bright.sticky-border-op-5 {
  border-color: rgba(46, 204, 113, 0.5) !important;
}

body .is-sticky .sticky-border-green-bright.sticky-border-op-6,
body .is-sticky .border-green-bright.sticky-border-op-6 {
  border-color: rgba(46, 204, 113, 0.6) !important;
}

body .is-sticky .sticky-border-green-bright.sticky-border-op-7,
body .is-sticky .border-green-bright.sticky-border-op-7 {
  border-color: rgba(46, 204, 113, 0.7) !important;
}

body .is-sticky .sticky-border-green-bright.sticky-border-op-8,
body .is-sticky .border-green-bright.sticky-border-op-8 {
  border-color: rgba(46, 204, 113, 0.8) !important;
}

body .is-sticky .sticky-border-green-bright.sticky-border-op-9,
body .is-sticky .border-green-bright.sticky-border-op-9 {
  border-color: rgba(46, 204, 113, 0.9) !important;
}

body .is-sticky .sticky-border-green-bright.sticky-border-op-10,
body .is-sticky .border-green-bright.sticky-border-op-10 {
  border-color: #2ecc71 !important;
}

body .is-sticky .sticky-op-0 {
  opacity: 0 !important;
}

body .is-sticky a.sticky-op-0:hover {
  opacity: 1 !important;
}

body .is-sticky .sticky-op-1 {
  opacity: 0.1 !important;
}

body .is-sticky a.sticky-op-1:hover {
  opacity: 1 !important;
}

body .is-sticky .sticky-op-2 {
  opacity: 0.2 !important;
}

body .is-sticky a.sticky-op-2:hover {
  opacity: 1 !important;
}

body .is-sticky .sticky-op-3 {
  opacity: 0.3 !important;
}

body .is-sticky a.sticky-op-3:hover {
  opacity: 1 !important;
}

body .is-sticky .sticky-op-4 {
  opacity: 0.4 !important;
}

body .is-sticky a.sticky-op-4:hover {
  opacity: 1 !important;
}

body .is-sticky .sticky-op-5 {
  opacity: 0.5 !important;
}

body .is-sticky a.sticky-op-5:hover {
  opacity: 1 !important;
}

body .is-sticky .sticky-op-6 {
  opacity: 0.6 !important;
}

body .is-sticky a.sticky-op-6:hover {
  opacity: 1 !important;
}

body .is-sticky .sticky-op-7 {
  opacity: 0.7 !important;
}

body .is-sticky a.sticky-op-7:hover {
  opacity: 1 !important;
}

body .is-sticky .sticky-op-8 {
  opacity: 0.8 !important;
}

body .is-sticky a.sticky-op-8:hover {
  opacity: 1 !important;
}

body .is-sticky .sticky-op-9 {
  opacity: 0.9 !important;
}

body .is-sticky a.sticky-op-9:hover {
  opacity: 1 !important;
}

body .is-sticky .sticky-op-10 {
  opacity: 1 !important;
}

body .is-sticky a.sticky-op-10:hover {
  opacity: 1 !important;
}

body .is-sticky .sticky-m-0 {
  margin: 0 !important;
}

body .is-sticky .sticky-mt-0 {
  margin-top: 0 !important;
}

body .is-sticky .sticky-mr-0 {
  margin-right: 0 !important;
}

body .is-sticky .sticky-mb-0 {
  margin-bottom: 0 !important;
}

body .is-sticky .sticky-ml-0 {
  margin-left: 0 !important;
}

body .is-sticky .sticky-mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

body .is-sticky .sticky-my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

body .is-sticky .sticky-m-1 {
  margin: 0.25rem !important;
}

body .is-sticky .sticky-mt-1 {
  margin-top: 0.25rem !important;
}

body .is-sticky .sticky-mr-1 {
  margin-right: 0.25rem !important;
}

body .is-sticky .sticky-mb-1 {
  margin-bottom: 0.25rem !important;
}

body .is-sticky .sticky-ml-1 {
  margin-left: 0.25rem !important;
}

body .is-sticky .sticky-mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

body .is-sticky .sticky-my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

body .is-sticky .sticky-m-1-neg {
  margin: -0.25rem !important;
}

body .is-sticky .sticky-mt-1-neg {
  margin-top: -0.25rem !important;
}

body .is-sticky .sticky-mr-1-neg {
  margin-right: -0.25rem !important;
}

body .is-sticky .sticky-mb-1-neg {
  margin-bottom: -0.25rem !important;
}

body .is-sticky .sticky-ml-1-neg {
  margin-left: -0.25rem !important;
}

body .is-sticky .sticky-mx-1-neg {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}

body .is-sticky .sticky-my-1-neg {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

body .is-sticky .sticky-m-2 {
  margin: 0.5rem !important;
}

body .is-sticky .sticky-mt-2 {
  margin-top: 0.5rem !important;
}

body .is-sticky .sticky-mr-2 {
  margin-right: 0.5rem !important;
}

body .is-sticky .sticky-mb-2 {
  margin-bottom: 0.5rem !important;
}

body .is-sticky .sticky-ml-2 {
  margin-left: 0.5rem !important;
}

body .is-sticky .sticky-mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

body .is-sticky .sticky-my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

body .is-sticky .sticky-m-2-neg {
  margin: -0.5rem !important;
}

body .is-sticky .sticky-mt-2-neg {
  margin-top: -0.5rem !important;
}

body .is-sticky .sticky-mr-2-neg {
  margin-right: -0.5rem !important;
}

body .is-sticky .sticky-mb-2-neg {
  margin-bottom: -0.5rem !important;
}

body .is-sticky .sticky-ml-2-neg {
  margin-left: -0.5rem !important;
}

body .is-sticky .sticky-mx-2-neg {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}

body .is-sticky .sticky-my-2-neg {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

body .is-sticky .sticky-m-3 {
  margin: 1rem !important;
}

body .is-sticky .sticky-mt-3 {
  margin-top: 1rem !important;
}

body .is-sticky .sticky-mr-3 {
  margin-right: 1rem !important;
}

body .is-sticky .sticky-mb-3 {
  margin-bottom: 1rem !important;
}

body .is-sticky .sticky-ml-3 {
  margin-left: 1rem !important;
}

body .is-sticky .sticky-mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

body .is-sticky .sticky-my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

body .is-sticky .sticky-m-3-neg {
  margin: -1rem !important;
}

body .is-sticky .sticky-mt-3-neg {
  margin-top: -1rem !important;
}

body .is-sticky .sticky-mr-3-neg {
  margin-right: -1rem !important;
}

body .is-sticky .sticky-mb-3-neg {
  margin-bottom: -1rem !important;
}

body .is-sticky .sticky-ml-3-neg {
  margin-left: -1rem !important;
}

body .is-sticky .sticky-mx-3-neg {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}

body .is-sticky .sticky-my-3-neg {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

body .is-sticky .sticky-m-4 {
  margin: 1.5rem !important;
}

body .is-sticky .sticky-mt-4 {
  margin-top: 1.5rem !important;
}

body .is-sticky .sticky-mr-4 {
  margin-right: 1.5rem !important;
}

body .is-sticky .sticky-mb-4 {
  margin-bottom: 1.5rem !important;
}

body .is-sticky .sticky-ml-4 {
  margin-left: 1.5rem !important;
}

body .is-sticky .sticky-mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

body .is-sticky .sticky-my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

body .is-sticky .sticky-m-4-neg {
  margin: -1.5rem !important;
}

body .is-sticky .sticky-mt-4-neg {
  margin-top: -1.5rem !important;
}

body .is-sticky .sticky-mr-4-neg {
  margin-right: -1.5rem !important;
}

body .is-sticky .sticky-mb-4-neg {
  margin-bottom: -1.5rem !important;
}

body .is-sticky .sticky-ml-4-neg {
  margin-left: -1.5rem !important;
}

body .is-sticky .sticky-mx-4-neg {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}

body .is-sticky .sticky-my-4-neg {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

body .is-sticky .sticky-m-5 {
  margin: 3rem !important;
}

body .is-sticky .sticky-mt-5 {
  margin-top: 3rem !important;
}

body .is-sticky .sticky-mr-5 {
  margin-right: 3rem !important;
}

body .is-sticky .sticky-mb-5 {
  margin-bottom: 3rem !important;
}

body .is-sticky .sticky-ml-5 {
  margin-left: 3rem !important;
}

body .is-sticky .sticky-mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

body .is-sticky .sticky-my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

body .is-sticky .sticky-m-5-neg {
  margin: -3rem !important;
}

body .is-sticky .sticky-mt-5-neg {
  margin-top: -3rem !important;
}

body .is-sticky .sticky-mr-5-neg {
  margin-right: -3rem !important;
}

body .is-sticky .sticky-mb-5-neg {
  margin-bottom: -3rem !important;
}

body .is-sticky .sticky-ml-5-neg {
  margin-left: -3rem !important;
}

body .is-sticky .sticky-mx-5-neg {
  margin-right: -3rem !important;
  margin-left: -3rem !important;
}

body .is-sticky .sticky-my-5-neg {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

body .is-sticky .sticky-m-6 {
  margin: 4.5rem !important;
}

body .is-sticky .sticky-mt-6 {
  margin-top: 4.5rem !important;
}

body .is-sticky .sticky-mr-6 {
  margin-right: 4.5rem !important;
}

body .is-sticky .sticky-mb-6 {
  margin-bottom: 4.5rem !important;
}

body .is-sticky .sticky-ml-6 {
  margin-left: 4.5rem !important;
}

body .is-sticky .sticky-mx-6 {
  margin-right: 4.5rem !important;
  margin-left: 4.5rem !important;
}

body .is-sticky .sticky-my-6 {
  margin-top: 4.5rem !important;
  margin-bottom: 4.5rem !important;
}

body .is-sticky .sticky-m-6-neg {
  margin: -4.5rem !important;
}

body .is-sticky .sticky-mt-6-neg {
  margin-top: -4.5rem !important;
}

body .is-sticky .sticky-mr-6-neg {
  margin-right: -4.5rem !important;
}

body .is-sticky .sticky-mb-6-neg {
  margin-bottom: -4.5rem !important;
}

body .is-sticky .sticky-ml-6-neg {
  margin-left: -4.5rem !important;
}

body .is-sticky .sticky-mx-6-neg {
  margin-right: -4.5rem !important;
  margin-left: -4.5rem !important;
}

body .is-sticky .sticky-my-6-neg {
  margin-top: -4.5rem !important;
  margin-bottom: -4.5rem !important;
}

body .is-sticky .sticky-m-7 {
  margin: 6rem !important;
}

body .is-sticky .sticky-mt-7 {
  margin-top: 6rem !important;
}

body .is-sticky .sticky-mr-7 {
  margin-right: 6rem !important;
}

body .is-sticky .sticky-mb-7 {
  margin-bottom: 6rem !important;
}

body .is-sticky .sticky-ml-7 {
  margin-left: 6rem !important;
}

body .is-sticky .sticky-mx-7 {
  margin-right: 6rem !important;
  margin-left: 6rem !important;
}

body .is-sticky .sticky-my-7 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important;
}

body .is-sticky .sticky-m-7-neg {
  margin: -6rem !important;
}

body .is-sticky .sticky-mt-7-neg {
  margin-top: -6rem !important;
}

body .is-sticky .sticky-mr-7-neg {
  margin-right: -6rem !important;
}

body .is-sticky .sticky-mb-7-neg {
  margin-bottom: -6rem !important;
}

body .is-sticky .sticky-ml-7-neg {
  margin-left: -6rem !important;
}

body .is-sticky .sticky-mx-7-neg {
  margin-right: -6rem !important;
  margin-left: -6rem !important;
}

body .is-sticky .sticky-my-7-neg {
  margin-top: -6rem !important;
  margin-bottom: -6rem !important;
}

body .is-sticky .sticky-m-8 {
  margin: 7.5rem !important;
}

body .is-sticky .sticky-mt-8 {
  margin-top: 7.5rem !important;
}

body .is-sticky .sticky-mr-8 {
  margin-right: 7.5rem !important;
}

body .is-sticky .sticky-mb-8 {
  margin-bottom: 7.5rem !important;
}

body .is-sticky .sticky-ml-8 {
  margin-left: 7.5rem !important;
}

body .is-sticky .sticky-mx-8 {
  margin-right: 7.5rem !important;
  margin-left: 7.5rem !important;
}

body .is-sticky .sticky-my-8 {
  margin-top: 7.5rem !important;
  margin-bottom: 7.5rem !important;
}

body .is-sticky .sticky-m-8-neg {
  margin: -7.5rem !important;
}

body .is-sticky .sticky-mt-8-neg {
  margin-top: -7.5rem !important;
}

body .is-sticky .sticky-mr-8-neg {
  margin-right: -7.5rem !important;
}

body .is-sticky .sticky-mb-8-neg {
  margin-bottom: -7.5rem !important;
}

body .is-sticky .sticky-ml-8-neg {
  margin-left: -7.5rem !important;
}

body .is-sticky .sticky-mx-8-neg {
  margin-right: -7.5rem !important;
  margin-left: -7.5rem !important;
}

body .is-sticky .sticky-my-8-neg {
  margin-top: -7.5rem !important;
  margin-bottom: -7.5rem !important;
}

body .is-sticky .sticky-m-9 {
  margin: 9rem !important;
}

body .is-sticky .sticky-mt-9 {
  margin-top: 9rem !important;
}

body .is-sticky .sticky-mr-9 {
  margin-right: 9rem !important;
}

body .is-sticky .sticky-mb-9 {
  margin-bottom: 9rem !important;
}

body .is-sticky .sticky-ml-9 {
  margin-left: 9rem !important;
}

body .is-sticky .sticky-mx-9 {
  margin-right: 9rem !important;
  margin-left: 9rem !important;
}

body .is-sticky .sticky-my-9 {
  margin-top: 9rem !important;
  margin-bottom: 9rem !important;
}

body .is-sticky .sticky-m-9-neg {
  margin: -9rem !important;
}

body .is-sticky .sticky-mt-9-neg {
  margin-top: -9rem !important;
}

body .is-sticky .sticky-mr-9-neg {
  margin-right: -9rem !important;
}

body .is-sticky .sticky-mb-9-neg {
  margin-bottom: -9rem !important;
}

body .is-sticky .sticky-ml-9-neg {
  margin-left: -9rem !important;
}

body .is-sticky .sticky-mx-9-neg {
  margin-right: -9rem !important;
  margin-left: -9rem !important;
}

body .is-sticky .sticky-my-9-neg {
  margin-top: -9rem !important;
  margin-bottom: -9rem !important;
}

body .is-sticky .sticky-m-10 {
  margin: 10.5rem !important;
}

body .is-sticky .sticky-mt-10 {
  margin-top: 10.5rem !important;
}

body .is-sticky .sticky-mr-10 {
  margin-right: 10.5rem !important;
}

body .is-sticky .sticky-mb-10 {
  margin-bottom: 10.5rem !important;
}

body .is-sticky .sticky-ml-10 {
  margin-left: 10.5rem !important;
}

body .is-sticky .sticky-mx-10 {
  margin-right: 10.5rem !important;
  margin-left: 10.5rem !important;
}

body .is-sticky .sticky-my-10 {
  margin-top: 10.5rem !important;
  margin-bottom: 10.5rem !important;
}

body .is-sticky .sticky-m-10-neg {
  margin: -10.5rem !important;
}

body .is-sticky .sticky-mt-10-neg {
  margin-top: -10.5rem !important;
}

body .is-sticky .sticky-mr-10-neg {
  margin-right: -10.5rem !important;
}

body .is-sticky .sticky-mb-10-neg {
  margin-bottom: -10.5rem !important;
}

body .is-sticky .sticky-ml-10-neg {
  margin-left: -10.5rem !important;
}

body .is-sticky .sticky-mx-10-neg {
  margin-right: -10.5rem !important;
  margin-left: -10.5rem !important;
}

body .is-sticky .sticky-my-10-neg {
  margin-top: -10.5rem !important;
  margin-bottom: -10.5rem !important;
}

body .is-sticky .sticky-p-0 {
  padding: 0 !important;
}

body .is-sticky .sticky-pt-0 {
  padding-top: 0 !important;
}

body .is-sticky .sticky-pr-0 {
  padding-right: 0 !important;
}

body .is-sticky .sticky-pb-0 {
  padding-bottom: 0 !important;
}

body .is-sticky .sticky-pl-0 {
  padding-left: 0 !important;
}

body .is-sticky .sticky-px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

body .is-sticky .sticky-py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

body .is-sticky .sticky-p-1 {
  padding: 0.25rem !important;
}

body .is-sticky .sticky-pt-1 {
  padding-top: 0.25rem !important;
}

body .is-sticky .sticky-pr-1 {
  padding-right: 0.25rem !important;
}

body .is-sticky .sticky-pb-1 {
  padding-bottom: 0.25rem !important;
}

body .is-sticky .sticky-pl-1 {
  padding-left: 0.25rem !important;
}

body .is-sticky .sticky-px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

body .is-sticky .sticky-py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

body .is-sticky .sticky-p-2 {
  padding: 0.5rem !important;
}

body .is-sticky .sticky-pt-2 {
  padding-top: 0.5rem !important;
}

body .is-sticky .sticky-pr-2 {
  padding-right: 0.5rem !important;
}

body .is-sticky .sticky-pb-2 {
  padding-bottom: 0.5rem !important;
}

body .is-sticky .sticky-pl-2 {
  padding-left: 0.5rem !important;
}

body .is-sticky .sticky-px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

body .is-sticky .sticky-py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

body .is-sticky .sticky-p-3 {
  padding: 1rem !important;
}

body .is-sticky .sticky-pt-3 {
  padding-top: 1rem !important;
}

body .is-sticky .sticky-pr-3 {
  padding-right: 1rem !important;
}

body .is-sticky .sticky-pb-3 {
  padding-bottom: 1rem !important;
}

body .is-sticky .sticky-pl-3 {
  padding-left: 1rem !important;
}

body .is-sticky .sticky-px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

body .is-sticky .sticky-py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

body .is-sticky .sticky-p-4 {
  padding: 1.5rem !important;
}

body .is-sticky .sticky-pt-4 {
  padding-top: 1.5rem !important;
}

body .is-sticky .sticky-pr-4 {
  padding-right: 1.5rem !important;
}

body .is-sticky .sticky-pb-4 {
  padding-bottom: 1.5rem !important;
}

body .is-sticky .sticky-pl-4 {
  padding-left: 1.5rem !important;
}

body .is-sticky .sticky-px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

body .is-sticky .sticky-py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

body .is-sticky .sticky-p-5 {
  padding: 3rem !important;
}

body .is-sticky .sticky-pt-5 {
  padding-top: 3rem !important;
}

body .is-sticky .sticky-pr-5 {
  padding-right: 3rem !important;
}

body .is-sticky .sticky-pb-5 {
  padding-bottom: 3rem !important;
}

body .is-sticky .sticky-pl-5 {
  padding-left: 3rem !important;
}

body .is-sticky .sticky-px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

body .is-sticky .sticky-py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

body .is-sticky .sticky-p-6 {
  padding: 4.5rem !important;
}

body .is-sticky .sticky-pt-6 {
  padding-top: 4.5rem !important;
}

body .is-sticky .sticky-pr-6 {
  padding-right: 4.5rem !important;
}

body .is-sticky .sticky-pb-6 {
  padding-bottom: 4.5rem !important;
}

body .is-sticky .sticky-pl-6 {
  padding-left: 4.5rem !important;
}

body .is-sticky .sticky-px-6 {
  padding-right: 4.5rem !important;
  padding-left: 4.5rem !important;
}

body .is-sticky .sticky-py-6 {
  padding-top: 4.5rem !important;
  padding-bottom: 4.5rem !important;
}

body .is-sticky .sticky-p-7 {
  padding: 6rem !important;
}

body .is-sticky .sticky-pt-7 {
  padding-top: 6rem !important;
}

body .is-sticky .sticky-pr-7 {
  padding-right: 6rem !important;
}

body .is-sticky .sticky-pb-7 {
  padding-bottom: 6rem !important;
}

body .is-sticky .sticky-pl-7 {
  padding-left: 6rem !important;
}

body .is-sticky .sticky-px-7 {
  padding-right: 6rem !important;
  padding-left: 6rem !important;
}

body .is-sticky .sticky-py-7 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}

body .is-sticky .sticky-p-8 {
  padding: 7.5rem !important;
}

body .is-sticky .sticky-pt-8 {
  padding-top: 7.5rem !important;
}

body .is-sticky .sticky-pr-8 {
  padding-right: 7.5rem !important;
}

body .is-sticky .sticky-pb-8 {
  padding-bottom: 7.5rem !important;
}

body .is-sticky .sticky-pl-8 {
  padding-left: 7.5rem !important;
}

body .is-sticky .sticky-px-8 {
  padding-right: 7.5rem !important;
  padding-left: 7.5rem !important;
}

body .is-sticky .sticky-py-8 {
  padding-top: 7.5rem !important;
  padding-bottom: 7.5rem !important;
}

body .is-sticky .sticky-p-9 {
  padding: 9rem !important;
}

body .is-sticky .sticky-pt-9 {
  padding-top: 9rem !important;
}

body .is-sticky .sticky-pr-9 {
  padding-right: 9rem !important;
}

body .is-sticky .sticky-pb-9 {
  padding-bottom: 9rem !important;
}

body .is-sticky .sticky-pl-9 {
  padding-left: 9rem !important;
}

body .is-sticky .sticky-px-9 {
  padding-right: 9rem !important;
  padding-left: 9rem !important;
}

body .is-sticky .sticky-py-9 {
  padding-top: 9rem !important;
  padding-bottom: 9rem !important;
}

body .is-sticky .sticky-p-10 {
  padding: 10.5rem !important;
}

body .is-sticky .sticky-pt-10 {
  padding-top: 10.5rem !important;
}

body .is-sticky .sticky-pr-10 {
  padding-right: 10.5rem !important;
}

body .is-sticky .sticky-pb-10 {
  padding-bottom: 10.5rem !important;
}

body .is-sticky .sticky-pl-10 {
  padding-left: 10.5rem !important;
}

body .is-sticky .sticky-px-10 {
  padding-right: 10.5rem !important;
  padding-left: 10.5rem !important;
}

body .is-sticky .sticky-py-10 {
  padding-top: 10.5rem !important;
  padding-bottom: 10.5rem !important;
}

body .is-sticky .sticky-m-auto {
  margin: auto !important;
}

body .is-sticky .sticky-mt-auto {
  margin-top: auto !important;
}

body .is-sticky .sticky-mr-auto {
  margin-right: auto !important;
}

body .is-sticky .sticky-mb-auto {
  margin-bottom: auto !important;
}

body .is-sticky .sticky-ml-auto {
  margin-left: auto !important;
}

body .is-sticky .sticky-mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

body .is-sticky .sticky-my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

@media (min-width: 576px) {
  body .is-sticky .sticky-m-sm-0 {
    margin: 0 !important;
  }
  body .is-sticky .sticky-mt-sm-0 {
    margin-top: 0 !important;
  }
  body .is-sticky .sticky-mr-sm-0 {
    margin-right: 0 !important;
  }
  body .is-sticky .sticky-mb-sm-0 {
    margin-bottom: 0 !important;
  }
  body .is-sticky .sticky-ml-sm-0 {
    margin-left: 0 !important;
  }
  body .is-sticky .sticky-mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  body .is-sticky .sticky-my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  body .is-sticky .sticky-m-sm-1 {
    margin: 0.25rem !important;
  }
  body .is-sticky .sticky-mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  body .is-sticky .sticky-mr-sm-1 {
    margin-right: 0.25rem !important;
  }
  body .is-sticky .sticky-mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  body .is-sticky .sticky-ml-sm-1 {
    margin-left: 0.25rem !important;
  }
  body .is-sticky .sticky-mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  body .is-sticky .sticky-my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  body .is-sticky .sticky-m-sm-1-neg {
    margin: -0.25rem !important;
  }
  body .is-sticky .sticky-mt-sm-1-neg {
    margin-top: -0.25rem !important;
  }
  body .is-sticky .sticky-mr-sm-1-neg {
    margin-right: -0.25rem !important;
  }
  body .is-sticky .sticky-mb-sm-1-neg {
    margin-bottom: -0.25rem !important;
  }
  body .is-sticky .sticky-ml-sm-1-neg {
    margin-left: -0.25rem !important;
  }
  body .is-sticky .sticky-mx-sm-1-neg {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  body .is-sticky .sticky-my-sm-1-neg {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  body .is-sticky .sticky-m-sm-2 {
    margin: 0.5rem !important;
  }
  body .is-sticky .sticky-mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  body .is-sticky .sticky-mr-sm-2 {
    margin-right: 0.5rem !important;
  }
  body .is-sticky .sticky-mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  body .is-sticky .sticky-ml-sm-2 {
    margin-left: 0.5rem !important;
  }
  body .is-sticky .sticky-mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  body .is-sticky .sticky-my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  body .is-sticky .sticky-m-sm-2-neg {
    margin: -0.5rem !important;
  }
  body .is-sticky .sticky-mt-sm-2-neg {
    margin-top: -0.5rem !important;
  }
  body .is-sticky .sticky-mr-sm-2-neg {
    margin-right: -0.5rem !important;
  }
  body .is-sticky .sticky-mb-sm-2-neg {
    margin-bottom: -0.5rem !important;
  }
  body .is-sticky .sticky-ml-sm-2-neg {
    margin-left: -0.5rem !important;
  }
  body .is-sticky .sticky-mx-sm-2-neg {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  body .is-sticky .sticky-my-sm-2-neg {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  body .is-sticky .sticky-m-sm-3 {
    margin: 1rem !important;
  }
  body .is-sticky .sticky-mt-sm-3 {
    margin-top: 1rem !important;
  }
  body .is-sticky .sticky-mr-sm-3 {
    margin-right: 1rem !important;
  }
  body .is-sticky .sticky-mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  body .is-sticky .sticky-ml-sm-3 {
    margin-left: 1rem !important;
  }
  body .is-sticky .sticky-mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  body .is-sticky .sticky-my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  body .is-sticky .sticky-m-sm-3-neg {
    margin: -1rem !important;
  }
  body .is-sticky .sticky-mt-sm-3-neg {
    margin-top: -1rem !important;
  }
  body .is-sticky .sticky-mr-sm-3-neg {
    margin-right: -1rem !important;
  }
  body .is-sticky .sticky-mb-sm-3-neg {
    margin-bottom: -1rem !important;
  }
  body .is-sticky .sticky-ml-sm-3-neg {
    margin-left: -1rem !important;
  }
  body .is-sticky .sticky-mx-sm-3-neg {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  body .is-sticky .sticky-my-sm-3-neg {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  body .is-sticky .sticky-m-sm-4 {
    margin: 1.5rem !important;
  }
  body .is-sticky .sticky-mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  body .is-sticky .sticky-mr-sm-4 {
    margin-right: 1.5rem !important;
  }
  body .is-sticky .sticky-mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  body .is-sticky .sticky-ml-sm-4 {
    margin-left: 1.5rem !important;
  }
  body .is-sticky .sticky-mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  body .is-sticky .sticky-my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  body .is-sticky .sticky-m-sm-4-neg {
    margin: -1.5rem !important;
  }
  body .is-sticky .sticky-mt-sm-4-neg {
    margin-top: -1.5rem !important;
  }
  body .is-sticky .sticky-mr-sm-4-neg {
    margin-right: -1.5rem !important;
  }
  body .is-sticky .sticky-mb-sm-4-neg {
    margin-bottom: -1.5rem !important;
  }
  body .is-sticky .sticky-ml-sm-4-neg {
    margin-left: -1.5rem !important;
  }
  body .is-sticky .sticky-mx-sm-4-neg {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  body .is-sticky .sticky-my-sm-4-neg {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  body .is-sticky .sticky-m-sm-5 {
    margin: 3rem !important;
  }
  body .is-sticky .sticky-mt-sm-5 {
    margin-top: 3rem !important;
  }
  body .is-sticky .sticky-mr-sm-5 {
    margin-right: 3rem !important;
  }
  body .is-sticky .sticky-mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  body .is-sticky .sticky-ml-sm-5 {
    margin-left: 3rem !important;
  }
  body .is-sticky .sticky-mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  body .is-sticky .sticky-my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  body .is-sticky .sticky-m-sm-5-neg {
    margin: -3rem !important;
  }
  body .is-sticky .sticky-mt-sm-5-neg {
    margin-top: -3rem !important;
  }
  body .is-sticky .sticky-mr-sm-5-neg {
    margin-right: -3rem !important;
  }
  body .is-sticky .sticky-mb-sm-5-neg {
    margin-bottom: -3rem !important;
  }
  body .is-sticky .sticky-ml-sm-5-neg {
    margin-left: -3rem !important;
  }
  body .is-sticky .sticky-mx-sm-5-neg {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  body .is-sticky .sticky-my-sm-5-neg {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  body .is-sticky .sticky-m-sm-6 {
    margin: 4.5rem !important;
  }
  body .is-sticky .sticky-mt-sm-6 {
    margin-top: 4.5rem !important;
  }
  body .is-sticky .sticky-mr-sm-6 {
    margin-right: 4.5rem !important;
  }
  body .is-sticky .sticky-mb-sm-6 {
    margin-bottom: 4.5rem !important;
  }
  body .is-sticky .sticky-ml-sm-6 {
    margin-left: 4.5rem !important;
  }
  body .is-sticky .sticky-mx-sm-6 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  body .is-sticky .sticky-my-sm-6 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  body .is-sticky .sticky-m-sm-6-neg {
    margin: -4.5rem !important;
  }
  body .is-sticky .sticky-mt-sm-6-neg {
    margin-top: -4.5rem !important;
  }
  body .is-sticky .sticky-mr-sm-6-neg {
    margin-right: -4.5rem !important;
  }
  body .is-sticky .sticky-mb-sm-6-neg {
    margin-bottom: -4.5rem !important;
  }
  body .is-sticky .sticky-ml-sm-6-neg {
    margin-left: -4.5rem !important;
  }
  body .is-sticky .sticky-mx-sm-6-neg {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }
  body .is-sticky .sticky-my-sm-6-neg {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  body .is-sticky .sticky-m-sm-7 {
    margin: 6rem !important;
  }
  body .is-sticky .sticky-mt-sm-7 {
    margin-top: 6rem !important;
  }
  body .is-sticky .sticky-mr-sm-7 {
    margin-right: 6rem !important;
  }
  body .is-sticky .sticky-mb-sm-7 {
    margin-bottom: 6rem !important;
  }
  body .is-sticky .sticky-ml-sm-7 {
    margin-left: 6rem !important;
  }
  body .is-sticky .sticky-mx-sm-7 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  body .is-sticky .sticky-my-sm-7 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  body .is-sticky .sticky-m-sm-7-neg {
    margin: -6rem !important;
  }
  body .is-sticky .sticky-mt-sm-7-neg {
    margin-top: -6rem !important;
  }
  body .is-sticky .sticky-mr-sm-7-neg {
    margin-right: -6rem !important;
  }
  body .is-sticky .sticky-mb-sm-7-neg {
    margin-bottom: -6rem !important;
  }
  body .is-sticky .sticky-ml-sm-7-neg {
    margin-left: -6rem !important;
  }
  body .is-sticky .sticky-mx-sm-7-neg {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  body .is-sticky .sticky-my-sm-7-neg {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  body .is-sticky .sticky-m-sm-8 {
    margin: 7.5rem !important;
  }
  body .is-sticky .sticky-mt-sm-8 {
    margin-top: 7.5rem !important;
  }
  body .is-sticky .sticky-mr-sm-8 {
    margin-right: 7.5rem !important;
  }
  body .is-sticky .sticky-mb-sm-8 {
    margin-bottom: 7.5rem !important;
  }
  body .is-sticky .sticky-ml-sm-8 {
    margin-left: 7.5rem !important;
  }
  body .is-sticky .sticky-mx-sm-8 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important;
  }
  body .is-sticky .sticky-my-sm-8 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }
  body .is-sticky .sticky-m-sm-8-neg {
    margin: -7.5rem !important;
  }
  body .is-sticky .sticky-mt-sm-8-neg {
    margin-top: -7.5rem !important;
  }
  body .is-sticky .sticky-mr-sm-8-neg {
    margin-right: -7.5rem !important;
  }
  body .is-sticky .sticky-mb-sm-8-neg {
    margin-bottom: -7.5rem !important;
  }
  body .is-sticky .sticky-ml-sm-8-neg {
    margin-left: -7.5rem !important;
  }
  body .is-sticky .sticky-mx-sm-8-neg {
    margin-right: -7.5rem !important;
    margin-left: -7.5rem !important;
  }
  body .is-sticky .sticky-my-sm-8-neg {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }
  body .is-sticky .sticky-m-sm-9 {
    margin: 9rem !important;
  }
  body .is-sticky .sticky-mt-sm-9 {
    margin-top: 9rem !important;
  }
  body .is-sticky .sticky-mr-sm-9 {
    margin-right: 9rem !important;
  }
  body .is-sticky .sticky-mb-sm-9 {
    margin-bottom: 9rem !important;
  }
  body .is-sticky .sticky-ml-sm-9 {
    margin-left: 9rem !important;
  }
  body .is-sticky .sticky-mx-sm-9 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }
  body .is-sticky .sticky-my-sm-9 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }
  body .is-sticky .sticky-m-sm-9-neg {
    margin: -9rem !important;
  }
  body .is-sticky .sticky-mt-sm-9-neg {
    margin-top: -9rem !important;
  }
  body .is-sticky .sticky-mr-sm-9-neg {
    margin-right: -9rem !important;
  }
  body .is-sticky .sticky-mb-sm-9-neg {
    margin-bottom: -9rem !important;
  }
  body .is-sticky .sticky-ml-sm-9-neg {
    margin-left: -9rem !important;
  }
  body .is-sticky .sticky-mx-sm-9-neg {
    margin-right: -9rem !important;
    margin-left: -9rem !important;
  }
  body .is-sticky .sticky-my-sm-9-neg {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }
  body .is-sticky .sticky-m-sm-10 {
    margin: 10.5rem !important;
  }
  body .is-sticky .sticky-mt-sm-10 {
    margin-top: 10.5rem !important;
  }
  body .is-sticky .sticky-mr-sm-10 {
    margin-right: 10.5rem !important;
  }
  body .is-sticky .sticky-mb-sm-10 {
    margin-bottom: 10.5rem !important;
  }
  body .is-sticky .sticky-ml-sm-10 {
    margin-left: 10.5rem !important;
  }
  body .is-sticky .sticky-mx-sm-10 {
    margin-right: 10.5rem !important;
    margin-left: 10.5rem !important;
  }
  body .is-sticky .sticky-my-sm-10 {
    margin-top: 10.5rem !important;
    margin-bottom: 10.5rem !important;
  }
  body .is-sticky .sticky-m-sm-10-neg {
    margin: -10.5rem !important;
  }
  body .is-sticky .sticky-mt-sm-10-neg {
    margin-top: -10.5rem !important;
  }
  body .is-sticky .sticky-mr-sm-10-neg {
    margin-right: -10.5rem !important;
  }
  body .is-sticky .sticky-mb-sm-10-neg {
    margin-bottom: -10.5rem !important;
  }
  body .is-sticky .sticky-ml-sm-10-neg {
    margin-left: -10.5rem !important;
  }
  body .is-sticky .sticky-mx-sm-10-neg {
    margin-right: -10.5rem !important;
    margin-left: -10.5rem !important;
  }
  body .is-sticky .sticky-my-sm-10-neg {
    margin-top: -10.5rem !important;
    margin-bottom: -10.5rem !important;
  }
  body .is-sticky .sticky-p-sm-0 {
    padding: 0 !important;
  }
  body .is-sticky .sticky-pt-sm-0 {
    padding-top: 0 !important;
  }
  body .is-sticky .sticky-pr-sm-0 {
    padding-right: 0 !important;
  }
  body .is-sticky .sticky-pb-sm-0 {
    padding-bottom: 0 !important;
  }
  body .is-sticky .sticky-pl-sm-0 {
    padding-left: 0 !important;
  }
  body .is-sticky .sticky-px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  body .is-sticky .sticky-py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  body .is-sticky .sticky-p-sm-1 {
    padding: 0.25rem !important;
  }
  body .is-sticky .sticky-pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  body .is-sticky .sticky-pr-sm-1 {
    padding-right: 0.25rem !important;
  }
  body .is-sticky .sticky-pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  body .is-sticky .sticky-pl-sm-1 {
    padding-left: 0.25rem !important;
  }
  body .is-sticky .sticky-px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  body .is-sticky .sticky-py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  body .is-sticky .sticky-p-sm-2 {
    padding: 0.5rem !important;
  }
  body .is-sticky .sticky-pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  body .is-sticky .sticky-pr-sm-2 {
    padding-right: 0.5rem !important;
  }
  body .is-sticky .sticky-pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  body .is-sticky .sticky-pl-sm-2 {
    padding-left: 0.5rem !important;
  }
  body .is-sticky .sticky-px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  body .is-sticky .sticky-py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  body .is-sticky .sticky-p-sm-3 {
    padding: 1rem !important;
  }
  body .is-sticky .sticky-pt-sm-3 {
    padding-top: 1rem !important;
  }
  body .is-sticky .sticky-pr-sm-3 {
    padding-right: 1rem !important;
  }
  body .is-sticky .sticky-pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  body .is-sticky .sticky-pl-sm-3 {
    padding-left: 1rem !important;
  }
  body .is-sticky .sticky-px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  body .is-sticky .sticky-py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  body .is-sticky .sticky-p-sm-4 {
    padding: 1.5rem !important;
  }
  body .is-sticky .sticky-pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  body .is-sticky .sticky-pr-sm-4 {
    padding-right: 1.5rem !important;
  }
  body .is-sticky .sticky-pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  body .is-sticky .sticky-pl-sm-4 {
    padding-left: 1.5rem !important;
  }
  body .is-sticky .sticky-px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  body .is-sticky .sticky-py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  body .is-sticky .sticky-p-sm-5 {
    padding: 3rem !important;
  }
  body .is-sticky .sticky-pt-sm-5 {
    padding-top: 3rem !important;
  }
  body .is-sticky .sticky-pr-sm-5 {
    padding-right: 3rem !important;
  }
  body .is-sticky .sticky-pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  body .is-sticky .sticky-pl-sm-5 {
    padding-left: 3rem !important;
  }
  body .is-sticky .sticky-px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  body .is-sticky .sticky-py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  body .is-sticky .sticky-p-sm-6 {
    padding: 4.5rem !important;
  }
  body .is-sticky .sticky-pt-sm-6 {
    padding-top: 4.5rem !important;
  }
  body .is-sticky .sticky-pr-sm-6 {
    padding-right: 4.5rem !important;
  }
  body .is-sticky .sticky-pb-sm-6 {
    padding-bottom: 4.5rem !important;
  }
  body .is-sticky .sticky-pl-sm-6 {
    padding-left: 4.5rem !important;
  }
  body .is-sticky .sticky-px-sm-6 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  body .is-sticky .sticky-py-sm-6 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  body .is-sticky .sticky-p-sm-7 {
    padding: 6rem !important;
  }
  body .is-sticky .sticky-pt-sm-7 {
    padding-top: 6rem !important;
  }
  body .is-sticky .sticky-pr-sm-7 {
    padding-right: 6rem !important;
  }
  body .is-sticky .sticky-pb-sm-7 {
    padding-bottom: 6rem !important;
  }
  body .is-sticky .sticky-pl-sm-7 {
    padding-left: 6rem !important;
  }
  body .is-sticky .sticky-px-sm-7 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  body .is-sticky .sticky-py-sm-7 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  body .is-sticky .sticky-p-sm-8 {
    padding: 7.5rem !important;
  }
  body .is-sticky .sticky-pt-sm-8 {
    padding-top: 7.5rem !important;
  }
  body .is-sticky .sticky-pr-sm-8 {
    padding-right: 7.5rem !important;
  }
  body .is-sticky .sticky-pb-sm-8 {
    padding-bottom: 7.5rem !important;
  }
  body .is-sticky .sticky-pl-sm-8 {
    padding-left: 7.5rem !important;
  }
  body .is-sticky .sticky-px-sm-8 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important;
  }
  body .is-sticky .sticky-py-sm-8 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  body .is-sticky .sticky-p-sm-9 {
    padding: 9rem !important;
  }
  body .is-sticky .sticky-pt-sm-9 {
    padding-top: 9rem !important;
  }
  body .is-sticky .sticky-pr-sm-9 {
    padding-right: 9rem !important;
  }
  body .is-sticky .sticky-pb-sm-9 {
    padding-bottom: 9rem !important;
  }
  body .is-sticky .sticky-pl-sm-9 {
    padding-left: 9rem !important;
  }
  body .is-sticky .sticky-px-sm-9 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }
  body .is-sticky .sticky-py-sm-9 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }
  body .is-sticky .sticky-p-sm-10 {
    padding: 10.5rem !important;
  }
  body .is-sticky .sticky-pt-sm-10 {
    padding-top: 10.5rem !important;
  }
  body .is-sticky .sticky-pr-sm-10 {
    padding-right: 10.5rem !important;
  }
  body .is-sticky .sticky-pb-sm-10 {
    padding-bottom: 10.5rem !important;
  }
  body .is-sticky .sticky-pl-sm-10 {
    padding-left: 10.5rem !important;
  }
  body .is-sticky .sticky-px-sm-10 {
    padding-right: 10.5rem !important;
    padding-left: 10.5rem !important;
  }
  body .is-sticky .sticky-py-sm-10 {
    padding-top: 10.5rem !important;
    padding-bottom: 10.5rem !important;
  }
  body .is-sticky .sticky-m-sm-auto {
    margin: auto !important;
  }
  body .is-sticky .sticky-mt-sm-auto {
    margin-top: auto !important;
  }
  body .is-sticky .sticky-mr-sm-auto {
    margin-right: auto !important;
  }
  body .is-sticky .sticky-mb-sm-auto {
    margin-bottom: auto !important;
  }
  body .is-sticky .sticky-ml-sm-auto {
    margin-left: auto !important;
  }
  body .is-sticky .sticky-mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  body .is-sticky .sticky-my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}

@media (min-width: 768px) {
  body .is-sticky .sticky-m-md-0 {
    margin: 0 !important;
  }
  body .is-sticky .sticky-mt-md-0 {
    margin-top: 0 !important;
  }
  body .is-sticky .sticky-mr-md-0 {
    margin-right: 0 !important;
  }
  body .is-sticky .sticky-mb-md-0 {
    margin-bottom: 0 !important;
  }
  body .is-sticky .sticky-ml-md-0 {
    margin-left: 0 !important;
  }
  body .is-sticky .sticky-mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  body .is-sticky .sticky-my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  body .is-sticky .sticky-m-md-1 {
    margin: 0.25rem !important;
  }
  body .is-sticky .sticky-mt-md-1 {
    margin-top: 0.25rem !important;
  }
  body .is-sticky .sticky-mr-md-1 {
    margin-right: 0.25rem !important;
  }
  body .is-sticky .sticky-mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  body .is-sticky .sticky-ml-md-1 {
    margin-left: 0.25rem !important;
  }
  body .is-sticky .sticky-mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  body .is-sticky .sticky-my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  body .is-sticky .sticky-m-md-1-neg {
    margin: -0.25rem !important;
  }
  body .is-sticky .sticky-mt-md-1-neg {
    margin-top: -0.25rem !important;
  }
  body .is-sticky .sticky-mr-md-1-neg {
    margin-right: -0.25rem !important;
  }
  body .is-sticky .sticky-mb-md-1-neg {
    margin-bottom: -0.25rem !important;
  }
  body .is-sticky .sticky-ml-md-1-neg {
    margin-left: -0.25rem !important;
  }
  body .is-sticky .sticky-mx-md-1-neg {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  body .is-sticky .sticky-my-md-1-neg {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  body .is-sticky .sticky-m-md-2 {
    margin: 0.5rem !important;
  }
  body .is-sticky .sticky-mt-md-2 {
    margin-top: 0.5rem !important;
  }
  body .is-sticky .sticky-mr-md-2 {
    margin-right: 0.5rem !important;
  }
  body .is-sticky .sticky-mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  body .is-sticky .sticky-ml-md-2 {
    margin-left: 0.5rem !important;
  }
  body .is-sticky .sticky-mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  body .is-sticky .sticky-my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  body .is-sticky .sticky-m-md-2-neg {
    margin: -0.5rem !important;
  }
  body .is-sticky .sticky-mt-md-2-neg {
    margin-top: -0.5rem !important;
  }
  body .is-sticky .sticky-mr-md-2-neg {
    margin-right: -0.5rem !important;
  }
  body .is-sticky .sticky-mb-md-2-neg {
    margin-bottom: -0.5rem !important;
  }
  body .is-sticky .sticky-ml-md-2-neg {
    margin-left: -0.5rem !important;
  }
  body .is-sticky .sticky-mx-md-2-neg {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  body .is-sticky .sticky-my-md-2-neg {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  body .is-sticky .sticky-m-md-3 {
    margin: 1rem !important;
  }
  body .is-sticky .sticky-mt-md-3 {
    margin-top: 1rem !important;
  }
  body .is-sticky .sticky-mr-md-3 {
    margin-right: 1rem !important;
  }
  body .is-sticky .sticky-mb-md-3 {
    margin-bottom: 1rem !important;
  }
  body .is-sticky .sticky-ml-md-3 {
    margin-left: 1rem !important;
  }
  body .is-sticky .sticky-mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  body .is-sticky .sticky-my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  body .is-sticky .sticky-m-md-3-neg {
    margin: -1rem !important;
  }
  body .is-sticky .sticky-mt-md-3-neg {
    margin-top: -1rem !important;
  }
  body .is-sticky .sticky-mr-md-3-neg {
    margin-right: -1rem !important;
  }
  body .is-sticky .sticky-mb-md-3-neg {
    margin-bottom: -1rem !important;
  }
  body .is-sticky .sticky-ml-md-3-neg {
    margin-left: -1rem !important;
  }
  body .is-sticky .sticky-mx-md-3-neg {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  body .is-sticky .sticky-my-md-3-neg {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  body .is-sticky .sticky-m-md-4 {
    margin: 1.5rem !important;
  }
  body .is-sticky .sticky-mt-md-4 {
    margin-top: 1.5rem !important;
  }
  body .is-sticky .sticky-mr-md-4 {
    margin-right: 1.5rem !important;
  }
  body .is-sticky .sticky-mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  body .is-sticky .sticky-ml-md-4 {
    margin-left: 1.5rem !important;
  }
  body .is-sticky .sticky-mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  body .is-sticky .sticky-my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  body .is-sticky .sticky-m-md-4-neg {
    margin: -1.5rem !important;
  }
  body .is-sticky .sticky-mt-md-4-neg {
    margin-top: -1.5rem !important;
  }
  body .is-sticky .sticky-mr-md-4-neg {
    margin-right: -1.5rem !important;
  }
  body .is-sticky .sticky-mb-md-4-neg {
    margin-bottom: -1.5rem !important;
  }
  body .is-sticky .sticky-ml-md-4-neg {
    margin-left: -1.5rem !important;
  }
  body .is-sticky .sticky-mx-md-4-neg {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  body .is-sticky .sticky-my-md-4-neg {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  body .is-sticky .sticky-m-md-5 {
    margin: 3rem !important;
  }
  body .is-sticky .sticky-mt-md-5 {
    margin-top: 3rem !important;
  }
  body .is-sticky .sticky-mr-md-5 {
    margin-right: 3rem !important;
  }
  body .is-sticky .sticky-mb-md-5 {
    margin-bottom: 3rem !important;
  }
  body .is-sticky .sticky-ml-md-5 {
    margin-left: 3rem !important;
  }
  body .is-sticky .sticky-mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  body .is-sticky .sticky-my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  body .is-sticky .sticky-m-md-5-neg {
    margin: -3rem !important;
  }
  body .is-sticky .sticky-mt-md-5-neg {
    margin-top: -3rem !important;
  }
  body .is-sticky .sticky-mr-md-5-neg {
    margin-right: -3rem !important;
  }
  body .is-sticky .sticky-mb-md-5-neg {
    margin-bottom: -3rem !important;
  }
  body .is-sticky .sticky-ml-md-5-neg {
    margin-left: -3rem !important;
  }
  body .is-sticky .sticky-mx-md-5-neg {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  body .is-sticky .sticky-my-md-5-neg {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  body .is-sticky .sticky-m-md-6 {
    margin: 4.5rem !important;
  }
  body .is-sticky .sticky-mt-md-6 {
    margin-top: 4.5rem !important;
  }
  body .is-sticky .sticky-mr-md-6 {
    margin-right: 4.5rem !important;
  }
  body .is-sticky .sticky-mb-md-6 {
    margin-bottom: 4.5rem !important;
  }
  body .is-sticky .sticky-ml-md-6 {
    margin-left: 4.5rem !important;
  }
  body .is-sticky .sticky-mx-md-6 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  body .is-sticky .sticky-my-md-6 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  body .is-sticky .sticky-m-md-6-neg {
    margin: -4.5rem !important;
  }
  body .is-sticky .sticky-mt-md-6-neg {
    margin-top: -4.5rem !important;
  }
  body .is-sticky .sticky-mr-md-6-neg {
    margin-right: -4.5rem !important;
  }
  body .is-sticky .sticky-mb-md-6-neg {
    margin-bottom: -4.5rem !important;
  }
  body .is-sticky .sticky-ml-md-6-neg {
    margin-left: -4.5rem !important;
  }
  body .is-sticky .sticky-mx-md-6-neg {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }
  body .is-sticky .sticky-my-md-6-neg {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  body .is-sticky .sticky-m-md-7 {
    margin: 6rem !important;
  }
  body .is-sticky .sticky-mt-md-7 {
    margin-top: 6rem !important;
  }
  body .is-sticky .sticky-mr-md-7 {
    margin-right: 6rem !important;
  }
  body .is-sticky .sticky-mb-md-7 {
    margin-bottom: 6rem !important;
  }
  body .is-sticky .sticky-ml-md-7 {
    margin-left: 6rem !important;
  }
  body .is-sticky .sticky-mx-md-7 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  body .is-sticky .sticky-my-md-7 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  body .is-sticky .sticky-m-md-7-neg {
    margin: -6rem !important;
  }
  body .is-sticky .sticky-mt-md-7-neg {
    margin-top: -6rem !important;
  }
  body .is-sticky .sticky-mr-md-7-neg {
    margin-right: -6rem !important;
  }
  body .is-sticky .sticky-mb-md-7-neg {
    margin-bottom: -6rem !important;
  }
  body .is-sticky .sticky-ml-md-7-neg {
    margin-left: -6rem !important;
  }
  body .is-sticky .sticky-mx-md-7-neg {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  body .is-sticky .sticky-my-md-7-neg {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  body .is-sticky .sticky-m-md-8 {
    margin: 7.5rem !important;
  }
  body .is-sticky .sticky-mt-md-8 {
    margin-top: 7.5rem !important;
  }
  body .is-sticky .sticky-mr-md-8 {
    margin-right: 7.5rem !important;
  }
  body .is-sticky .sticky-mb-md-8 {
    margin-bottom: 7.5rem !important;
  }
  body .is-sticky .sticky-ml-md-8 {
    margin-left: 7.5rem !important;
  }
  body .is-sticky .sticky-mx-md-8 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important;
  }
  body .is-sticky .sticky-my-md-8 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }
  body .is-sticky .sticky-m-md-8-neg {
    margin: -7.5rem !important;
  }
  body .is-sticky .sticky-mt-md-8-neg {
    margin-top: -7.5rem !important;
  }
  body .is-sticky .sticky-mr-md-8-neg {
    margin-right: -7.5rem !important;
  }
  body .is-sticky .sticky-mb-md-8-neg {
    margin-bottom: -7.5rem !important;
  }
  body .is-sticky .sticky-ml-md-8-neg {
    margin-left: -7.5rem !important;
  }
  body .is-sticky .sticky-mx-md-8-neg {
    margin-right: -7.5rem !important;
    margin-left: -7.5rem !important;
  }
  body .is-sticky .sticky-my-md-8-neg {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }
  body .is-sticky .sticky-m-md-9 {
    margin: 9rem !important;
  }
  body .is-sticky .sticky-mt-md-9 {
    margin-top: 9rem !important;
  }
  body .is-sticky .sticky-mr-md-9 {
    margin-right: 9rem !important;
  }
  body .is-sticky .sticky-mb-md-9 {
    margin-bottom: 9rem !important;
  }
  body .is-sticky .sticky-ml-md-9 {
    margin-left: 9rem !important;
  }
  body .is-sticky .sticky-mx-md-9 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }
  body .is-sticky .sticky-my-md-9 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }
  body .is-sticky .sticky-m-md-9-neg {
    margin: -9rem !important;
  }
  body .is-sticky .sticky-mt-md-9-neg {
    margin-top: -9rem !important;
  }
  body .is-sticky .sticky-mr-md-9-neg {
    margin-right: -9rem !important;
  }
  body .is-sticky .sticky-mb-md-9-neg {
    margin-bottom: -9rem !important;
  }
  body .is-sticky .sticky-ml-md-9-neg {
    margin-left: -9rem !important;
  }
  body .is-sticky .sticky-mx-md-9-neg {
    margin-right: -9rem !important;
    margin-left: -9rem !important;
  }
  body .is-sticky .sticky-my-md-9-neg {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }
  body .is-sticky .sticky-m-md-10 {
    margin: 10.5rem !important;
  }
  body .is-sticky .sticky-mt-md-10 {
    margin-top: 10.5rem !important;
  }
  body .is-sticky .sticky-mr-md-10 {
    margin-right: 10.5rem !important;
  }
  body .is-sticky .sticky-mb-md-10 {
    margin-bottom: 10.5rem !important;
  }
  body .is-sticky .sticky-ml-md-10 {
    margin-left: 10.5rem !important;
  }
  body .is-sticky .sticky-mx-md-10 {
    margin-right: 10.5rem !important;
    margin-left: 10.5rem !important;
  }
  body .is-sticky .sticky-my-md-10 {
    margin-top: 10.5rem !important;
    margin-bottom: 10.5rem !important;
  }
  body .is-sticky .sticky-m-md-10-neg {
    margin: -10.5rem !important;
  }
  body .is-sticky .sticky-mt-md-10-neg {
    margin-top: -10.5rem !important;
  }
  body .is-sticky .sticky-mr-md-10-neg {
    margin-right: -10.5rem !important;
  }
  body .is-sticky .sticky-mb-md-10-neg {
    margin-bottom: -10.5rem !important;
  }
  body .is-sticky .sticky-ml-md-10-neg {
    margin-left: -10.5rem !important;
  }
  body .is-sticky .sticky-mx-md-10-neg {
    margin-right: -10.5rem !important;
    margin-left: -10.5rem !important;
  }
  body .is-sticky .sticky-my-md-10-neg {
    margin-top: -10.5rem !important;
    margin-bottom: -10.5rem !important;
  }
  body .is-sticky .sticky-p-md-0 {
    padding: 0 !important;
  }
  body .is-sticky .sticky-pt-md-0 {
    padding-top: 0 !important;
  }
  body .is-sticky .sticky-pr-md-0 {
    padding-right: 0 !important;
  }
  body .is-sticky .sticky-pb-md-0 {
    padding-bottom: 0 !important;
  }
  body .is-sticky .sticky-pl-md-0 {
    padding-left: 0 !important;
  }
  body .is-sticky .sticky-px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  body .is-sticky .sticky-py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  body .is-sticky .sticky-p-md-1 {
    padding: 0.25rem !important;
  }
  body .is-sticky .sticky-pt-md-1 {
    padding-top: 0.25rem !important;
  }
  body .is-sticky .sticky-pr-md-1 {
    padding-right: 0.25rem !important;
  }
  body .is-sticky .sticky-pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  body .is-sticky .sticky-pl-md-1 {
    padding-left: 0.25rem !important;
  }
  body .is-sticky .sticky-px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  body .is-sticky .sticky-py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  body .is-sticky .sticky-p-md-2 {
    padding: 0.5rem !important;
  }
  body .is-sticky .sticky-pt-md-2 {
    padding-top: 0.5rem !important;
  }
  body .is-sticky .sticky-pr-md-2 {
    padding-right: 0.5rem !important;
  }
  body .is-sticky .sticky-pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  body .is-sticky .sticky-pl-md-2 {
    padding-left: 0.5rem !important;
  }
  body .is-sticky .sticky-px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  body .is-sticky .sticky-py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  body .is-sticky .sticky-p-md-3 {
    padding: 1rem !important;
  }
  body .is-sticky .sticky-pt-md-3 {
    padding-top: 1rem !important;
  }
  body .is-sticky .sticky-pr-md-3 {
    padding-right: 1rem !important;
  }
  body .is-sticky .sticky-pb-md-3 {
    padding-bottom: 1rem !important;
  }
  body .is-sticky .sticky-pl-md-3 {
    padding-left: 1rem !important;
  }
  body .is-sticky .sticky-px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  body .is-sticky .sticky-py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  body .is-sticky .sticky-p-md-4 {
    padding: 1.5rem !important;
  }
  body .is-sticky .sticky-pt-md-4 {
    padding-top: 1.5rem !important;
  }
  body .is-sticky .sticky-pr-md-4 {
    padding-right: 1.5rem !important;
  }
  body .is-sticky .sticky-pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  body .is-sticky .sticky-pl-md-4 {
    padding-left: 1.5rem !important;
  }
  body .is-sticky .sticky-px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  body .is-sticky .sticky-py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  body .is-sticky .sticky-p-md-5 {
    padding: 3rem !important;
  }
  body .is-sticky .sticky-pt-md-5 {
    padding-top: 3rem !important;
  }
  body .is-sticky .sticky-pr-md-5 {
    padding-right: 3rem !important;
  }
  body .is-sticky .sticky-pb-md-5 {
    padding-bottom: 3rem !important;
  }
  body .is-sticky .sticky-pl-md-5 {
    padding-left: 3rem !important;
  }
  body .is-sticky .sticky-px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  body .is-sticky .sticky-py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  body .is-sticky .sticky-p-md-6 {
    padding: 4.5rem !important;
  }
  body .is-sticky .sticky-pt-md-6 {
    padding-top: 4.5rem !important;
  }
  body .is-sticky .sticky-pr-md-6 {
    padding-right: 4.5rem !important;
  }
  body .is-sticky .sticky-pb-md-6 {
    padding-bottom: 4.5rem !important;
  }
  body .is-sticky .sticky-pl-md-6 {
    padding-left: 4.5rem !important;
  }
  body .is-sticky .sticky-px-md-6 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  body .is-sticky .sticky-py-md-6 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  body .is-sticky .sticky-p-md-7 {
    padding: 6rem !important;
  }
  body .is-sticky .sticky-pt-md-7 {
    padding-top: 6rem !important;
  }
  body .is-sticky .sticky-pr-md-7 {
    padding-right: 6rem !important;
  }
  body .is-sticky .sticky-pb-md-7 {
    padding-bottom: 6rem !important;
  }
  body .is-sticky .sticky-pl-md-7 {
    padding-left: 6rem !important;
  }
  body .is-sticky .sticky-px-md-7 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  body .is-sticky .sticky-py-md-7 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  body .is-sticky .sticky-p-md-8 {
    padding: 7.5rem !important;
  }
  body .is-sticky .sticky-pt-md-8 {
    padding-top: 7.5rem !important;
  }
  body .is-sticky .sticky-pr-md-8 {
    padding-right: 7.5rem !important;
  }
  body .is-sticky .sticky-pb-md-8 {
    padding-bottom: 7.5rem !important;
  }
  body .is-sticky .sticky-pl-md-8 {
    padding-left: 7.5rem !important;
  }
  body .is-sticky .sticky-px-md-8 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important;
  }
  body .is-sticky .sticky-py-md-8 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  body .is-sticky .sticky-p-md-9 {
    padding: 9rem !important;
  }
  body .is-sticky .sticky-pt-md-9 {
    padding-top: 9rem !important;
  }
  body .is-sticky .sticky-pr-md-9 {
    padding-right: 9rem !important;
  }
  body .is-sticky .sticky-pb-md-9 {
    padding-bottom: 9rem !important;
  }
  body .is-sticky .sticky-pl-md-9 {
    padding-left: 9rem !important;
  }
  body .is-sticky .sticky-px-md-9 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }
  body .is-sticky .sticky-py-md-9 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }
  body .is-sticky .sticky-p-md-10 {
    padding: 10.5rem !important;
  }
  body .is-sticky .sticky-pt-md-10 {
    padding-top: 10.5rem !important;
  }
  body .is-sticky .sticky-pr-md-10 {
    padding-right: 10.5rem !important;
  }
  body .is-sticky .sticky-pb-md-10 {
    padding-bottom: 10.5rem !important;
  }
  body .is-sticky .sticky-pl-md-10 {
    padding-left: 10.5rem !important;
  }
  body .is-sticky .sticky-px-md-10 {
    padding-right: 10.5rem !important;
    padding-left: 10.5rem !important;
  }
  body .is-sticky .sticky-py-md-10 {
    padding-top: 10.5rem !important;
    padding-bottom: 10.5rem !important;
  }
  body .is-sticky .sticky-m-md-auto {
    margin: auto !important;
  }
  body .is-sticky .sticky-mt-md-auto {
    margin-top: auto !important;
  }
  body .is-sticky .sticky-mr-md-auto {
    margin-right: auto !important;
  }
  body .is-sticky .sticky-mb-md-auto {
    margin-bottom: auto !important;
  }
  body .is-sticky .sticky-ml-md-auto {
    margin-left: auto !important;
  }
  body .is-sticky .sticky-mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  body .is-sticky .sticky-my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}

@media (min-width: 992px) {
  body .is-sticky .sticky-m-lg-0 {
    margin: 0 !important;
  }
  body .is-sticky .sticky-mt-lg-0 {
    margin-top: 0 !important;
  }
  body .is-sticky .sticky-mr-lg-0 {
    margin-right: 0 !important;
  }
  body .is-sticky .sticky-mb-lg-0 {
    margin-bottom: 0 !important;
  }
  body .is-sticky .sticky-ml-lg-0 {
    margin-left: 0 !important;
  }
  body .is-sticky .sticky-mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  body .is-sticky .sticky-my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  body .is-sticky .sticky-m-lg-1 {
    margin: 0.25rem !important;
  }
  body .is-sticky .sticky-mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  body .is-sticky .sticky-mr-lg-1 {
    margin-right: 0.25rem !important;
  }
  body .is-sticky .sticky-mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  body .is-sticky .sticky-ml-lg-1 {
    margin-left: 0.25rem !important;
  }
  body .is-sticky .sticky-mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  body .is-sticky .sticky-my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  body .is-sticky .sticky-m-lg-1-neg {
    margin: -0.25rem !important;
  }
  body .is-sticky .sticky-mt-lg-1-neg {
    margin-top: -0.25rem !important;
  }
  body .is-sticky .sticky-mr-lg-1-neg {
    margin-right: -0.25rem !important;
  }
  body .is-sticky .sticky-mb-lg-1-neg {
    margin-bottom: -0.25rem !important;
  }
  body .is-sticky .sticky-ml-lg-1-neg {
    margin-left: -0.25rem !important;
  }
  body .is-sticky .sticky-mx-lg-1-neg {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  body .is-sticky .sticky-my-lg-1-neg {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  body .is-sticky .sticky-m-lg-2 {
    margin: 0.5rem !important;
  }
  body .is-sticky .sticky-mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  body .is-sticky .sticky-mr-lg-2 {
    margin-right: 0.5rem !important;
  }
  body .is-sticky .sticky-mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  body .is-sticky .sticky-ml-lg-2 {
    margin-left: 0.5rem !important;
  }
  body .is-sticky .sticky-mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  body .is-sticky .sticky-my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  body .is-sticky .sticky-m-lg-2-neg {
    margin: -0.5rem !important;
  }
  body .is-sticky .sticky-mt-lg-2-neg {
    margin-top: -0.5rem !important;
  }
  body .is-sticky .sticky-mr-lg-2-neg {
    margin-right: -0.5rem !important;
  }
  body .is-sticky .sticky-mb-lg-2-neg {
    margin-bottom: -0.5rem !important;
  }
  body .is-sticky .sticky-ml-lg-2-neg {
    margin-left: -0.5rem !important;
  }
  body .is-sticky .sticky-mx-lg-2-neg {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  body .is-sticky .sticky-my-lg-2-neg {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  body .is-sticky .sticky-m-lg-3 {
    margin: 1rem !important;
  }
  body .is-sticky .sticky-mt-lg-3 {
    margin-top: 1rem !important;
  }
  body .is-sticky .sticky-mr-lg-3 {
    margin-right: 1rem !important;
  }
  body .is-sticky .sticky-mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  body .is-sticky .sticky-ml-lg-3 {
    margin-left: 1rem !important;
  }
  body .is-sticky .sticky-mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  body .is-sticky .sticky-my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  body .is-sticky .sticky-m-lg-3-neg {
    margin: -1rem !important;
  }
  body .is-sticky .sticky-mt-lg-3-neg {
    margin-top: -1rem !important;
  }
  body .is-sticky .sticky-mr-lg-3-neg {
    margin-right: -1rem !important;
  }
  body .is-sticky .sticky-mb-lg-3-neg {
    margin-bottom: -1rem !important;
  }
  body .is-sticky .sticky-ml-lg-3-neg {
    margin-left: -1rem !important;
  }
  body .is-sticky .sticky-mx-lg-3-neg {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  body .is-sticky .sticky-my-lg-3-neg {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  body .is-sticky .sticky-m-lg-4 {
    margin: 1.5rem !important;
  }
  body .is-sticky .sticky-mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  body .is-sticky .sticky-mr-lg-4 {
    margin-right: 1.5rem !important;
  }
  body .is-sticky .sticky-mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  body .is-sticky .sticky-ml-lg-4 {
    margin-left: 1.5rem !important;
  }
  body .is-sticky .sticky-mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  body .is-sticky .sticky-my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  body .is-sticky .sticky-m-lg-4-neg {
    margin: -1.5rem !important;
  }
  body .is-sticky .sticky-mt-lg-4-neg {
    margin-top: -1.5rem !important;
  }
  body .is-sticky .sticky-mr-lg-4-neg {
    margin-right: -1.5rem !important;
  }
  body .is-sticky .sticky-mb-lg-4-neg {
    margin-bottom: -1.5rem !important;
  }
  body .is-sticky .sticky-ml-lg-4-neg {
    margin-left: -1.5rem !important;
  }
  body .is-sticky .sticky-mx-lg-4-neg {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  body .is-sticky .sticky-my-lg-4-neg {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  body .is-sticky .sticky-m-lg-5 {
    margin: 3rem !important;
  }
  body .is-sticky .sticky-mt-lg-5 {
    margin-top: 3rem !important;
  }
  body .is-sticky .sticky-mr-lg-5 {
    margin-right: 3rem !important;
  }
  body .is-sticky .sticky-mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  body .is-sticky .sticky-ml-lg-5 {
    margin-left: 3rem !important;
  }
  body .is-sticky .sticky-mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  body .is-sticky .sticky-my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  body .is-sticky .sticky-m-lg-5-neg {
    margin: -3rem !important;
  }
  body .is-sticky .sticky-mt-lg-5-neg {
    margin-top: -3rem !important;
  }
  body .is-sticky .sticky-mr-lg-5-neg {
    margin-right: -3rem !important;
  }
  body .is-sticky .sticky-mb-lg-5-neg {
    margin-bottom: -3rem !important;
  }
  body .is-sticky .sticky-ml-lg-5-neg {
    margin-left: -3rem !important;
  }
  body .is-sticky .sticky-mx-lg-5-neg {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  body .is-sticky .sticky-my-lg-5-neg {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  body .is-sticky .sticky-m-lg-6 {
    margin: 4.5rem !important;
  }
  body .is-sticky .sticky-mt-lg-6 {
    margin-top: 4.5rem !important;
  }
  body .is-sticky .sticky-mr-lg-6 {
    margin-right: 4.5rem !important;
  }
  body .is-sticky .sticky-mb-lg-6 {
    margin-bottom: 4.5rem !important;
  }
  body .is-sticky .sticky-ml-lg-6 {
    margin-left: 4.5rem !important;
  }
  body .is-sticky .sticky-mx-lg-6 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  body .is-sticky .sticky-my-lg-6 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  body .is-sticky .sticky-m-lg-6-neg {
    margin: -4.5rem !important;
  }
  body .is-sticky .sticky-mt-lg-6-neg {
    margin-top: -4.5rem !important;
  }
  body .is-sticky .sticky-mr-lg-6-neg {
    margin-right: -4.5rem !important;
  }
  body .is-sticky .sticky-mb-lg-6-neg {
    margin-bottom: -4.5rem !important;
  }
  body .is-sticky .sticky-ml-lg-6-neg {
    margin-left: -4.5rem !important;
  }
  body .is-sticky .sticky-mx-lg-6-neg {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }
  body .is-sticky .sticky-my-lg-6-neg {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  body .is-sticky .sticky-m-lg-7 {
    margin: 6rem !important;
  }
  body .is-sticky .sticky-mt-lg-7 {
    margin-top: 6rem !important;
  }
  body .is-sticky .sticky-mr-lg-7 {
    margin-right: 6rem !important;
  }
  body .is-sticky .sticky-mb-lg-7 {
    margin-bottom: 6rem !important;
  }
  body .is-sticky .sticky-ml-lg-7 {
    margin-left: 6rem !important;
  }
  body .is-sticky .sticky-mx-lg-7 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  body .is-sticky .sticky-my-lg-7 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  body .is-sticky .sticky-m-lg-7-neg {
    margin: -6rem !important;
  }
  body .is-sticky .sticky-mt-lg-7-neg {
    margin-top: -6rem !important;
  }
  body .is-sticky .sticky-mr-lg-7-neg {
    margin-right: -6rem !important;
  }
  body .is-sticky .sticky-mb-lg-7-neg {
    margin-bottom: -6rem !important;
  }
  body .is-sticky .sticky-ml-lg-7-neg {
    margin-left: -6rem !important;
  }
  body .is-sticky .sticky-mx-lg-7-neg {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  body .is-sticky .sticky-my-lg-7-neg {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  body .is-sticky .sticky-m-lg-8 {
    margin: 7.5rem !important;
  }
  body .is-sticky .sticky-mt-lg-8 {
    margin-top: 7.5rem !important;
  }
  body .is-sticky .sticky-mr-lg-8 {
    margin-right: 7.5rem !important;
  }
  body .is-sticky .sticky-mb-lg-8 {
    margin-bottom: 7.5rem !important;
  }
  body .is-sticky .sticky-ml-lg-8 {
    margin-left: 7.5rem !important;
  }
  body .is-sticky .sticky-mx-lg-8 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important;
  }
  body .is-sticky .sticky-my-lg-8 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }
  body .is-sticky .sticky-m-lg-8-neg {
    margin: -7.5rem !important;
  }
  body .is-sticky .sticky-mt-lg-8-neg {
    margin-top: -7.5rem !important;
  }
  body .is-sticky .sticky-mr-lg-8-neg {
    margin-right: -7.5rem !important;
  }
  body .is-sticky .sticky-mb-lg-8-neg {
    margin-bottom: -7.5rem !important;
  }
  body .is-sticky .sticky-ml-lg-8-neg {
    margin-left: -7.5rem !important;
  }
  body .is-sticky .sticky-mx-lg-8-neg {
    margin-right: -7.5rem !important;
    margin-left: -7.5rem !important;
  }
  body .is-sticky .sticky-my-lg-8-neg {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }
  body .is-sticky .sticky-m-lg-9 {
    margin: 9rem !important;
  }
  body .is-sticky .sticky-mt-lg-9 {
    margin-top: 9rem !important;
  }
  body .is-sticky .sticky-mr-lg-9 {
    margin-right: 9rem !important;
  }
  body .is-sticky .sticky-mb-lg-9 {
    margin-bottom: 9rem !important;
  }
  body .is-sticky .sticky-ml-lg-9 {
    margin-left: 9rem !important;
  }
  body .is-sticky .sticky-mx-lg-9 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }
  body .is-sticky .sticky-my-lg-9 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }
  body .is-sticky .sticky-m-lg-9-neg {
    margin: -9rem !important;
  }
  body .is-sticky .sticky-mt-lg-9-neg {
    margin-top: -9rem !important;
  }
  body .is-sticky .sticky-mr-lg-9-neg {
    margin-right: -9rem !important;
  }
  body .is-sticky .sticky-mb-lg-9-neg {
    margin-bottom: -9rem !important;
  }
  body .is-sticky .sticky-ml-lg-9-neg {
    margin-left: -9rem !important;
  }
  body .is-sticky .sticky-mx-lg-9-neg {
    margin-right: -9rem !important;
    margin-left: -9rem !important;
  }
  body .is-sticky .sticky-my-lg-9-neg {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }
  body .is-sticky .sticky-m-lg-10 {
    margin: 10.5rem !important;
  }
  body .is-sticky .sticky-mt-lg-10 {
    margin-top: 10.5rem !important;
  }
  body .is-sticky .sticky-mr-lg-10 {
    margin-right: 10.5rem !important;
  }
  body .is-sticky .sticky-mb-lg-10 {
    margin-bottom: 10.5rem !important;
  }
  body .is-sticky .sticky-ml-lg-10 {
    margin-left: 10.5rem !important;
  }
  body .is-sticky .sticky-mx-lg-10 {
    margin-right: 10.5rem !important;
    margin-left: 10.5rem !important;
  }
  body .is-sticky .sticky-my-lg-10 {
    margin-top: 10.5rem !important;
    margin-bottom: 10.5rem !important;
  }
  body .is-sticky .sticky-m-lg-10-neg {
    margin: -10.5rem !important;
  }
  body .is-sticky .sticky-mt-lg-10-neg {
    margin-top: -10.5rem !important;
  }
  body .is-sticky .sticky-mr-lg-10-neg {
    margin-right: -10.5rem !important;
  }
  body .is-sticky .sticky-mb-lg-10-neg {
    margin-bottom: -10.5rem !important;
  }
  body .is-sticky .sticky-ml-lg-10-neg {
    margin-left: -10.5rem !important;
  }
  body .is-sticky .sticky-mx-lg-10-neg {
    margin-right: -10.5rem !important;
    margin-left: -10.5rem !important;
  }
  body .is-sticky .sticky-my-lg-10-neg {
    margin-top: -10.5rem !important;
    margin-bottom: -10.5rem !important;
  }
  body .is-sticky .sticky-p-lg-0 {
    padding: 0 !important;
  }
  body .is-sticky .sticky-pt-lg-0 {
    padding-top: 0 !important;
  }
  body .is-sticky .sticky-pr-lg-0 {
    padding-right: 0 !important;
  }
  body .is-sticky .sticky-pb-lg-0 {
    padding-bottom: 0 !important;
  }
  body .is-sticky .sticky-pl-lg-0 {
    padding-left: 0 !important;
  }
  body .is-sticky .sticky-px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  body .is-sticky .sticky-py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  body .is-sticky .sticky-p-lg-1 {
    padding: 0.25rem !important;
  }
  body .is-sticky .sticky-pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  body .is-sticky .sticky-pr-lg-1 {
    padding-right: 0.25rem !important;
  }
  body .is-sticky .sticky-pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  body .is-sticky .sticky-pl-lg-1 {
    padding-left: 0.25rem !important;
  }
  body .is-sticky .sticky-px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  body .is-sticky .sticky-py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  body .is-sticky .sticky-p-lg-2 {
    padding: 0.5rem !important;
  }
  body .is-sticky .sticky-pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  body .is-sticky .sticky-pr-lg-2 {
    padding-right: 0.5rem !important;
  }
  body .is-sticky .sticky-pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  body .is-sticky .sticky-pl-lg-2 {
    padding-left: 0.5rem !important;
  }
  body .is-sticky .sticky-px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  body .is-sticky .sticky-py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  body .is-sticky .sticky-p-lg-3 {
    padding: 1rem !important;
  }
  body .is-sticky .sticky-pt-lg-3 {
    padding-top: 1rem !important;
  }
  body .is-sticky .sticky-pr-lg-3 {
    padding-right: 1rem !important;
  }
  body .is-sticky .sticky-pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  body .is-sticky .sticky-pl-lg-3 {
    padding-left: 1rem !important;
  }
  body .is-sticky .sticky-px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  body .is-sticky .sticky-py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  body .is-sticky .sticky-p-lg-4 {
    padding: 1.5rem !important;
  }
  body .is-sticky .sticky-pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  body .is-sticky .sticky-pr-lg-4 {
    padding-right: 1.5rem !important;
  }
  body .is-sticky .sticky-pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  body .is-sticky .sticky-pl-lg-4 {
    padding-left: 1.5rem !important;
  }
  body .is-sticky .sticky-px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  body .is-sticky .sticky-py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  body .is-sticky .sticky-p-lg-5 {
    padding: 3rem !important;
  }
  body .is-sticky .sticky-pt-lg-5 {
    padding-top: 3rem !important;
  }
  body .is-sticky .sticky-pr-lg-5 {
    padding-right: 3rem !important;
  }
  body .is-sticky .sticky-pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  body .is-sticky .sticky-pl-lg-5 {
    padding-left: 3rem !important;
  }
  body .is-sticky .sticky-px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  body .is-sticky .sticky-py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  body .is-sticky .sticky-p-lg-6 {
    padding: 4.5rem !important;
  }
  body .is-sticky .sticky-pt-lg-6 {
    padding-top: 4.5rem !important;
  }
  body .is-sticky .sticky-pr-lg-6 {
    padding-right: 4.5rem !important;
  }
  body .is-sticky .sticky-pb-lg-6 {
    padding-bottom: 4.5rem !important;
  }
  body .is-sticky .sticky-pl-lg-6 {
    padding-left: 4.5rem !important;
  }
  body .is-sticky .sticky-px-lg-6 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  body .is-sticky .sticky-py-lg-6 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  body .is-sticky .sticky-p-lg-7 {
    padding: 6rem !important;
  }
  body .is-sticky .sticky-pt-lg-7 {
    padding-top: 6rem !important;
  }
  body .is-sticky .sticky-pr-lg-7 {
    padding-right: 6rem !important;
  }
  body .is-sticky .sticky-pb-lg-7 {
    padding-bottom: 6rem !important;
  }
  body .is-sticky .sticky-pl-lg-7 {
    padding-left: 6rem !important;
  }
  body .is-sticky .sticky-px-lg-7 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  body .is-sticky .sticky-py-lg-7 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  body .is-sticky .sticky-p-lg-8 {
    padding: 7.5rem !important;
  }
  body .is-sticky .sticky-pt-lg-8 {
    padding-top: 7.5rem !important;
  }
  body .is-sticky .sticky-pr-lg-8 {
    padding-right: 7.5rem !important;
  }
  body .is-sticky .sticky-pb-lg-8 {
    padding-bottom: 7.5rem !important;
  }
  body .is-sticky .sticky-pl-lg-8 {
    padding-left: 7.5rem !important;
  }
  body .is-sticky .sticky-px-lg-8 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important;
  }
  body .is-sticky .sticky-py-lg-8 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  body .is-sticky .sticky-p-lg-9 {
    padding: 9rem !important;
  }
  body .is-sticky .sticky-pt-lg-9 {
    padding-top: 9rem !important;
  }
  body .is-sticky .sticky-pr-lg-9 {
    padding-right: 9rem !important;
  }
  body .is-sticky .sticky-pb-lg-9 {
    padding-bottom: 9rem !important;
  }
  body .is-sticky .sticky-pl-lg-9 {
    padding-left: 9rem !important;
  }
  body .is-sticky .sticky-px-lg-9 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }
  body .is-sticky .sticky-py-lg-9 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }
  body .is-sticky .sticky-p-lg-10 {
    padding: 10.5rem !important;
  }
  body .is-sticky .sticky-pt-lg-10 {
    padding-top: 10.5rem !important;
  }
  body .is-sticky .sticky-pr-lg-10 {
    padding-right: 10.5rem !important;
  }
  body .is-sticky .sticky-pb-lg-10 {
    padding-bottom: 10.5rem !important;
  }
  body .is-sticky .sticky-pl-lg-10 {
    padding-left: 10.5rem !important;
  }
  body .is-sticky .sticky-px-lg-10 {
    padding-right: 10.5rem !important;
    padding-left: 10.5rem !important;
  }
  body .is-sticky .sticky-py-lg-10 {
    padding-top: 10.5rem !important;
    padding-bottom: 10.5rem !important;
  }
  body .is-sticky .sticky-m-lg-auto {
    margin: auto !important;
  }
  body .is-sticky .sticky-mt-lg-auto {
    margin-top: auto !important;
  }
  body .is-sticky .sticky-mr-lg-auto {
    margin-right: auto !important;
  }
  body .is-sticky .sticky-mb-lg-auto {
    margin-bottom: auto !important;
  }
  body .is-sticky .sticky-ml-lg-auto {
    margin-left: auto !important;
  }
  body .is-sticky .sticky-mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  body .is-sticky .sticky-my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}

@media (min-width: 1200px) {
  body .is-sticky .sticky-m-xl-0 {
    margin: 0 !important;
  }
  body .is-sticky .sticky-mt-xl-0 {
    margin-top: 0 !important;
  }
  body .is-sticky .sticky-mr-xl-0 {
    margin-right: 0 !important;
  }
  body .is-sticky .sticky-mb-xl-0 {
    margin-bottom: 0 !important;
  }
  body .is-sticky .sticky-ml-xl-0 {
    margin-left: 0 !important;
  }
  body .is-sticky .sticky-mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  body .is-sticky .sticky-my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  body .is-sticky .sticky-m-xl-1 {
    margin: 0.25rem !important;
  }
  body .is-sticky .sticky-mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  body .is-sticky .sticky-mr-xl-1 {
    margin-right: 0.25rem !important;
  }
  body .is-sticky .sticky-mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  body .is-sticky .sticky-ml-xl-1 {
    margin-left: 0.25rem !important;
  }
  body .is-sticky .sticky-mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  body .is-sticky .sticky-my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  body .is-sticky .sticky-m-xl-1-neg {
    margin: -0.25rem !important;
  }
  body .is-sticky .sticky-mt-xl-1-neg {
    margin-top: -0.25rem !important;
  }
  body .is-sticky .sticky-mr-xl-1-neg {
    margin-right: -0.25rem !important;
  }
  body .is-sticky .sticky-mb-xl-1-neg {
    margin-bottom: -0.25rem !important;
  }
  body .is-sticky .sticky-ml-xl-1-neg {
    margin-left: -0.25rem !important;
  }
  body .is-sticky .sticky-mx-xl-1-neg {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  body .is-sticky .sticky-my-xl-1-neg {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  body .is-sticky .sticky-m-xl-2 {
    margin: 0.5rem !important;
  }
  body .is-sticky .sticky-mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  body .is-sticky .sticky-mr-xl-2 {
    margin-right: 0.5rem !important;
  }
  body .is-sticky .sticky-mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  body .is-sticky .sticky-ml-xl-2 {
    margin-left: 0.5rem !important;
  }
  body .is-sticky .sticky-mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  body .is-sticky .sticky-my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  body .is-sticky .sticky-m-xl-2-neg {
    margin: -0.5rem !important;
  }
  body .is-sticky .sticky-mt-xl-2-neg {
    margin-top: -0.5rem !important;
  }
  body .is-sticky .sticky-mr-xl-2-neg {
    margin-right: -0.5rem !important;
  }
  body .is-sticky .sticky-mb-xl-2-neg {
    margin-bottom: -0.5rem !important;
  }
  body .is-sticky .sticky-ml-xl-2-neg {
    margin-left: -0.5rem !important;
  }
  body .is-sticky .sticky-mx-xl-2-neg {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  body .is-sticky .sticky-my-xl-2-neg {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  body .is-sticky .sticky-m-xl-3 {
    margin: 1rem !important;
  }
  body .is-sticky .sticky-mt-xl-3 {
    margin-top: 1rem !important;
  }
  body .is-sticky .sticky-mr-xl-3 {
    margin-right: 1rem !important;
  }
  body .is-sticky .sticky-mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  body .is-sticky .sticky-ml-xl-3 {
    margin-left: 1rem !important;
  }
  body .is-sticky .sticky-mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  body .is-sticky .sticky-my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  body .is-sticky .sticky-m-xl-3-neg {
    margin: -1rem !important;
  }
  body .is-sticky .sticky-mt-xl-3-neg {
    margin-top: -1rem !important;
  }
  body .is-sticky .sticky-mr-xl-3-neg {
    margin-right: -1rem !important;
  }
  body .is-sticky .sticky-mb-xl-3-neg {
    margin-bottom: -1rem !important;
  }
  body .is-sticky .sticky-ml-xl-3-neg {
    margin-left: -1rem !important;
  }
  body .is-sticky .sticky-mx-xl-3-neg {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  body .is-sticky .sticky-my-xl-3-neg {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  body .is-sticky .sticky-m-xl-4 {
    margin: 1.5rem !important;
  }
  body .is-sticky .sticky-mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  body .is-sticky .sticky-mr-xl-4 {
    margin-right: 1.5rem !important;
  }
  body .is-sticky .sticky-mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  body .is-sticky .sticky-ml-xl-4 {
    margin-left: 1.5rem !important;
  }
  body .is-sticky .sticky-mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  body .is-sticky .sticky-my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  body .is-sticky .sticky-m-xl-4-neg {
    margin: -1.5rem !important;
  }
  body .is-sticky .sticky-mt-xl-4-neg {
    margin-top: -1.5rem !important;
  }
  body .is-sticky .sticky-mr-xl-4-neg {
    margin-right: -1.5rem !important;
  }
  body .is-sticky .sticky-mb-xl-4-neg {
    margin-bottom: -1.5rem !important;
  }
  body .is-sticky .sticky-ml-xl-4-neg {
    margin-left: -1.5rem !important;
  }
  body .is-sticky .sticky-mx-xl-4-neg {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  body .is-sticky .sticky-my-xl-4-neg {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  body .is-sticky .sticky-m-xl-5 {
    margin: 3rem !important;
  }
  body .is-sticky .sticky-mt-xl-5 {
    margin-top: 3rem !important;
  }
  body .is-sticky .sticky-mr-xl-5 {
    margin-right: 3rem !important;
  }
  body .is-sticky .sticky-mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  body .is-sticky .sticky-ml-xl-5 {
    margin-left: 3rem !important;
  }
  body .is-sticky .sticky-mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  body .is-sticky .sticky-my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  body .is-sticky .sticky-m-xl-5-neg {
    margin: -3rem !important;
  }
  body .is-sticky .sticky-mt-xl-5-neg {
    margin-top: -3rem !important;
  }
  body .is-sticky .sticky-mr-xl-5-neg {
    margin-right: -3rem !important;
  }
  body .is-sticky .sticky-mb-xl-5-neg {
    margin-bottom: -3rem !important;
  }
  body .is-sticky .sticky-ml-xl-5-neg {
    margin-left: -3rem !important;
  }
  body .is-sticky .sticky-mx-xl-5-neg {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  body .is-sticky .sticky-my-xl-5-neg {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  body .is-sticky .sticky-m-xl-6 {
    margin: 4.5rem !important;
  }
  body .is-sticky .sticky-mt-xl-6 {
    margin-top: 4.5rem !important;
  }
  body .is-sticky .sticky-mr-xl-6 {
    margin-right: 4.5rem !important;
  }
  body .is-sticky .sticky-mb-xl-6 {
    margin-bottom: 4.5rem !important;
  }
  body .is-sticky .sticky-ml-xl-6 {
    margin-left: 4.5rem !important;
  }
  body .is-sticky .sticky-mx-xl-6 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  body .is-sticky .sticky-my-xl-6 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  body .is-sticky .sticky-m-xl-6-neg {
    margin: -4.5rem !important;
  }
  body .is-sticky .sticky-mt-xl-6-neg {
    margin-top: -4.5rem !important;
  }
  body .is-sticky .sticky-mr-xl-6-neg {
    margin-right: -4.5rem !important;
  }
  body .is-sticky .sticky-mb-xl-6-neg {
    margin-bottom: -4.5rem !important;
  }
  body .is-sticky .sticky-ml-xl-6-neg {
    margin-left: -4.5rem !important;
  }
  body .is-sticky .sticky-mx-xl-6-neg {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }
  body .is-sticky .sticky-my-xl-6-neg {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  body .is-sticky .sticky-m-xl-7 {
    margin: 6rem !important;
  }
  body .is-sticky .sticky-mt-xl-7 {
    margin-top: 6rem !important;
  }
  body .is-sticky .sticky-mr-xl-7 {
    margin-right: 6rem !important;
  }
  body .is-sticky .sticky-mb-xl-7 {
    margin-bottom: 6rem !important;
  }
  body .is-sticky .sticky-ml-xl-7 {
    margin-left: 6rem !important;
  }
  body .is-sticky .sticky-mx-xl-7 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  body .is-sticky .sticky-my-xl-7 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  body .is-sticky .sticky-m-xl-7-neg {
    margin: -6rem !important;
  }
  body .is-sticky .sticky-mt-xl-7-neg {
    margin-top: -6rem !important;
  }
  body .is-sticky .sticky-mr-xl-7-neg {
    margin-right: -6rem !important;
  }
  body .is-sticky .sticky-mb-xl-7-neg {
    margin-bottom: -6rem !important;
  }
  body .is-sticky .sticky-ml-xl-7-neg {
    margin-left: -6rem !important;
  }
  body .is-sticky .sticky-mx-xl-7-neg {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  body .is-sticky .sticky-my-xl-7-neg {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  body .is-sticky .sticky-m-xl-8 {
    margin: 7.5rem !important;
  }
  body .is-sticky .sticky-mt-xl-8 {
    margin-top: 7.5rem !important;
  }
  body .is-sticky .sticky-mr-xl-8 {
    margin-right: 7.5rem !important;
  }
  body .is-sticky .sticky-mb-xl-8 {
    margin-bottom: 7.5rem !important;
  }
  body .is-sticky .sticky-ml-xl-8 {
    margin-left: 7.5rem !important;
  }
  body .is-sticky .sticky-mx-xl-8 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important;
  }
  body .is-sticky .sticky-my-xl-8 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }
  body .is-sticky .sticky-m-xl-8-neg {
    margin: -7.5rem !important;
  }
  body .is-sticky .sticky-mt-xl-8-neg {
    margin-top: -7.5rem !important;
  }
  body .is-sticky .sticky-mr-xl-8-neg {
    margin-right: -7.5rem !important;
  }
  body .is-sticky .sticky-mb-xl-8-neg {
    margin-bottom: -7.5rem !important;
  }
  body .is-sticky .sticky-ml-xl-8-neg {
    margin-left: -7.5rem !important;
  }
  body .is-sticky .sticky-mx-xl-8-neg {
    margin-right: -7.5rem !important;
    margin-left: -7.5rem !important;
  }
  body .is-sticky .sticky-my-xl-8-neg {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }
  body .is-sticky .sticky-m-xl-9 {
    margin: 9rem !important;
  }
  body .is-sticky .sticky-mt-xl-9 {
    margin-top: 9rem !important;
  }
  body .is-sticky .sticky-mr-xl-9 {
    margin-right: 9rem !important;
  }
  body .is-sticky .sticky-mb-xl-9 {
    margin-bottom: 9rem !important;
  }
  body .is-sticky .sticky-ml-xl-9 {
    margin-left: 9rem !important;
  }
  body .is-sticky .sticky-mx-xl-9 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }
  body .is-sticky .sticky-my-xl-9 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }
  body .is-sticky .sticky-m-xl-9-neg {
    margin: -9rem !important;
  }
  body .is-sticky .sticky-mt-xl-9-neg {
    margin-top: -9rem !important;
  }
  body .is-sticky .sticky-mr-xl-9-neg {
    margin-right: -9rem !important;
  }
  body .is-sticky .sticky-mb-xl-9-neg {
    margin-bottom: -9rem !important;
  }
  body .is-sticky .sticky-ml-xl-9-neg {
    margin-left: -9rem !important;
  }
  body .is-sticky .sticky-mx-xl-9-neg {
    margin-right: -9rem !important;
    margin-left: -9rem !important;
  }
  body .is-sticky .sticky-my-xl-9-neg {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }
  body .is-sticky .sticky-m-xl-10 {
    margin: 10.5rem !important;
  }
  body .is-sticky .sticky-mt-xl-10 {
    margin-top: 10.5rem !important;
  }
  body .is-sticky .sticky-mr-xl-10 {
    margin-right: 10.5rem !important;
  }
  body .is-sticky .sticky-mb-xl-10 {
    margin-bottom: 10.5rem !important;
  }
  body .is-sticky .sticky-ml-xl-10 {
    margin-left: 10.5rem !important;
  }
  body .is-sticky .sticky-mx-xl-10 {
    margin-right: 10.5rem !important;
    margin-left: 10.5rem !important;
  }
  body .is-sticky .sticky-my-xl-10 {
    margin-top: 10.5rem !important;
    margin-bottom: 10.5rem !important;
  }
  body .is-sticky .sticky-m-xl-10-neg {
    margin: -10.5rem !important;
  }
  body .is-sticky .sticky-mt-xl-10-neg {
    margin-top: -10.5rem !important;
  }
  body .is-sticky .sticky-mr-xl-10-neg {
    margin-right: -10.5rem !important;
  }
  body .is-sticky .sticky-mb-xl-10-neg {
    margin-bottom: -10.5rem !important;
  }
  body .is-sticky .sticky-ml-xl-10-neg {
    margin-left: -10.5rem !important;
  }
  body .is-sticky .sticky-mx-xl-10-neg {
    margin-right: -10.5rem !important;
    margin-left: -10.5rem !important;
  }
  body .is-sticky .sticky-my-xl-10-neg {
    margin-top: -10.5rem !important;
    margin-bottom: -10.5rem !important;
  }
  body .is-sticky .sticky-p-xl-0 {
    padding: 0 !important;
  }
  body .is-sticky .sticky-pt-xl-0 {
    padding-top: 0 !important;
  }
  body .is-sticky .sticky-pr-xl-0 {
    padding-right: 0 !important;
  }
  body .is-sticky .sticky-pb-xl-0 {
    padding-bottom: 0 !important;
  }
  body .is-sticky .sticky-pl-xl-0 {
    padding-left: 0 !important;
  }
  body .is-sticky .sticky-px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  body .is-sticky .sticky-py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  body .is-sticky .sticky-p-xl-1 {
    padding: 0.25rem !important;
  }
  body .is-sticky .sticky-pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  body .is-sticky .sticky-pr-xl-1 {
    padding-right: 0.25rem !important;
  }
  body .is-sticky .sticky-pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  body .is-sticky .sticky-pl-xl-1 {
    padding-left: 0.25rem !important;
  }
  body .is-sticky .sticky-px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  body .is-sticky .sticky-py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  body .is-sticky .sticky-p-xl-2 {
    padding: 0.5rem !important;
  }
  body .is-sticky .sticky-pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  body .is-sticky .sticky-pr-xl-2 {
    padding-right: 0.5rem !important;
  }
  body .is-sticky .sticky-pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  body .is-sticky .sticky-pl-xl-2 {
    padding-left: 0.5rem !important;
  }
  body .is-sticky .sticky-px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  body .is-sticky .sticky-py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  body .is-sticky .sticky-p-xl-3 {
    padding: 1rem !important;
  }
  body .is-sticky .sticky-pt-xl-3 {
    padding-top: 1rem !important;
  }
  body .is-sticky .sticky-pr-xl-3 {
    padding-right: 1rem !important;
  }
  body .is-sticky .sticky-pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  body .is-sticky .sticky-pl-xl-3 {
    padding-left: 1rem !important;
  }
  body .is-sticky .sticky-px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  body .is-sticky .sticky-py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  body .is-sticky .sticky-p-xl-4 {
    padding: 1.5rem !important;
  }
  body .is-sticky .sticky-pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  body .is-sticky .sticky-pr-xl-4 {
    padding-right: 1.5rem !important;
  }
  body .is-sticky .sticky-pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  body .is-sticky .sticky-pl-xl-4 {
    padding-left: 1.5rem !important;
  }
  body .is-sticky .sticky-px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  body .is-sticky .sticky-py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  body .is-sticky .sticky-p-xl-5 {
    padding: 3rem !important;
  }
  body .is-sticky .sticky-pt-xl-5 {
    padding-top: 3rem !important;
  }
  body .is-sticky .sticky-pr-xl-5 {
    padding-right: 3rem !important;
  }
  body .is-sticky .sticky-pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  body .is-sticky .sticky-pl-xl-5 {
    padding-left: 3rem !important;
  }
  body .is-sticky .sticky-px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  body .is-sticky .sticky-py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  body .is-sticky .sticky-p-xl-6 {
    padding: 4.5rem !important;
  }
  body .is-sticky .sticky-pt-xl-6 {
    padding-top: 4.5rem !important;
  }
  body .is-sticky .sticky-pr-xl-6 {
    padding-right: 4.5rem !important;
  }
  body .is-sticky .sticky-pb-xl-6 {
    padding-bottom: 4.5rem !important;
  }
  body .is-sticky .sticky-pl-xl-6 {
    padding-left: 4.5rem !important;
  }
  body .is-sticky .sticky-px-xl-6 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  body .is-sticky .sticky-py-xl-6 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  body .is-sticky .sticky-p-xl-7 {
    padding: 6rem !important;
  }
  body .is-sticky .sticky-pt-xl-7 {
    padding-top: 6rem !important;
  }
  body .is-sticky .sticky-pr-xl-7 {
    padding-right: 6rem !important;
  }
  body .is-sticky .sticky-pb-xl-7 {
    padding-bottom: 6rem !important;
  }
  body .is-sticky .sticky-pl-xl-7 {
    padding-left: 6rem !important;
  }
  body .is-sticky .sticky-px-xl-7 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  body .is-sticky .sticky-py-xl-7 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  body .is-sticky .sticky-p-xl-8 {
    padding: 7.5rem !important;
  }
  body .is-sticky .sticky-pt-xl-8 {
    padding-top: 7.5rem !important;
  }
  body .is-sticky .sticky-pr-xl-8 {
    padding-right: 7.5rem !important;
  }
  body .is-sticky .sticky-pb-xl-8 {
    padding-bottom: 7.5rem !important;
  }
  body .is-sticky .sticky-pl-xl-8 {
    padding-left: 7.5rem !important;
  }
  body .is-sticky .sticky-px-xl-8 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important;
  }
  body .is-sticky .sticky-py-xl-8 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  body .is-sticky .sticky-p-xl-9 {
    padding: 9rem !important;
  }
  body .is-sticky .sticky-pt-xl-9 {
    padding-top: 9rem !important;
  }
  body .is-sticky .sticky-pr-xl-9 {
    padding-right: 9rem !important;
  }
  body .is-sticky .sticky-pb-xl-9 {
    padding-bottom: 9rem !important;
  }
  body .is-sticky .sticky-pl-xl-9 {
    padding-left: 9rem !important;
  }
  body .is-sticky .sticky-px-xl-9 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }
  body .is-sticky .sticky-py-xl-9 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }
  body .is-sticky .sticky-p-xl-10 {
    padding: 10.5rem !important;
  }
  body .is-sticky .sticky-pt-xl-10 {
    padding-top: 10.5rem !important;
  }
  body .is-sticky .sticky-pr-xl-10 {
    padding-right: 10.5rem !important;
  }
  body .is-sticky .sticky-pb-xl-10 {
    padding-bottom: 10.5rem !important;
  }
  body .is-sticky .sticky-pl-xl-10 {
    padding-left: 10.5rem !important;
  }
  body .is-sticky .sticky-px-xl-10 {
    padding-right: 10.5rem !important;
    padding-left: 10.5rem !important;
  }
  body .is-sticky .sticky-py-xl-10 {
    padding-top: 10.5rem !important;
    padding-bottom: 10.5rem !important;
  }
  body .is-sticky .sticky-m-xl-auto {
    margin: auto !important;
  }
  body .is-sticky .sticky-mt-xl-auto {
    margin-top: auto !important;
  }
  body .is-sticky .sticky-mr-xl-auto {
    margin-right: auto !important;
  }
  body .is-sticky .sticky-mb-xl-auto {
    margin-bottom: auto !important;
  }
  body .is-sticky .sticky-ml-xl-auto {
    margin-left: auto !important;
  }
  body .is-sticky .sticky-mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  body .is-sticky .sticky-my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}